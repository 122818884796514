import React, { useEffect, useState } from "react";
import DynamicForm from "../../components/DynamicForm";
import { useParams } from "react-router-dom";
import { _getContest, _submitContest } from "../../services/apiService";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import * as yup from "yup";
import Cookies from "universal-cookie";
import CardContests from "../../components/Cards/CardContests.js";
import moment from "moment";

export default function Contests(props) {
  let { id } = useParams();
  const [contestApiData, setContestApiData] = useState(null);

  const [contest, setContest] = useState(null);
  const [validateSchema, setValidateSchema] = useState(null);

  const [contestMode, setContestMode] = useState("add");

  const [contestFormData, setContestFormData] = useState(null);
  const [formValidateSchema, setFormValidateSchema] = useState(null);

  const [contestFormDataEdit, setContestFormDataEdit] = useState(null);
  const [formEditValidateSchema, setFormEditValidateSchema] = useState(null);

  const fieldsInfo = React.useMemo(
    () => [
      {
        name: "name",
        label: "Contest Name",
        placeholder: "Contest Name",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Contest Name is required"],
          },
        ],
      },
      {
        name: "description",
        label: "Contest Description",
        placeholder: "Enter Contest Description",
        type: "longText",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Contest Description is required"],
          },
        ],
      },
      {
        name: "created",
        label: "Contest Created On",
        placeholder: "Enter Contest Created On",
        hiddenOnForm: true,
        type: "date",
        validationType: "date",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Contest Created Date is required"],
          },
        ],
      },
      {
        name: "startDate",
        label: "Contest start Date",
        placeholder: "Enter Contest start Date",
        type: "date",
        validationType: "date",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Contest start Date is required"],
          },
        ],
      },
      {
        name: "endDate",
        label: "Contest End Date",
        placeholder: "Enter Contest End Date",
        type: "date",
        validationType: "date",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Contest start End is required"],
          },
        ],
      },
      {
        name: "prize",
        label: "Contest Prize",
        placeholder: "Enter Contest Prize",
        type: "typeAhead",
        typeAheadApiType: "prizes",
        // "hiddenOnForm": true,
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Contest Prize is required"],
          },
        ],
      },
      {
        name: "participants",
        label: "Number of participants",
        placeholder: "Enter participants",
        type: "text",
        hiddenOnForm: true,
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["participants is required"],
          },
        ],
      },
      {
        name: "maxWinners",
        label: "maximum winners",
        placeholder: "Enter maximum winners",
        type: "text",
        validationType: "number",
        value: "",
        validations: [
          {
            type: "required",
            params: ["maximum winners is required"],
          },
          {
            type: "typeError",
            params: ["maximum winners should be a number"],
          },
        ],
      },
      {
        name: "winners",
        label: "Winners",
        placeholder: "Enter Winners",
        type: "text",
        hiddenOnForm: true,
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Winners is required"],
          },
        ],
      },
      {
        name: "category",
        label: "Category",
        placeholder: "Enter category",
        type: "select",
        validationType: "string",
        value: "",
        defaultOption: "Select Contest category",
        validations: [
          {
            type: "required",
            params: ["category is required"],
          },
        ],
        options: ["Spin wheel", "roulette", "Multi wheel"],
      },
      {
        name: "status",
        label: "status",
        placeholder: "Enter status",
        type: "select",
        defaultOption: "Select Contest Status",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["status is required"],
          },
        ],
        options: ["Active", "Scheduled"],
      },
    ],
    []
  );

  const messagesAdd = {
    success: "Contest added successfully",
    error: "Can not add contest",
  };

  const messagesEdit = {
    success: "Contest edit successfully",
    error: "Can not edit contest",
  };

  function prepareContestAddForm() {
    let fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));

    fieldsInfoCopy = fieldsInfoCopy.filter((el) => el.hiddenOnForm !== true);

    const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);

    setFormValidateSchema(validateSchema);
    setContestFormData(fieldsInfoCopy);
  }

  function prepareContestEditForm() {
    const result = contestApiData;
    let fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
    fieldsInfoCopy = fieldsInfoCopy.filter((el) => el.hiddenOnForm !== true);
    fieldsInfoCopy.forEach((fieldObj) => {
      if (result[fieldObj["name"]] || result[fieldObj["name"]] === 0) {
        let value = result[fieldObj["name"]];
        if (fieldObj["validationType"] === "number") {
          value = parseInt(value);
        } else {
          if (value.constructor === Array) {
            value = value.map((el) => el.name).join(",");
          } else if (typeof value === "object" && value !== null) {
            value = value._id;
          }
        }
        if (fieldObj["validationType"] === "date") {
          fieldObj["value"] = moment(value).format("YYYY-MM-DD");
        } else {
          fieldObj["value"] = value;
        }
      }
    });
    const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);
    setFormEditValidateSchema(validateSchema);
    setContestFormDataEdit(fieldsInfoCopy);
  }

  function addContest() {
    prepareContestAddForm();
    const url = "/admin/contests/add";
    props.history.push(url);
    //id='add';
  }

  function editContest() {
    prepareContestEditForm();
    setContestMode("edit");
    //id='add';
  }

  async function submitData(data = "") {
    if (data) {
      const cookies = new Cookies();
      const cookieInfo = cookies.get("kidUser");
      data["addedBy"] = cookieInfo.id;
      const response = await _submitContest(data);
      return response;
    }
  }

  async function editData(data = "") {
    if (data) {
      data._id = id;
      const response = await _submitContest(data);
      return response;
    }
  }

  async function changeUrl() {
    const url = "/admin/contests";
    props.history.push(url);
  }

  useEffect(() => {
    function prepareContestForm(result) {
      const fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
      fieldsInfoCopy.forEach((fieldObj) => {
        if (result[fieldObj["name"]] || result[fieldObj["name"]] === 0) {
          let value = result[fieldObj["name"]];
          if (fieldObj["validationType"] === "number") {
            value = parseInt(value);
          } else if (fieldObj["validationType"] === "date") {
            fieldObj["type"] = "text";
          } else {
            if (value.constructor === Array) {
              value = value.map((el) => el.name).join(",");
            } else if (typeof value === "object" && value !== null) {
              value = value.name;
            }
          }
          if (fieldObj["validationType"] === "date") {
            fieldObj["value"] = moment(value).format("YYYY-MM-DD");
          } else {
            fieldObj["value"] = value;
          }
        }
      });
      const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
      const validateSchema = yup.object().shape(yepSchema);
      setValidateSchema(validateSchema);
      setContest(fieldsInfoCopy);
    }
    async function fetchData() {
      // You can await here
      let result = await _getContest(id);
      setContestApiData(result);
      prepareContestForm(result);
      // ...
    }
    if (id && id !== "add") {
      fetchData();
    }
    setContestMode("add");
  }, [id, fieldsInfo]); // Or [] if effect doesn't need props or state

  return (
    <>
      <div className="flex flex-wrap">
        {id && id !== "add" ? (
          <>
            {contest ? (
              <>
                {contestMode === "add" ? (
                  <div className="w-full">
                    {/* add mode */}
                    <div className="flex justify-between bg-white pt-4 pr-4">
                      <button
                        onClick={(e) => props.history.goBack()}
                        className="ml-2 mt-3 text-sm flex flex-row"
                      >
                        <svg
                          version="1.1"
                          id="Capa_1"
                          className="w-5 h-5 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 26.676 26.676"
                        >
                          <g>
                            <path
                              d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                            />
                          </g>
                        </svg>
                        <div className="underline px-1">Go back</div>
                      </button>
                      <div className="flex justify-end space-x-2">
                        <button
                          className="inline-flex justify-center focus:outline-none py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                          onClick={() => editContest()}
                        >
                          Edit contest
                        </button>
                      </div>
                    </div>
                    <DynamicInfoRender
                      fields={contest}
                      validation={validateSchema}
                    />
                  </div>
                ) : (
                  // edit mode
                  <div className="w-full">
                    <div className="flex justify-between bg-white pt-4 pr-4">
                      <div className="text-bold w-2/3 text-xl pl-4">
                        Edit Contest Information
                      </div>
                      <div className="flex justify-end">
                        <button
                          className="inline-flex focus:outline-none justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                          onClick={() => setContestMode("add")}
                        >
                          Cancel Edit
                        </button>
                      </div>
                    </div>
                    <DynamicForm
                      onSubmit={editData}
                      history={props.history}
                      messages={messagesEdit}
                      onSuccess={changeUrl}
                      redirect={false}
                      dialogMessage="Are you sure you want to edit this contest?"
                      fields={contestFormDataEdit}
                      validation={formEditValidateSchema}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="my-4">
                  <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                    {[...Array(4)].map((e, i) => (
                      <div
                        key={i}
                        className="animate-pulse my-10 flex space-x-4"
                      >
                        <div className="flex-1 space-y-4 py-1">
                          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </>
        ) : id && id === "add" ? (
          <div className="w-full bg-white rounded-lg">
            <button
              onClick={(e) => props.history.goBack()}
              className="ml-2 mt-3 text-sm flex flex-row"
            >
              <svg
                version="1.1"
                id="Capa_1"
                className="w-5 h-5 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 26.676 26.676"
              >
                <g>
                  <path
                    d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                  />
                </g>
              </svg>
              <div className="underline px-1">Go back</div>
            </button>

            <DynamicForm
              onSubmit={submitData}
              history={props.history}
              messages={messagesAdd}
              onSuccess={changeUrl}
              redirect={false}
              dialogMessage="Are you sure you want to add a new contest?"
              fields={contestFormData}
              validation={formValidateSchema}
            />
          </div>
        ) : (
          <div className="w-full mb-12 px-4">
            <div className="flex justify-end bg-white pt-4 pr-4">
              <div>
                <button
                  className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                  onClick={() => addContest()}
                >
                  Add contest
                </button>
              </div>
            </div>
            <CardContests props={props} color="dark" />
          </div>
        )}
      </div>
    </>
  );
}
