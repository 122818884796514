import React from "react";
import { Switch, Route } from "react-router-dom";

// ../../components
import PartnerSidebar from "../../components/Sidebar/PartnerSidebar.js";

// ../../views
import PartnerDashboard from "../../views/partner/Dashboard.js";
import Products from "../../views/partner/Products";
import Loans from "../../views/partner/Loans";
import Reports from "../../views/partner/Reports.js";
import LoanApplications from "../../views/partner/LoanApplications";
import LogoutLoader from "../../components/Cards/LogoutLoader";
import Company from "../../views/partner/Company";

export default function Partner() {
  return (
    <>
      <PartnerSidebar />
      <div className="pt-32 md:pt-32 relative md:ml-64 bg-gray-200 flex flex-col min-h-screen">
        <div className="px-4 md:px-10 mx-auto w-full -m-24 flex-grow">
          <Switch>
            <Route
              path="/partner/dashboard"
              exact
              component={PartnerDashboard}
            />
            <Route path="/partner/logout" exact component={LogoutLoader} />

            <Route path="/partner/products/:id" exact component={Products} />
            <Route
              exactly
              path="/partner/products"
              render={(props) => <Products {...props} />}
            />

            <Route path="/partner/loans/:id" exact component={Loans} />
            <Route
              exactly
              path="/partner/loans"
              render={(props) => <Loans {...props} />}
            />

            <Route path="/partner/reports/:id" exact component={Reports} />
            <Route
              exactly
              path="/partner/reports"
              render={(props) => <Reports {...props} />}
            />

            <Route
              component={LoanApplications}
              path="/partner/loan-applications/:id"
            />
            <Route
              component={LoanApplications}
              path="/partner/loan-applications"
            />

            <Route path="/partner/company" exact component={Company} />
          </Switch>
        </div>
      </div>
    </>
  );
}
