import React from "react";

export default function DynamicModal({
  modalTitle,
  modalBody,
  showModal,
  setShowModal,
  size,
}) {
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className={`relative w-auto my-6 mx-auto ${size}`}>
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {/*header*/}
                {modalTitle && (
                  <div className="flex items-start justify-between pt-5 pl-5  rounded-t">
                    <h3 className="text-2xl font-semibold">{modalTitle}</h3>
                  </div>
                )}
                {/*body*/}
                <div className="relative px-5 flex-auto">
                  <p className="my-4 text-slate-500 text-base leading-relaxed">
                    {modalBody}
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end rounded-b">
                  <button
                    className="text-white bg-green-500 rounded-md px-4 py-1 outline-none focus:outline-none mr-7 mb-5 mt-4 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
