import React, { Component, Fragment } from "react";
import { Formik, Field } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AsynchronousAutocomplete from "../../components/AutoComplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Confetti from "react-dom-confetti";

class DynamicForm extends Component {
  state = {
    isSubmitted: false,
    isConfirmed: false,
    formData: null,
    resetForm: null,
    config: {
      angle: 90,
      spread: 180,
      startVelocity: 40,
      elementCount: 360,
      dragFriction: 0.12,
      duration: 3000,
      stagger: 3,
      width: "10px",
      height: "10px",
      perspective: "700px",
      colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
    },
    isActive: false,
    history: null,
  };

  notify(isSuccess) {
    let message = "";
    if (isSuccess) {
      this.setState({
        isActive: true,
      });
      // if (this.props.messages && this.props.messages.success) {
      //   message = this.props.messages.success;
      // } else {
      //   message = "Yay! Information successfuly submitted";
      // }
      toast.success("Congratulations, you are one step closer now!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      if (this.props.messages && this.props.messages.error) {
        message = this.props.messages.error;
      } else {
        message = "Oops! Something Went wrong. Please try again";
      }

      toast.error(message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }
  handleVerifyLater = (history) => {
    console.log(history);
    if (this.props.allData) {
      console.log("allInfo:", this.props.allInfo, this.props.index);
      const isLast = this.props.allInfo.length === this.props.index + 1;
      if (isLast) {
        setTimeout(() => history.push("/"), 3000);
      } else {
        const data = this.props.allInfo[this.props.index + 1];
        console.log("next data:", data);
        const isSubmitted = data.userStatus === "Submitted" || "";
        const path = isSubmitted ? "/verification" : "/information";
        const schemaData = isSubmitted
          ? data.verificationSchema.fields
          : data.fields;
        const typeData = isSubmitted ? data.verificationSchema : data;
        history.push({
          pathname: path,
          state: {
            data: schemaData,
            id: typeData._id,
            allData: data,
            allInfo: this.props.allInfo,
            index: this.props.index + 1,
          },
        });
      }
    } else {
      setTimeout(() => history.push("/"), 3000);
    }
  };

  handleClickClose = () => {
    this.setState({
      isConfirmed: !this.state.isConfirmed,
    });
  };

  confirmSubmitForm = async () => {
    this.setState({
      isSubmitted: true,
      isConfirmed: !this.state.isConfirmed,
    });

    const { formData, resetForm, history } = this.state;
    const response = await this.props.onSubmit(formData);

    if (response.ok) {
      resetForm({});
    }

    this.notify(response.ok);

    if (response.ok && this.props.redirect !== false) {
      console.log("this.props.allData:", this.props.allData);

      if (this.props.allData) {
        console.log("allInfo:", this.props.allInfo, this.props.index);
        const isLast = this.props.allInfo.length === this.props.index + 1;
        if (isLast) {
          setTimeout(() => history.push("/"), 3000);
        } else {
          const data = this.props.allInfo[this.props.index + 1];
          console.log("next data:", data);
          const isSubmitted = data.userStatus === "Submitted" || "";
          const path = isSubmitted ? "/verification" : "/information";
          const schemaData = isSubmitted
            ? data.verificationSchema.fields
            : data.fields;
          const typeData = isSubmitted ? data.verificationSchema : data;

          history.push({
            pathname: path,
            state: {
              data: schemaData,
              id: typeData._id,
              allData: data,
              allInfo: this.props.allInfo,
              index: this.props.index + 1,
            },
          });
        }
      } else {
        setTimeout(() => history.push("/earn-points"), 3000);
      }

      /* 
    //auto redirect to verify section if enabled
      if(this.props.allData) {
        const rjson = await response.json();
        if(rjson.sId) {
          this.props.allData.sId = rjson.sId;
          history.push({
            pathname: '/verification',
            state: { data: this.props.allData.verificationSchema.fields, id: this.props.allData.verificationSchema._id, 
              allData: this.props.allData, allInfo:this.props.allInfo, index:this.props.index },
          });
        } else {
          console.log("allInfo:",this.props.allInfo, this.props.index)
          const isLast = this.props.allInfo.length === (this.props.index+1);
          if(isLast) {
            setTimeout(() => history.push("/"), 3000);
          } else {
            const data = this.props.allInfo[this.props.index+1];
            console.log("next data:",data)
            const isSubmitted = data.userStatus === "Submitted" || '';
  const path = isSubmitted ? "/verification" : "/information";
  const schemaData = isSubmitted ? data.verificationSchema.fields : data.fields;
  const typeData = isSubmitted ? data.verificationSchema : data;
            history.push({
              pathname: path,
              state: { data: schemaData, id: typeData._id, allData: data, allInfo:this.props.allInfo, index:this.props.index+1 },
            });
          }
        
        }
        
       
      } else {
        setTimeout(() => history.push("/"), 3000);
      }*/
      // setTimeout(() => history.push("/"), 3000);
    }

    if (response.ok && this.props.redirect === false) {
      setTimeout(() => {
        if (this.props.onSuccess) {
          this.props.onSuccess({});
        }
      }, 3000);
    }

    setTimeout(
      () =>
        this.setState({
          isSubmitted: false,
        }),
      1000
    );
  };

  submitForm = async (formData, resetForm, history) => {
    this.setState({
      formData: formData,
      resetForm: resetForm,
      history: history,
      isConfirmed: !this.state.isConfirmed,
    });
  };

  renderFileType(input) {
    input.validations = input.validations
      ? input.validations
      : input.conditionalValidations;
    return (
      <div className="col-span-12 sm:col-span-6" key={input.name}>
        <label
          className="block text-sm font-medium"
          style={{ color: "#FBFA58" }}
        >
          {input.label}
        </label>
        <Field
          name={input.name}
          render={(props) => {
            const { field } = props;
            const { errors, touched, setFieldValue, setFieldTouched } =
              props.form;
            const hasError =
              errors[input.name] && touched[input.name] ? "hasError" : "";
            return (
              <>
                <label
                  style={{ color: "white" }}
                  htmlFor={input.name}
                  className="relative cursor-pointer rounded-md font-medium text-gray-600 hover:text-gray-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gray-500 "
                >
                  <div
                    style={{
                      background: "#394A48",
                      color: "white",
                      border: "1px solid #FCFA57",
                      borderRadius: "20px",
                    }}
                    className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 rounded-md ${
                      hasError ? "border-red-700" : ""
                    }`}
                  >
                    <div className="space-y-1 text-center">
                      <svg
                        style={{ color: "white" }}
                        className="mx-auto h-12 w-12"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-white  justify-center">
                        <span>{input.label}</span>
                        <input
                          id={input.name}
                          name={input.name}
                          type="file"
                          className="sr-only"
                          onChange={(event) => {
                            setFieldValue(
                              input.name,
                              event.currentTarget.files[0]
                            );
                            setFieldTouched(input.name, true, false);
                          }}
                        />

                        {/* To do add drag drop
                    <p className="pl-1">or drag and drop</p> */}
                      </div>
                      <p className="text-xs" style={{ color: "white" }}>
                        {input.validations[1]["fileTypes"]
                          .map((el) => el.toUpperCase())
                          .join(", ")}{" "}
                        up to {input.validations[1]["maxFileSize"]}MB
                      </p>
                    </div>
                  </div>
                </label>
                {hasError ? (
                  <div className="error text-xs font-semibold text-red-300">
                    {errors[input.name]}
                  </div>
                ) : null}
                {hasError ? null : (
                  <div
                    className="text-xs font-semibold "
                    style={{ color: "#87F288" }}
                  >
                    {field && field.value && field.value.name ? (
                      <li>{field.value.name}</li>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </>
            );
          }}
        />
      </div>
    );
  }
  renderTypeAhead(input) {
    return (
      <div className="col-span-12 sm:col-span-3" key={input.name}>
        <label
          htmlFor="{input.label}"
          className="block text-sm font-medium text-gray-700"
        >
          {input.label}
        </label>
        <Field
          name={input.name}
          render={(props) => {
            // const { field } = props;
            const { errors, touched, setFieldValue } = props.form;
            const hasError =
              errors[input.name] && touched[input.name] ? "hasError" : "";
            return (
              <>
                <AsynchronousAutocomplete
                  onChange={(e, val) => setFieldValue(input.name, val._id)}
                  type={input.typeAheadApiType}
                  label={input.label}
                  placeholder={input.placeholder}
                  id={hasError}
                />
                {hasError ? (
                  <div className="error text-xs font-semibold text-red-300">
                    {errors[input.name]}
                  </div>
                ) : null}
              </>
            );
          }}
        />
      </div>
    );
  }
  renderlongText(input) {
    return (
      <div className="col-span-12 sm:col-span-6" key={input.name}>
        <label
          htmlFor="{input.label}"
          className="block text-sm font-medium"
          style={{ color: "#FBFA58" }}
        >
          {input.label}
        </label>
        {input.sublabel ? (
          <p className="text-xs font-medium" style={{ color: "#FBFA58" }}>
            {input.sublabel}
          </p>
        ) : (
          ""
        )}
        <Field
          name={input.name}
          render={(props) => {
            const { field } = props;
            const { errors, touched } = props.form;
            const hasError =
              errors[input.name] && touched[input.name] ? "hasError" : "";
            return (
              <>
                <textarea
                  style={{
                    background: "#394A48",
                    color: "white",
                    border: "1px solid #FCFA57",
                    borderRadius: "40px",
                  }}
                  {...field}
                  placeholder={input.placeholder}
                  id={hasError}
                  type={input.type}
                  rows="3"
                  className={`white-placeholder shadow-sm focus:ring-gray-500 focus:border-gray-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md ${
                    hasError ? "border-red-700" : ""
                  }`}
                ></textarea>
                {hasError ? (
                  <div className="error text-xs font-semibold text-red-300">
                    {errors[input.name]}
                  </div>
                ) : null}
              </>
            );
          }}
        />
      </div>
    );
  }

  renderSelect(input) {
    return (
      <div className="col-span-12 sm:col-span-3" s key={input.name}>
        <label
          htmlFor="{input.label}"
          className="block text-sm font-medium"
          style={{ color: "#FBFA58" }}
        >
          {input.label}
        </label>
        {input.sublabel ? (
          <p className="text-xs font-medium" style={{ color: "#FBFA58" }}>
            {input.sublabel}
          </p>
        ) : (
          ""
        )}
        <Field
          name={input.name}
          render={(props) => {
            const { field } = props;
            const { errors, touched } = props.form;
            const hasError =
              errors[input.name] && touched[input.name] ? "hasError" : "";
            return (
              <>
                <select
                  {...field}
                  style={{
                    background: "#394A48",
                    color: "white",
                    border: "1px solid #FCFA57",
                    borderRadius: "40px",
                  }}
                  id={hasError}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                >
                  <option value=""> {input.defaultOption} </option>
                  {input.options.map((option, index) => (
                    <option key={index} value={option}>
                      {" "}
                      {option}{" "}
                    </option>
                  ))}
                </select>
                {hasError ? (
                  <div className="error text-xs font-semibold text-red-300">
                    {errors[input.name]}
                  </div>
                ) : null}
              </>
            );
          }}
        />
      </div>
    );
  }

  renderCheckbox(input) {
    return (
      <div className="col-span-12 sm:col-span-6" key={input.name}>
        {/* <label htmlFor="{input.label}" className="block text-sm font-medium text-gray-700">{input.label}</label> */}
        <Field
          name={input.name}
          render={(props) => {
            const { field } = props;
            const { errors, touched } = props.form;
            const hasError =
              errors[input.name] && touched[input.name] ? "hasError" : "";
            return (
              <>{input.label ?<div style={{color:"white", paddingBottom:"2%"}}>
                {input.label}
              </div>:''}
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      checked={field.value}
                      {...field}
                      id={hasError}
                      name={input.name}
                      type="checkbox"
                      className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor={input.name}
                      className="font-medium text-white"
                    >
                      {input.checkboxTitle}
                    </label>
                    <p className="text-white">{input.checkboxDescription}</p>
                  </div>
                </div>
                {hasError ? (
                  <div className="error text-xs font-semibold text-red-300">
                    {errors[input.name]}
                  </div>
                ) : null}
              </>
            );
          }}
        />
      </div>
    );
  }

  isFieldRequired(input, form) {
    if (!form) {
      return true;
    }
    let isRequired = true;
    // decides if a field is required
    // check conditionalValidations
    const hasConditional = input.conditionalValidations ? true : false;
    // check first validation
    if (hasConditional) {
      const condition = input.conditionalValidations[0];
      if (condition.compareType === "not equal") {
        isRequired =
          !form.values[condition["when"]] &&
          form.values[condition["when"]] !== condition["value"];
      } else if (condition.compareType === "equal") {
        isRequired =
          form.values[condition["when"]] &&
          form.values[condition["when"]] === condition["value"];
      } else if (condition.compareType === "greaterThanEqual") {
        isRequired =
          form.values[condition["when"]] &&
          form.values[condition["when"]] >= condition["value"];
      }
    }
    return isRequired;
  }

  renderFields(inputs, form) {
    return inputs.map((input) => {
      if (!this.isFieldRequired(input, form)) {
        return "";
      }
      if (input.type === "longText") {
        return this.renderlongText(input);
      } else if (input.type === "file") {
        return this.renderFileType(input);
      } else if (input.type === "select") {
        return this.renderSelect(input);
      } else if (input.type === "typeAhead") {
        return this.renderTypeAhead(input);
      } else if (input.type === "checkbox") {
        return this.renderCheckbox(input);
      }
      return (
        <div className="col-span-12 sm:col-span-3" key={input.name}>
          <label
            style={{ color: "#FBFA58" }}
            htmlFor="{input.label}"
            className="block text-sm font-medium"
          >
            {input.label}
          </label>
          {input.sublabel ? (
            <p className="text-xs font-medium" style={{ color: "#FBFA58" }}>
              {input.sublabel}
            </p>
          ) : (
            ""
          )}
          <Field
            name={input.name}
            render={(props) => {
              const { field } = props;
              const { errors, touched } = props.form;
              const hasError =
                errors[input.name] && touched[input.name] ? "hasError" : "";
              return (
                <>
                  <input
                    style={{
                      background: "#394A48",
                      color: "white",
                      border: "1px solid #FCFA57",
                      borderRadius: "40px",
                    }}
                    {...field}
                    placeholder={input.placeholder}
                    id={hasError}
                    type={input.type === "date" ? "date" : "text"}
                    className={`mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      hasError ? "border-red-700" : ""
                    }`}
                  />
                  {hasError ? (
                    <div className="error text-xs font-semibold text-red-300">
                      {errors[input.name]}
                    </div>
                  ) : null}
                </>
              );
            }}
          />
        </div>
      );
    });
  }

  getInitialValues(inputs) {
    //declare an empty initialValues object
    const initialValues = {};
    //loop loop over fields array
    //if prop does not exit in the initialValues object,
    // pluck off the name and value props and add it to the initialValues object;
    inputs.forEach((field) => {
      if (!initialValues[field.name]) {
        initialValues[field.name] = field.value;
      }
    });

    //return initialValues object
    return initialValues;
  }

  render() {
    console.log("this.prop:", this.props);
    const initialValues = this.getInitialValues(this.props.fields);
    return (
      <Formik
        onSubmit={(values, { resetForm }) => {
          this.submitForm(values, resetForm, this.props.history);
          // values, resetForm, this.props.history
        }}
        validationSchema={this.props.validation}
        initialValues={initialValues}
        enableReinitialize
        render={(form) => {
          return (
            <div
              className="mt-5 md:mt-0 w-full sm:mx-40 lg:mx-60"
              style={{
                background: `#30484C`,
                border: "1px solid #87F288",
                boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.04)",
                backdropFilter: "blur(30px)",
                borderRadius: "20px",
              }}
            >
              <Confetti
                active={this.state.isActive}
                config={this.state.config}
              />
              <div className="">
                <h1
                  style={{ color: "#87F288" }}
                  className="text-center text-2xl sm:text-3xl font-bold pt-4"
                >
                  {this.props.isVerification
                    ? "Verify " + this.props.allData.title
                    : this.props.allData && this.props.allData.title}
                </h1>
                <h1
                  style={{ color: "#FBFA58" }}
                  className="text-center text-base font-bold "
                >
                  Fill out Information and grow your plant
                </h1>
                <form onSubmit={form.handleSubmit}>
                  <div className="px-4 py-5  space-y-6 sm:p-6">
                    {this.props.fields.length === 0 ? (
                      <div style={{ color: "#FBFA58" }}>
                        Verification not required. Click submit to continue
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="grid grid-cols-6 sm:gap-6">
                      {this.renderFields(this.props.fields, form)}
                    </div>
                  </div>
                  <div className="px-4 py-3 text-center flex">
                    <div
                      className={`${
                        this.props.isVerification ? "w-full" : "w-full"
                      }`}
                    >
                      <button
                        type="submit"
                        style={{ background: "#FCFA57" }}
                        className="rounded-full py-2 shadow w-full"
                        disabled={this.state.isSubmitted}
                      >
                        {this.state.isSubmitted ? (
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          ""
                        )}
                        Submit
                      </button>
                    </div>

                    <Dialog
                      fullWidth={true}
                      maxWidth="sm"
                      open={this.state.isConfirmed}
                      onClose={this.state.handleClickClose}
                      aria-labelledby="form-dialog-title"
                    >
                      <DialogContent>
                        <DialogContentText>
                          {this.props.dialogMessage
                            ? this.props.dialogMessage
                            : "Information submitted cannot be edited. Are you sure you want to submit?"}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleClickClose} color="primary">
                          Cancel
                        </Button>
                        <Button
                          onClick={this.confirmSubmitForm}
                          color="primary"
                        >
                          Approve
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </form>
                <ToastContainer
                  position="bottom-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </div>
          );
        }}
      />
    );
  }
}

export default DynamicForm;
