import React, { useEffect, useState } from "react";
import {
  _getDashboardInfo,
  _getInformations,
  _joinContest,
  _getTree,
  _getUserInformations,
} from "../../services/apiService";
import Confetti from "react-dom-confetti";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

function OverviewComponent2(props) {
  const [response, setResponse] = useState([]);
  const [informationData, setInformationData] = useState([]);
  const [isActive, setisActive] = useState(false);
  const [userData, setUserData] = useState(null);
  const [treeInfo, settreeInfo] = useState(null);
  const [percentComplete, setpercentComplete] = useState(null);
  const [pointInPercent, setPointInPercent] = useState(0);
  const [profileStatus, setProfileStatus] = useState({
    verification: false,
    submission: false,
    profileComplete: true,
    DocumentForVerification: true,
    loanServices: false,
    rewards: false,
    financialTips: true,
  });

  async function joinContest(contest) {
    const result = await _joinContest(contest._id);
    if (result.ok) {
      // show success and error
      contest.isJoined = true;
      setisActive(true);
      toast.success("Contest has been joined", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }

  const config = {
    angle: 90,
    spread: 180,
    startVelocity: 40,
    elementCount: 360,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "700px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  useEffect(() => {
    async function fetchInformations() {
      let response = await _getUserInformations();
      const statuses = ["Submitted", "SubmittedForVerfication", "Verified"];
      const result = [];
      statuses.forEach((status) => {
        if (response[status]) {
          response[status].forEach((record) => {
            result.push({
              name: record.informationSchema.name,
              status: status,
            });
          });
        }
      });
      setInformationData(result);
      console.log(result);
    }
    async function fetchData() {
      // You can await here

      let information = await _getInformations();
    
      console.log("information:", information);
      setResponse(information);
    }
    fetchInformations();
    fetchData();
  }, []);

  return (
    <>
    
  <div class="flex flex-col bg-gray-100  mx-auto py-4 px-2">
      <div class="mb-2 text-xl font-light">Loan Projected Metrics</div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      <div class="bg-white rounded-lg shadow-lg p-4 text-center">
        <div class="text-5xl text-red-500"><i class="fas fa-bolt"></i></div>
        <div class="text-4xl text-red-400 mt-2">49</div>
        <div class="text-small font-bold mt-4 font-light">Risk score</div>
      </div>
      <div class="bg-white rounded-lg shadow-lg p-4 text-center">
        <div class="text-5xl text-yellow-500"><i class="fas fa-universal-access"></i></div>
        <div class="text-4xl text-yellow-400 mt-2">D</div>
        <div class="text-small font-bold mt-4 font-light">Risk grade</div>
      </div>
      <div class="bg-white rounded-lg shadow-lg p-4 text-center">
        <div class="text-5xl text-green-500"><i class="fas fa-code"></i></div>
        <div class="text-4xl text-green-400 mt-2">30%</div>
        <div class="text-small font-bold mt-4 font-light">Debt service ratio</div>
      </div>
    </div>
  </div>
  <div class="flex flex-col bg-white  mx-auto px-4 py-6 my-6">
    <h2 class="mb-2 text-xl font-light ">Information Not Provided</h2>
    {response.length > 0 
    ?
    <ul>
  {response.map((el, index) => (
    <li key={index} className="border-b border-gray-400 pb-2 mb-2 text-small">
      {el.title}
      <span className="text-red-500 text-small float-right">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="16px" height="16px">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
        </svg>
      </span>
    </li>
  ))}
</ul>

  
    :
    <h3>All information provided</h3>}
   
  </div>

  <div class="flex flex-col  bg-white  mx-auto px-4 py-6 my-6">
    <h2 class="mb-2 text-xl font-light ">Submitted Information</h2>
    {informationData.length > 0 && informationData.filter(el=>el.status=="SubmittedForVerfication").length>0
    ?
    <ul>
    {informationData.filter(el=>el.status=="SubmittedForVerfication").map((el, index) => (
      <li key={index} className="border-b border-gray-400 pb-2 mb-2 text-small">
        {el.name}
        <span className="text-green-500 text-small float-right">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" width="16px" height="16px">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
          </svg>
        </span>
      </li>
    ))}
  </ul>
  
    :
    <h3>No submitted information</h3>}
   
  </div>

  <div class="flex flex-col  bg-white  mx-auto px-4 py-6 my-6">
    <h2 class="mb-2 text-xl font-light ">Verified Information</h2>
    {informationData.length > 0 && informationData.filter(el=>el.status=="Verified").length>0
    ?
    <ul>
    {informationData.filter(el=>el.status=="Verified").map((el, index) => (
      <li key={index} className="border-b border-gray-400 pb-2 mb-2 text-small">
        {el.name}
        <span className="text-green-500 text-small float-right">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" width="16px" height="16px">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
          </svg>
        </span>
      </li>
    ))}
  </ul>
  
    :
    <h3>No verified information</h3>}
   
  </div>


    </>
  );
}

export default OverviewComponent2;
