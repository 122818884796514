import * as yup from "yup";
import moment from "moment";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { _getOneLoan, _approveLoan, _rejectLoan } from "../../services/apiService";

import CardUser from "../../components/Cards/CardUser.js";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import UserNotesModal from "../../components/Modal/UserNotesModal";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const fieldsInfo = [
  {
    name: "interestRate",
    label: "Interest Rate",
    placeholder: "Enter interest rate here",
    hiddenOnForm: true,
    type: "text",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["interest rate is required"],
      },
    ],
  },
  {
    name: "status",
    label: "Status",
    placeholder: "Enter status here",
    type: "text",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["Status is required"],
      },
    ],
  },
  {
    name: "rejectionReason",
    label: "Rejection Reason",
    placeholder: "Enter rejection reason here",
    type: "text",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["Rejection reason is required"],
      },
    ],
  },{
    name: "term",
    label: "Term",
    placeholder: "Enter term here",
    type: "text",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["Term is required"],
      },
    ],
  },{
    name: "loanAmount",
    label: "Loan Amount",
    placeholder: "Enter loan amount here",
    type: "text",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["Loan amount is required"],
      },
    ],
  },{
    name: "productId",
    label: "Product ID",
    placeholder: "Enter product ID here",
    type: "text",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["Product ID is required"],
      },
    ],
  },{
    name: "loanPurpose",
    label: "Loan Purpose",
    placeholder: "Enter loan purpose here",
    type: "text",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["Loan purpose is required"],
      },
    ],
  }
];

export default function LoanInformation(props) {
  let { id } = useParams();
  const [rejectionNote, setRejectionNote] = React.useState("");
  const [approvalNote, setApprovalNote] = React.useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [reject, setRejectHandle] = React.useState(false);
  const [verified, setVerifiedHandle] = React.useState(false);
  const [loan, setLoan] = useState(null);
  const [userFilter, setUserFilter] = useState("");
  const [validateSchema, setValidateSchema] = useState(null);
  const [isUserNotesModalOpen, setIsUserNotesModalOpen] = useState(false);

  useEffect(() => {

    async function fetchData() {
      function prepareLoanForm(result) {
        const fieldsInfoCopy = [...fieldsInfo];
        fieldsInfoCopy.forEach((fieldObj) => {
          if (result[fieldObj["name"]]) {
            let value = result[fieldObj["name"]];
            if (fieldObj["validationType"] === "number") {
              value = parseInt(value);
            } else {
              if (value.constructor === Array) {
                value = value.map((el) => el.name).join(",");
              } else if (typeof value === "object" && value !== null) {
                value = value.name;
              }
            }
            if (fieldObj["validationType"] === "date") {
              fieldObj["value"] = moment(value).format("YYYY-MM-DD");
            } else {
              fieldObj["value"] = value;
            }
          }
        });
        const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
        const validateSchema = yup.object().shape(yepSchema);
        setValidateSchema(validateSchema);
        setLoan(fieldsInfoCopy);
        console.log(fieldsInfoCopy);
      }

      let result = await _getOneLoan(id);
      prepareLoanForm(result);
    }

    if (id) fetchData();
  }, [id]);

  const handleClickOpenVerify = () => {
    setVerifiedHandle(true);
  };

  const handleClickCloseVerify = () => {
    setVerifiedHandle(false);
  };

  const handleClickOpenReject = () => {
    setRejectHandle(true);
  };

  const handleClickCloseReject = () => {
    setRejectHandle(false);
  };


  const verifyLoan = async () => {
    const response = await _approveLoan(id, {approvalNote: approvalNote});
    if (response.ok) {
      toast.success("Successfully Approved loan", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      const statusObj = loan.find((el) => el.name === "status");
      statusObj.value = "Approved";
      setLoan([...loan]);
    //   result.status = "Approved";
      // const url = "/admin/verification";
      // setTimeout(() => history.push(url), 3000);
    } else {
      toast.error("Oops! an error occured", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    setVerifiedHandle(false);
  };

  const rejectLoan = async () => {
    const body = {
      id: id,
      rejectionNote: rejectionNote,
      rejectionReason: rejectReason,
    };
    const response = await _rejectLoan(id, body);
    // check response
    if (response.ok) {
      setRejectReason("");
      toast.success("Loan Rejected successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // const url = "/admin/verification";
      // setTimeout(() => history.push(url), 3000);
    //   data.verification.status = "Rejected";
      const statusObj = loan.find((el) => el.name === "status");
      statusObj.value = "Rejected";
      setLoan([...loan]);
    } else {
      toast.error("Oops! an error occured", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    setRejectHandle(false);
  };

  return (
    <>
      <UserNotesModal
        open={isUserNotesModalOpen}
        onClose={() => setIsUserNotesModalOpen(false)}
      />
      <div className="mx-4">
        <div className="flex items-center bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          <div className="w-1/2">Filter by user</div>
          <div className="w-1/2">
            <input
              type="text"
              placeholder="Enter user name or email"
              onChange={(e) => setUserFilter(e.target.value)}
              className="w-full rounded px-4 placeholder-black"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mt-4">
        {id ? (
          <>
            {loan ? (
              <div className="w-full bg-white rounded-xl">
                <div className="flex justify-between px-8 py-4">
                  <button
                    onClick={(e) => props.history.goBack()}
                    className="ml-2 mt-3 text-sm flex flex-row"
                  >
                    <svg
                      version="1.1"
                      id="Capa_1"
                      className="w-5 h-5 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 26.676 26.676"
                    >
                      <g>
                        <path
                          d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                        />
                      </g>
                    </svg>
                    <div className="underline px-1">Go back</div>
                  </button>
                  {/* <button
                    onClick={() => {
                      setIsUserNotesModalOpen(true);
                    }}
                    className="focus:outline-none text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-500 dark:focus:ring-red-700"
                  >
                    Notes
                  </button> */}
                  <div className="ml-auto">
               {loan && loan[1].value=='Pending' ?<><button
                  className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600 mr-4"
                  onClick={handleClickOpenVerify}
                >
                  Verify
                </button>
                <Dialog
                  fullWidth={true}
                  maxWidth="sm"
                  open={verified}
                  onClose={handleClickCloseVerify}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogContent>
                    <DialogContentText>
                      Approve the loan? Leave a note
                    </DialogContentText>
                    <textarea
                      placeholder="Leave a note"
                      value={approvalNote}
                      onChange={(e) => {
                        setApprovalNote(e.target.value);
                      }}
                      rows="3"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md placeholder-black"
                    ></textarea>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClickCloseVerify} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={verifyLoan} color="primary">
                      Approve
                    </Button>
                  </DialogActions>
                </Dialog>
                <button
                  className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                  onClick={handleClickOpenReject}
                >
                  Reject
                </button>
                <Dialog
                  fullWidth={true}
                  maxWidth="md"
                  open={reject}
                  onClose={handleClickCloseReject}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogContent>
                    <DialogContentText>
                      Please specify a reason for the rejection.
                    </DialogContentText>
                    <textarea
                      placeholder="Please specify a reason for the rejection."
                      required
                      value={rejectReason}
                      onChange={(e) => {
                        setRejectReason(e.target.value);
                      }}
                      rows="3"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md placeholder-black"
                    ></textarea>
                    <DialogContentText>Note</DialogContentText>
                    <textarea
                      placeholder="Leave a note"
                      value={rejectionNote}
                      onChange={(e) => {
                        setRejectionNote(e.target.value);
                      }}
                      rows="3"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md placeholder-black"
                    ></textarea>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClickCloseReject} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={rejectLoan} color="primary">
                      Reject
                    </Button>
                  </DialogActions>
                </Dialog>
                </> :
                <p
                className={`w-full text-right text-sm bg-white pt-4 pr-4 font-bold ${
                  
                    loan[1].value === "Rejected"
                    ? "text-red-600"
                    : "text-primary"
                }`}
              >
                This loan is {loan[1].value}
              </p>}
              </div>
                </div>
                <DynamicInfoRender fields={loan} validation={validateSchema} />
              </div>
            ) : (
              <>
                <div className="my-4">
                  <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                    {[...Array(4)].map((e, i) => (
                      <div
                        key={i}
                        className="animate-pulse my-10 flex space-x-4"
                      >
                        <div className="flex-1 space-y-4 py-1">
                          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="w-full mb-12 px-4">
            <div className="flex justify-end bg-white pt-4 pr-4"></div>
            <CardUser props={props} userFilter={userFilter} color="dark" />
          </div>
        )}
      </div>
    </>
  );
}
