import React, { useEffect, useState } from "react";
import { _getLandingCopy, _editLandingCopy } from "../../services/apiService";
import ReactJson from 'react-json-view'

export default function Editcopy(props) {
  const [landingCopy, setLandingCopy] = useState(null);
  function editJson(json) { 
    _editLandingCopy({updateData:json.updated_src});
  };
  useEffect(() => {
    async function fetchData() {
      let result = await _getLandingCopy();
      setLandingCopy(result);
    }
    fetchData();
  }, []);
  return <>{landingCopy?
    <ReactJson src={JSON.parse(landingCopy['jsonBlob'])} collapsed={true} onEdit={(edit)=>editJson(edit)}/>:''}</>;
}
