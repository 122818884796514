import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
// import styled from 'styled-components';
import { _getAacquisitions } from "../../services/apiService";
import moment from "moment";

const columns = [
  {
    name: "User",
    selector: "user.fullName",
    sortable: true,
    wrap: true,
  },
  {
    name: "Prize",
    selector: "prize.name",
    sortable: true,
    wrap: true,
  },
  {
    name: "Acquisition Date",
    selector: "created",
    sortable: true,
    wrap: true,
  },
  {
    name: "Seeds spent",
    selector: "pointSpent",
    sortable: true,
    wrap: true,
  },
  {
    name: "Prize Type",
    selector: "prizeType",
    sortable: true,
    wrap: true,
  },
];

export default function CardRedeem(props) {
  const [redeem, setRedeem] = useState(null);

  function redeemClicked(e) {
    const history = props.props.history;
    const url = "/admin/redeem/" + e._id;
    history.push(url);
  }
  useEffect(() => {
    async function fetchData() {
      // You can await here
      let result = await _getAacquisitions();
      setRedeem(result);
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  redeem &&
    redeem.map(
      (red, i) =>
        (red.created = moment(red.created).format("h:mm a, MMMM Do YYYY"))
    );

  return redeem ? (
    <DataTable
      className="cursor-pointer"
      title="Redeem"
      columns={columns}
      pagination={true}
      paginationPerPage={8}
      data={redeem}
      onRowClicked={(e) => redeemClicked(e)}
      // customStyles={customStyles}
    />
  ) : (
    <>
      <div className="my-4">
        <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          {[...Array(4)].map((e, i) => (
            <div key={i} className="animate-pulse my-10 flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                <div className="space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
