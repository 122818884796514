import React, { useEffect, useState } from "react";
import { CSVLink } from 'react-csv';
import { _getProducts, _getLoans, _getLoanApplicationsPartner } from "../../services/apiService";

export default function Reports() {

  const [productsData, setProductsData] = useState(null);
  const [loansData, setLoansData] = useState(null);
  const [loanApplicationData, setLoanApplicationData] = useState(null);

  useEffect(() => {
    function createLoanAppCsv(data) {
      if(data.length>0) {
        const temp = [];
    data.forEach(el => {
      const headersMap = {'name':1,'description':1,'securityType':1,'repaymentOption':1,
    'allowKSAPToOffer':1,'type':1,'email':1,'fullName':1};
      const parsed = JSON.parse(el.loanInfoImmutable);
      Object.keys(parsed).forEach(key => {
        if(headersMap[key]) {
          headersMap[key] = parsed[key];
        }
      } )
      Object.keys(el.user).forEach(key => {
        if(headersMap[key]) {
          headersMap[key] = el.user[key];
        }
      } );
      temp.push(headersMap);
    });
    setLoanApplicationData(temp);
      } else {
        setLoanApplicationData([]);
      }
      
    }

    async function fetchDataProduct() {
      // You can await here
      let result = await _getProducts();
      // let tempData = [];
      setProductsData(result);
      if(result.length>0) {
        // tempData.push(Object.keys(result[0]));
        // // parse
        // result.forEach(el => tempData.push(Object.values(el)));
      }
      // console.log("tempData:",tempData);
      
    }
    async function fetchDataLoan() {
      // You can await here
      let result = await _getLoans();
      // let tempData = [];
      setLoansData(result);
      
    }
    async function fetchDataLoanApp() {
      // You can await here
      let result = await _getLoanApplicationsPartner();
      //setLoanApplicationData(result);
      createLoanAppCsv(result);
      // let tempData = [];
      
    }
      Promise.all([fetchDataLoan(),fetchDataProduct(),fetchDataLoanApp()]);
  }, []); // Or [] if effect doesn't need props or state


  return (
    <>
      <div className="mb-4 lg:flex lg:items-center bg-white p-5 rounded-xl lg:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-xl sm:truncate">
            Products download
          </h2>
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
                <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
              </svg>
              Download your product's details in csv
            </div>
          </div>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4">
          <span className="sm:ml-3">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme-main hover:bg-theme-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={productsData && productsData.length > 0 ? false: true}
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              {productsData && productsData.length>0 ?
              <CSVLink data={productsData} filename={"products-"+ Date.now() + ".csv"}>Download</CSVLink>:'Download'}
            </button>
          </span>
        </div>
      </div>

      <div className="mb-4 lg:flex lg:items-center bg-white p-5 rounded-xl lg:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-xl sm:truncate">
            Loans download
          </h2>
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
                <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
              </svg>
              Download your loan details in csv
            </div>
          </div>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4">
          <span className="sm:ml-3">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme-main hover:bg-theme-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={loansData && loansData.length > 0 ? false: true}
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              {loansData && loansData.length>0 ?
              <CSVLink data={loansData} filename={"loans-"+ Date.now() + ".csv"}>Download</CSVLink>:'Download'}
              </button>
          </span>
        </div>
      </div>

      <div className="mb-4 lg:flex lg:items-center bg-white p-5 rounded-xl lg:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-xl sm:truncate">
            Applications download
          </h2>
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
                <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
              </svg>
              Download loan applications in csv
            </div>
          </div>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4">
          <span className="sm:ml-3">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme-main hover:bg-theme-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={loanApplicationData && loanApplicationData.length > 0 ? false: true}
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              {loanApplicationData && loanApplicationData.length>0 ?
              <CSVLink data={loanApplicationData} filename={"loan-application-"+ Date.now() + ".csv"}>Download</CSVLink>:'Download'}
            </button>
          </span>
        </div>
      </div>
    </>
  );
}
