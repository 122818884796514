import React, { useState, useEffect } from "react";
import { _getPublicLoans, _applyLoan } from "../../services/apiService";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function DetailedView() {
  const [loans, setLoans] = useState(null);
  let { id } = useParams();

  async function applyLoan(loadId) {
    if (loadId) {
      const data = {
        loanId: loadId,
      };
      const response = await _applyLoan(data);
      const message = await response.text();
      if (response.ok) {
        toast.success(message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
        toast.error(message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    }
  }

  useEffect(() => {
    async function fetchData() {
      // You can await here
      let result = await _getPublicLoans();
      result.forEach((information, i) => {
        information.created = moment(information.startDate).format(
          "h:mm a, MMMM Do YYYY"
        );
      });
      setLoans(result);
      // setFilteredItems(result)
      // ...
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  return (
    <div>
      {loans &&
        loans.map((loan) =>
          id === loan._id ? (
            <section className="text-gray-600 body-font overflow-hidden">
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <div className="bg-white rounded-lg container px-5 py-12 mx-auto">
                <div className="lg:w-4/5 mx-auto flex flex-wrap">
                  <img
                    alt="ecommerce"
                    className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
                    src={loan.loanImage}
                  />
                  <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                    <h2 className="text-sm title-font text-gray-500 tracking-widest">
                      {loan.type}
                    </h2>
                    <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
                      {loan.name}
                    </h1>
                    <div className="flex mb-4"></div>
                    <div className="leading-relaxed">{loan.description}</div>
                    <div>
                      <div className="my-0.5 text-sm">
                        Max Interest: {loan.maximumInterestRate}
                      </div>
                      <div className="my-0.5 text-sm">
                        Min Interest: {loan.minimumInterestRate}
                      </div>
                      <div className="my-0.5 text-sm">
                        Max Term: {loan.maximumTerm}
                      </div>
                      <div className="my-0.5 text-sm">
                        Min Term: {loan.minimumTerm}
                      </div>
                      <div className="my-0.5 text-sm">
                        Security Type: {loan.securityType}
                      </div>
                      <div className="my-0.5 text-sm">
                        Status: {loan.status}
                      </div>
                      <div className="my-0.5 mb-4 text-sm">
                        Type: {loan.type}
                      </div>
                    </div>
                    <span className="title-font font-medium text-2xl text-gray-900">
                      ${loan.maximumAmount}
                    </span>
                    <div>
                      <button
                        onClick={() => applyLoan(loan._id)}
                        className="ml-auto text-white bg-theme-main border-0 my-3 py-2 px-6 focus:outline-none hover:bg-theme-main-hover rounded"
                      >
                        Apply Now
                      </button>
                    </div>
                    <div className="mt-5 text-lg text-faded italic">
                      Offered by:-
                    </div>
                    <div>
                      <img
                        alt="logo"
                        className="h-20 rounded-lg"
                        src={loan.partner.logo}
                      />
                    </div>
                    <div className="font-thin">{loan.partner.name}</div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <div></div>
          )
        )}
    </div>
  );
}
export default DetailedView;
