import React, { useEffect, useState } from "react";
import CardProducts from "../../components/Cards/CardProducts";
import DynamicForm from "../../components/DynamicForm";
import { _getProduct, _submitProduct } from "../../services/apiService";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import * as yup from "yup";
import moment from "moment";

import { useParams } from "react-router-dom";

export default function Products(props) {
  let { id } = useParams();
  const [productFormData, setProductFormData] = useState(null);
  const [formValidateSchema, setFormValidateSchema] = useState(null);
  const [productApiData, setProductApiData] = useState(null);
  const [productFormDataEdit, setProductFormDataEdit] = useState(null);
  const [formEditValidateSchema, setFormEditValidateSchema] = useState(null);
  const [product, setProduct] = useState(null);
  const [validateSchema, setValidateSchema] = useState(null);
  const [productMode, setProductMode] = useState("add");

  const fieldsInfo = React.useMemo(
    () => [
      {
        name: "created",
        label: "Created On",
        placeholder: "Created On",
        hiddenOnForm: true,
        type: "date",
        validationType: "date",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Created Date is required"],
          },
        ],
      },
      {
        name: "updated",
        label: "Updated On",
        placeholder: "Enter Updated Created On",
        hiddenOnForm: true,
        type: "date",
        validationType: "date",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Updated Date is required"],
          },
        ],
      },
      {
        name: "name",
        label: "Product Name",
        placeholder: "Product Name",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Product Name is required"],
          },
        ],
      },
      {
        name: "type",
        label: "type",
        placeholder: "Enter type",
        type: "select",
        validationType: "string",
        value: "",
        defaultOption: "Select type",
        validations: [
          {
            type: "required",
            params: ["type is required"],
          },
        ],
        options: ["Personal", "Business", "Both"],
      },
      {
        name: "shortCode",
        label: "Product Short Code",
        placeholder: "Product Short Code",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Product Short Code is required"],
          },
        ],
      },
      {
        name: "status",
        label: "Product Status",
        placeholder: "Enter Product Status",
        type: "select",
        validationType: "string",
        value: "",
        defaultOption: "Select Product Status",
        validations: [
          {
            type: "required",
            params: ["Product Status is required"],
          },
        ],
        options: ["Active", "Inactive"],
      },
      {
        name: "loanRequirementCriteria",
        label: "Requirement Criteria",
        placeholder: "Enter Requirement Criteria",
        type: "select",
        validationType: "string",
        value: "",
        defaultOption: "Select Requirement Criteria",
        validations: [
          {
            type: "required",
            params: ["Requirement Criteria is required"],
          },
        ],
        options: ["DSR", "Free Cash Flow", "Both"],
      },
      {
        name: "description",
        label: " Description",
        placeholder: "Enter Description",
        type: "longText",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Description is required"],
          },
        ],
      },
      {
        name: "productImage",
        label: "Product's image",
        placeholder: "Upload Your Product's image",
        type: "file",
        validationType: "mixed",
        value: "",
        validations: [
          {
            type: "notrequired",
            params: ["product's image is required"],
          },
          {
            type: "test",
            maxFileSize: 10,
            fileTypes: ["jpg", "jpeg", "png"],
          },
        ],
      },
    ],
    []
  );

  const messagesAdd = {
    success: "Product added successfully",
    error: "Can not add product",
  };

  const messagesEdit = {
    success: "Product edited successfully",
    error: "Can not edit product",
  };

  function prepareProductAddForm() {
    let fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));

    fieldsInfoCopy = fieldsInfoCopy.filter((el) => el.hiddenOnForm !== true);

    const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);

    setFormValidateSchema(validateSchema);
    setProductFormData(fieldsInfoCopy);
  }

  function prepareProductEditForm() {
    const result = productApiData;
    let fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
    fieldsInfoCopy = fieldsInfoCopy.filter((el) => el.hiddenOnForm !== true);
    fieldsInfoCopy.forEach((fieldObj) => {
      if (result[fieldObj["name"]] || result[fieldObj["name"]] === 0) {
        let value = result[fieldObj["name"]];
        if (fieldObj["validationType"] === "number") {
          value = parseInt(value);
        } else {
          if (value.constructor === Array) {
            value = value.map((el) => el.name).join(",");
          } else if (typeof value === "object" && value !== null) {
            value = value._id;
          }
        }
        if (fieldObj["validationType"] === "date") {
          fieldObj["value"] = moment(value).format("YYYY-MM-DD");
        } else {
          fieldObj["value"] = value;
        }
      }
    });
    const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);
    setFormEditValidateSchema(validateSchema);
    setProductFormDataEdit(fieldsInfoCopy);
  }
  function addProduct() {
    prepareProductAddForm();
    const url = "/partner/products/add";
    props.history.push(url);
    //id='add';
  }

  function editProduct() {
    prepareProductEditForm();
    setProductMode("edit");
    //id='add';
  }

  async function submitData(data = "") {
    if (data) {
      const response = await _submitProduct(data);
      return response;
    }
  }

  async function editData(data = "") {
    if (data) {
      data._id = id;
      const response = await _submitProduct(data);
      return response;
    }
  }

  async function changeUrl() {
    const url = "/partner/products";
    props.history.push(url);
  }

  useEffect(() => {
    function prepareProductForm(result) {
      const fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
      fieldsInfoCopy.forEach((fieldObj) => {
        if (result[fieldObj["name"]] || result[fieldObj["name"]] === 0) {
          let value = result[fieldObj["name"]];
          if (fieldObj["validationType"] === "number") {
            value = parseInt(value);
          } else if (fieldObj["validationType"] === "date") {
            fieldObj["type"] = "text";
          } else {
            if (value.constructor === Array) {
              value = value.map((el) => el.name).join(",");
            } else if (typeof value === "object" && value !== null) {
              value = value.name;
            }
          }
          if (fieldObj["validationType"] === "date") {
            fieldObj["value"] = moment(value).format("YYYY-MM-DD");
          } else {
            fieldObj["value"] = value;
          }
        }
      });
      const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
      const validateSchema = yup.object().shape(yepSchema);
      setValidateSchema(validateSchema);
      setProduct(fieldsInfoCopy);
    }
    async function fetchData() {
      // You can await here
      let result = await _getProduct(id);
      setProductApiData(result);
      prepareProductForm(result);
      // ...
    }
    if (id && id !== "add") {
      fetchData();
    }
    setProductMode("add");
  }, [id, fieldsInfo]); // Or [] if effect doesn't need props or state

  return (
    <>
      <div className="flex flex-wrap">
        {id && id !== "add" ? (
          <>
            {product ? (
              <>
                {productMode === "add" ? (
                  <div className="w-full">
                    {/* add mode */}

                    <div className="flex justify-between bg-white pt-4 pr-4">
                      <button
                        onClick={(e) => props.history.goBack()}
                        className="ml-2 text-sm flex flex-row"
                      >
                        <svg
                          version="1.1"
                          id="Capa_1"
                          className="w-5 h-5 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 26.676 26.676"
                        >
                          <g>
                            <path
                              d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                            />
                          </g>
                        </svg>
                        <div className="underline px-1">Go back</div>
                      </button>
                      <div className="flex justify-end space-x-2">
                        <button
                          className="inline-flex justify-center focus:outline-none py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                          onClick={() => editProduct()}
                        >
                          Edit Product
                        </button>
                      </div>
                    </div>
                    <DynamicInfoRender
                      fields={product}
                      validation={validateSchema}
                    />
                  </div>
                ) : (
                  // edit mode
                  <div className="w-full">
                    <div className="flex justify-between bg-white pt-4 pr-4">
                      <div className="text-bold w-2/3 text-xl pl-4">
                        Edit Product Information
                      </div>
                      <div className="flex justify-end">
                        <button
                          className="inline-flex focus:outline-none justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                          onClick={() => setProductMode("add")}
                        >
                          Cancel Edit
                        </button>
                      </div>
                    </div>
                    <DynamicForm
                      onSubmit={editData}
                      history={props.history}
                      messages={messagesEdit}
                      dialogMessage="Are you sure you want to submit?"
                      onSuccess={changeUrl}
                      redirect={false}
                      fields={productFormDataEdit}
                      validation={formEditValidateSchema}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="my-4">
                  <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                    {[...Array(4)].map((e, i) => (
                      <div
                        key={i}
                        className="animate-pulse my-10 flex space-x-4"
                      >
                        <div className="flex-1 space-y-4 py-1">
                          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </>
        ) : id && id === "add" ? (
          <div className="w-full bg-white">
            <button
              onClick={(e) => props.history.goBack()}
              className="ml-2 py-2 text-sm flex flex-row"
            >
              <svg
                version="1.1"
                id="Capa_1"
                className="w-5 h-5 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 26.676 26.676"
              >
                <g>
                  <path
                    d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                  />
                </g>
              </svg>
              <div className="underline px-1">Go back</div>
            </button>

            <DynamicForm
              onSubmit={submitData}
              history={props.history}
              messages={messagesAdd}
              onSuccess={changeUrl}
              dialogMessage="Are you sure you want to submit?"
              redirect={false}
              fields={productFormData}
              validation={formValidateSchema}
            />
          </div>
        ) : (
          <div className="w-full mb-12 px-4">
            <div className="flex justify-end bg-white pt-4 pr-4">
              <div>
                <button
                  className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                  onClick={() => addProduct()}
                >
                  Add Product
                </button>
              </div>
            </div>
            <CardProducts props={props} color="dark" />
          </div>
        )}
      </div>
    </>
  );
}
