import React, { Component, Fragment } from "react";
import { Formik, Field } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class DynamicInfoRender extends Component {
  state = {
    isSubmitted: false,
  };

  notify(isSuccess) {
    let message = "";
    if (isSuccess) {
      // message = "Yay! Information successfuly submitted";
      // toast.success(message, {
      //   position: toast.POSITION.BOTTOM_LEFT,
      // });
    } else {
      message = "Oops! Something Went wrong. Please try again";
      toast.error(message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }

  async submitForm(formData, resetForm, history) {
    this.setState({
      isSubmitted: true,
    });
    const response = await this.props.onSubmit(formData);

    if (response.ok) {
      resetForm({});
      setTimeout(() => history.push("/"), 3000);
    }

    this.notify(response.ok);
    setTimeout(
      () =>
        this.setState({
          isSubmitted: false,
        }),
      1000
    );
  }

  renderFileType(input) {
    if (!input.value || input.value === "NOT AVAILABLE") {
      return;
    }
    const isFile =
      input.value.indexOf("pdf") !== -1 || input.value.indexOf("xlsx") !== -1;
    return !isFile ? (
      <div className="col-span-12 sm:col-span-6" key={input.name}>
        <label className="block text-sm font-medium text-gray-700">
          {input.label}
        </label>
        <div className="rounded-lg h-64 overflow-hidden">
          <img
            alt="content"
            className="object-cover object-center h-full w-full"
            src={input.value}
          />
        </div>
      </div>
    ) : (
      <div
        className={`mt-1 col-span-12 sm:col-span-6 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md }`}
      >
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600 justify-center">
            <label
              htmlFor={input.name}
              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 "
            >
              <span>
                <a target="_blank" rel="noreferrer" href={input.value}>
                  View {input.label} File by clicking
                </a>
              </span>
            </label>
            {/* To do add drag drop
      <p className="pl-1">or drag and drop</p> */}
          </div>
        </div>
      </div>
    );
  }

  renderlongText(input) {
    return (
      <div className="col-span-12 sm:col-span-6" key={input.name}>
        <label
          htmlFor="{input.label}"
          className="block text-sm font-medium text-gray-700"
        >
          {input.label}
        </label>
        <Field
          name={input.name}
          render={(props) => {
            const { field } = props;
            const { errors, touched } = props.form;
            const hasError =
              errors[input.name] && touched[input.name] ? "hasError" : "";
            return (
              <>
                <textarea
                  {...field}
                  disabled
                  placeholder={input.placeholder}
                  id={hasError}
                  type={input.type}
                  rows="3"
                  className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md ${
                    hasError ? "border-red-700" : ""
                  }`}
                ></textarea>
                {hasError ? (
                  <div className="error text-xs font-semibold text-red-700">
                    {errors[input.name]}
                  </div>
                ) : null}
              </>
            );
          }}
        />
      </div>
    );
  }

  renderSelect(input) {
    return (
      <div className="col-span-6 sm:col-span-3" key={input.name}>
        <label
          htmlFor="{input.label}"
          className="block text-sm font-medium text-gray-700"
        >
          {input.label}
        </label>
        <Field
          name={input.name}
          render={(props) => {
            const { field } = props;
            const { errors, touched } = props.form;
            const hasError =
              errors[input.name] && touched[input.name] ? "hasError" : "";
            return (
              <>
                <select
                  disabled
                  {...field}
                  id={hasError}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value=""> </option>
                  {input.options.map((option, index) => (
                    <option key={index} value={option}>
                      {" "}
                      {option}{" "}
                    </option>
                  ))}
                </select>
                {hasError ? (
                  <div className="error text-xs font-semibold text-red-700">
                    {errors[input.name]}
                  </div>
                ) : null}
              </>
            );
          }}
        />
      </div>
    );
  }

  renderCheckbox(input) {
    return (
      <div className="col-span-12 sm:col-span-6" key={input.name}>
        {/* <label htmlFor="{input.label}" className="block text-sm font-medium text-gray-700">{input.label}</label> */}
        <Field
          name={input.name}
          render={(props) => {
            const { field } = props;
            const { errors, touched } = props.form;
            const hasError =
              errors[input.name] && touched[input.name] ? "hasError" : "";
            return (
              <>{input.label ?<div style={{paddingBottom:"2%"}}>
              {input.label}
            </div>:''}
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      checked={field.value}
                      {...field}
                      id={hasError}
                      name={input.name}
                      type="checkbox"
                      disabled
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor={input.name}
                      className="font-medium text-gray-700"
                    >
                      {input.checkboxTitle}
                    </label>
                    <p className="text-gray-500">{input.checkboxDescription}</p>
                  </div>
                </div>
                {hasError ? (
                  <div className="error text-xs font-semibold text-red-700">
                    {errors[input.name]}
                  </div>
                ) : null}
              </>
            );
          }}
        />
      </div>
    );
  }

  isFieldRequired(input, form) {
    if (!form) {
      return true;
    }
    let isRequired = true;
    // decides if a field is required
    // check conditionalValidations
    const hasConditional = input.conditionalValidations ? true : false;
    // check first validation
    if (hasConditional) {
      const condition = input.conditionalValidations[0];
      if (condition.compareType === "not equal") {
        isRequired =
          form.values[condition["when"]] &&
          form.values[condition["when"]] !== condition["value"];
      } else if (condition.compareType === "equal") {
        isRequired =
          form.values[condition["when"]] &&
          form.values[condition["when"]] === condition["value"];
      } else if (condition.compareType === "greaterThanEqual") {
        isRequired =
          form.values[condition["when"]] &&
          form.values[condition["when"]] >= condition["value"];
      }
    }
    return isRequired;
  }
  renderFields(inputs, form) {
    return inputs.map((input) => {
      if (!this.isFieldRequired(input, form)) {
        return "";
      }
      if (input.type === "longText") {
        return this.renderlongText(input);
      } else if (input.type === "file" || input.type === "urltext") {
        return this.renderFileType(input);
      } else if (input.type === "select") {
        return this.renderSelect(input);
      } else if (input.type === "checkbox") {
        return this.renderCheckbox(input);
      }
      return (
        <div className="col-span-6 sm:col-span-3" key={input.name}>
          <label
            htmlFor="{input.label}"
            className="block text-sm font-medium text-gray-700"
          >
            {input.label}
          </label>
          <Field
            name={input.name}
            render={(props) => {
              const { field } = props;
              const { errors, touched } = props.form;
              const hasError =
                errors[input.name] && touched[input.name] ? "hasError" : "";
              return (
                <>
                  <input
                    {...field}
                    disabled
                    placeholder={input.placeholder}
                    id={hasError}
                    type={input.type}
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      hasError ? "border-red-700" : ""
                    }`}
                  />
                  {hasError ? (
                    <div className="error text-xs font-semibold text-red-700">
                      {errors[input.name]}
                    </div>
                  ) : null}
                </>
              );
            }}
          />
        </div>
      );
    });
  }

  getInitialValues(inputs) {
    //declare an empty initialValues object
    const initialValues = {};
    //loop loop over fields array
    //if prop does not exit in the initialValues object,
    // pluck off the name and value props and add it to the initialValues object;
    inputs.forEach((field) => {
      if (!initialValues[field.name]) {
        initialValues[field.name] =
          field.value || field.value === 0 || field.value === false
            ? field.value
            : "NOT AVAILABLE";
      }
    });

    //return initialValues object
    return initialValues;
  }

  render() {
    const initialValues = this.getInitialValues(this.props.fields);
    return (
      <Formik
        onSubmit={(values, { resetForm }) => {
          this.setState({
            isSubmitted: true,
          });
          this.submitForm(values, resetForm, this.props.history);
        }}
        validationSchema={this.props.validation}
        initialValues={initialValues}
        enableReinitialize
        render={(form) => {
          return (
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <form onSubmit={form.handleSubmit}>
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    {this.props.fields.length === 0
                      ? `No Verification field was required.`
                      : ""}
                    <div className="grid grid-cols-6 gap-6">
                      {this.renderFields(this.props.fields, form)}
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    {/* <button type="submit" 
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                  disabled={this.state.isSubmitted}
                  >
                    {this.state.isSubmitted ? 
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>:''}
                  Submit
                  </button> */}
                  </div>
                </form>
                <ToastContainer
                  position="bottom-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </div>
          );
        }}
      />
    );
  }
}

export default DynamicInfoRender;
