import { taskCardSvg } from "../../assets/svgs/svg";

function TaskCard(props) {
  try {
  console.log("props:",props)
  const data = props.data;
  let isActive = true;
  const isSubmitted = data.userStatus === "Submitted" || props.status;
  const path = isSubmitted ? "/verification" : "/information";
  const schemaData = isSubmitted ? data.verificationSchema.fields : data.fields;
  const typeData = isSubmitted ? data.verificationSchema : data;
  const showVerification =
    isSubmitted && data.status && data.status === "Submitted";
    const showVerified =
    isSubmitted && data.status && data.status === "Verified";
    const showRejection =
    isSubmitted && data.status && data.status === "Rejected";
  
  if (props.disablePossible) {
    isActive =
      data.status === "Active" && props.disablePossible && !data.isHidden;
  }

  function redirect() {
    if (props.redirect) {
      if (path === "/information") {
        props.props.history.push({
          pathname: path,
          state: {
            data: schemaData,
            id: typeData._id,
            allData: data,
            allInfo: props.allInfo,
            index: props.keyIndex,
          },
        });
      } else {
        props.props.history.push({
          pathname: path,
          state: {
            data: schemaData,
            id: typeData._id,
            allData: data,
            allInfo: props.allInfo,
            index: props.keyIndex,
          },
        });
      }
    }
  }

  return (
    <div className="flex-col space-y-3 my-3 cursor-pointer relative rounded-xl">
      <div
        className={`grid grid-cols-12 gap-3 bg-white rounded-lg shadow-sm hover:shadow-lg duration-500 p-3 items-center ${
          isActive ? "opacity-100" : "opacity-50"
        } ${isActive ? "pointer-events-auto" : "pointer-events-none"}`}
        onClick={() => redirect()}
      >
        <div className="col-span-0 sm:col-span-2 text-center hidden sm:block">
          <span className="grid grid-rows-2 mx-auto mb-3 py-2 w-4/5 2lg:w-3/5 rounded-md  m-auto">
            {/* <div className="inline-block font-medium text-2xl text-white">
                    {responses} 
                    </div>

                    <div className="inline-block font-medium text-white mx-1 text-sm lg:text-md">
                        Response
                    </div> */}
            {/* {taskCardSvg.basicInfo} */}
            <svg
              id="Layer_1"
              className="pt-2"
              enableBackground="new 0 0 512 512"
              height="64"
              viewBox="0 0 610 610"
              width="64"
              xmlns="http://www.w3.org/2000/svg"
            >
              {taskCardSvg[data.name || data.tags]
                ? taskCardSvg[data.name]
                  ? taskCardSvg[data.name]
                  : taskCardSvg[data.tags]
                : taskCardSvg["Basic Info"]}
            </svg>
          </span>
        </div>

        {/* Mobile div */}
        <div className="col-span-12 sm:col-start-3 sm:col-end-13 px-3 sm:px-0">
          <div className="grid block sm:hidden">
            <div className="flex flex-wrap">
              <div className="mr-2">
                <div className="inline-block font-light capitalize">
                  <i className="uil uil-check-circle mr-1"></i>
                  <span className="text-sm">
                    <svg
                      id="Layer_1"
                      enableBackground="new 0 0 512 512"
                      height="64"
                      viewBox="0 0 610 610"
                      width="64"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {taskCardSvg[data.name]
                        ? taskCardSvg[data.name]
                        : taskCardSvg["Basic Info"]}
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2">
            {showVerification ? (
              <span className="bg-red-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                Click to submit supporting documents
              </span>
            ) : (
              ""
            )}
            {showRejection ? (
              <span className="bg-red-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                {data.submittedVerification && data.submittedVerification.rejectionReason ? data.submittedVerification.rejectionReason: 'Your verification has been Rejected'}
                
              </span>
            ) : (
              ""
            )}
            {showVerified ? (
              <span className="bg-red-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                Your verification has been Verified
              </span>
            ) : (
              ""
            )}
            <span className="sm:text-sm md:text-md lg:text-lg text-gray-700 font-bold hover:underline">
              {typeData && typeData["title"] ? typeData["title"] : ""}
            </span>

            <p className="text-gray-600 my-1 text-sm md:text-md">
              {typeData && typeData["description"]
                ? typeData["description"]
                : ""}
              <br />
              <span className="text-secondary font-bold text-xs">
                {" "}
                {isSubmitted ? "Verifying this " : "Completing this "} will give
                you {data["points"]} seeds{" "}
              </span>
            </p>
          </div>

          <div className="grid grid-cols-2 mt-2 my-auto">
            <div className="col-span-12 lg:col-span-8">
              {/* {isSubmitted ? <span className="inline-block rounded-full text-white 
                            bg-red-600 hover:bg-red-700 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                            Click to submit supporting documents
                        </span>: ''} */}
              <span
                className="inline-block rounded-full text-white 
                            bg-theme-analog hover:bg-theme-analog-hover duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100"
              >
                +{data["points"]} seeds
              </span>
              <span
                className="inline-block rounded-full text-white 
                            bg-theme-main hover:bg-theme-main-hover duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100"
              >
                {data["tags"]}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
                      } catch(err) {
                        console.log(err)
                      }
}

export default TaskCard;
