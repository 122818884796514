import React, { useState, useEffect } from "react";
import { _getLoanApplicationsUser } from "../../services/apiService";
import { useParams, Link } from "react-router-dom";
import moment from "moment";

function UserLoanApplicationDetailed() {
  let { id } = useParams();
  const [loans, setLoans] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let result = await _getLoanApplicationsUser();
      result.forEach((loan) => {
        if (loan._id === id) {
          loan.created = moment(loan.created).format("h:mm a, MMMM Do YYYY");
          loan.loanInfoImmutable = JSON.parse(loan.loanInfoImmutable);
          loan.productInfoImmutable = JSON.parse(loan.productInfoImmutable);
          setLoans(loan);
        }
      });
    }
    fetchData();
  }, [id]); // Or [] if effect doesn't need props or state

  return loans ? (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <Link to="/loan-application/">
          <div className="mt-5 ml-2 text-sm flex flex-row">
            <svg
              version="1.1"
              id="Capa_1"
              className="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 26.676 26.676"
            >
              <g>
                <path
                  d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                />
              </g>
            </svg>
            <div className="underline px-1">Go back</div>
          </div>
        </Link>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Applicant details
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Details of your loan application.
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Full name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.name}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Application for
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.type}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.status}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Loan Maximum Amount
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.maximumAmount}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.description}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Loan default Interest Rate
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.defaultInterestRate}%
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Maximum Term
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.maximumTerm}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Minimum Term
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.minimumTerm}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  ) : (
    <div>This is detailed view</div>
  );
}

export default UserLoanApplicationDetailed;
