import React from "react";
import CardLoanApplicationsUser from "../../components/Cards/CardLoanApplicationsUser";
import { useParams } from "react-router-dom";
import UserLoanApplicationDetailed from "./UserLoanApplicationDetailed";

function UserLoanApplicationListView(props) {
  let { id } = useParams();
  return id ? (
    <div>
      <UserLoanApplicationDetailed />
    </div>
  ) : (
    <div>
      <CardLoanApplicationsUser props={props} />
    </div>
  );
}

export default UserLoanApplicationListView;
