
import OverviewComponent1  from "../../components/Dashboard/OverviewComponent1";
// import OverviewComponent2  from "../../components/Dashboard/OverviewComponent2";

function Dashboard(props) {


    return(
        <>
        <OverviewComponent1 props={props}/>
        {/* <OverviewComponent2 props={props}/> */}
        </>
    )
 }
 
 export default Dashboard;