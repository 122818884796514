import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { _getSubmittedInformations } from "../../services/apiService";

const columns = [
  {
    name: "User Name",
    selector: "user.fullName",
    wrap: true,
    sortable: true,
  },
  {
    name: "Submission Date",
    selector: "user.created",
    wrap: true,
    sortable: true,
  },
  {
    name: "Submission Stat",
    selector: "SubmissionStat",
    wrap: true,
    sortable: true,
  },
  {
    name: "Verification Stat",
    selector: "verificationStat",
    wrap: true,
    sortable: true,
  },
  {
    name: "Status",
    selector: "user.status",
    sortable: true,
    wrap: true,
    cell: (row, index, column, id) => {
      return (
        <button
          className={`${
            row.user.status === "Completed"
              ? "bg-green-500 hover:bg-green-700"
              : "bg-red-500 hover:bg-red-700"
          } text-white text-xs font-bold py-2 px-4 rounded pointer-events-none`}
        >
          {row.user.status}
        </button>
      );
    },
  },
];

function groupVerificationData(verifications) {
  const groupedData = {};
  verifications.forEach((verification) => {
    const userEmail = verification?.user?.email;
    if (!groupedData[userEmail]) {
      groupedData[userEmail] = [verification];
    } else {
      groupedData[userEmail].push(verification);
    }
  });
  return groupedData;
}

function getMostRecentDataFromGroup(group) {
  const hasModel5 = window.location.href.includes("model-5");
  const data = [];
  Object.values(group).forEach((arr) => {
    const mostRecent = arr.reduce((prev, current) =>
      prev.created > current.created ? prev : current
    );
    data.push(mostRecent);
  });

  const modifiedData = data.map((s) => {
    let verificationCount = 0;
    let pendingVerificationCount = 0;
    const verifications = group[s.user.email];
    console.log("verifications:",verifications)
    verifications.forEach((verification) => {
      if (verification) {
        verificationCount++;
        pendingVerificationCount +=
          verification.status === "Submitted" ||
          verification.status === "SubmittedForVerfication"
            ? 0
            : 1;
      }
    });
    return {
      ...s,
      user: {
        ...s.user,
        status: s.status === "Completed" ? "Completed" : "Pending",
      },
      verificationStat: `${pendingVerificationCount} / ${verificationCount}`,
      SubmissionStat: `${verifications.length > 0 && verifications[0].submittedInformationCount ? verifications[0].submittedInformationCount:0} / ${hasModel5 ? 6 : 9}`,
    };
  });
  return modifiedData;
}

export default function CardVerification(props) {
  const hasModel5 = window.location.href.includes("model-5");
  const history = useHistory();
  const [verificationItems, setVerificationItems] = useState(null);
  const [verificationItemsFilter, setVerificationItemsFilter] = useState(null);

  const handleChange = (val) => {
    if (val) {
      const filter = verificationItems.filter(
        (el) => el.user.fullName.toLowerCase().indexOf(val.toLowerCase()) !== -1
      );
      setVerificationItemsFilter(filter);
    } else {
      setVerificationItemsFilter(verificationItems);
    }
  };

  useEffect(() => {
    async function fetchData() {
      let response = await _getSubmittedInformations();
      if(hasModel5){
        response = response.filter((el) => el.verificationSchema.for &&
        el.verificationSchema.for === "PreQualUser");
      } else {
        response = response.filter((el) =>!el.verificationSchema.for ||
         el.verificationSchema.for !== "PreQualUser");
      }
      console.log(response)
      const groupedData = groupVerificationData(response);
      const mostRecentData = getMostRecentDataFromGroup(groupedData);
      setVerificationItems(mostRecentData);
      setVerificationItemsFilter(mostRecentData);
    }
    fetchData();
  }, [hasModel5]);

  function verificationItemClicked(e) {
    const hasModel5 = window.location.href.includes("model-5");
    const userId = e.user._id;
    const url = `/admin/verification/user/${hasModel5 ? 'model-5/':''}${userId}`;
    history.push(url);
  }
  return (
    <>
      <div className="my-4">
        <div className="flex bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          <div className="w-1/2">Filter by user</div>
          <div className="w-1/2">
            <input
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              type="text"
              className="w-full sm:w-1/2 px-4 loan placeholder-black"
              placeholder="Enter user name"
              style={{ color: "black" }}
            ></input>
          </div>
        </div>
      </div>
      {verificationItemsFilter ? (
        <DataTable
          className="cursor-pointer"
          title="Verification Panel"
          columns={columns}
          pagination={true}
          paginationPerPage={8}
          data={verificationItemsFilter}
          onRowClicked={(e) => verificationItemClicked(e)}
          // customStyles={customStyles}
        />
      ) : (
        <>
          <div className="my-4">
            <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
              {[...Array(4)].map((e, i) => (
                <div className="animate-pulse my-10 flex space-x-4" key={i}>
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                    <div className="space-y-2">
                      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                      <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}
