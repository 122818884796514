import { useField } from "formik";
import Select from "react-select";

const SelectInput = ({
  label,
  options,
  getOptionLabel,
  getOptionValue,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue, setTouched, setError } = helpers;

  const setFieldProps = (selectedOption) => {
    setValue(selectedOption.value);
    setTouched(true);
    setError(undefined);
  };

  const opts = options.map((option) => {
    const optionLabel = getOptionLabel(option);
    const optionValue = getOptionValue(option);
    return { label: optionLabel, value: optionValue };
  });

  return (
    <div className="relative">
      <label htmlFor={props.id || props.name}>{label}</label>
      <Select
        options={opts}
        isMulti={false}
        name={field.name}
        isSearchable={true}
        onChange={(selectedOption) => setFieldProps(selectedOption)}
        value={opts ? opts.find((opt) => opt.value === field.value) : ""}
      />
      {meta.touched && meta.error ? (
        <div className="error text-xs font-semibold text-red-700">
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default SelectInput;
