import React, { useState } from "react";
import NavBarLanding from "../../components/NavBarLanding/index";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../components/Footer/index";
import { _requestPasswordResetLink } from "../../services/apiService";
import { Link } from "react-router-dom";

import TextInput from "../../components/TextInput";

function RequestLink() {
  const [isDisabled, setIsDisabled] = useState(false);

  const notify = (isSuccess, message) => {
    if (isSuccess) {
      setIsDisabled(false);
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setIsDisabled(false);
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <div>
      <div
        className="relative overflow-hidden min-h-screen"
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <div className="relative">
          <div
            className="border-b-1 shadow-lg border-gray-600"
            style={{ backgroundColor: "white" }}
          >
            <NavBarLanding pageType="signup" />
          </div>
          <main className="mt-16 sm:mt-24">
            <div className="mx-auto max-w-7xl">
              <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                <div className="bg-white border border-gray-300 solid shadow-lg sm:max-w-3xl sm:w-full sm:mx-auto mx-8 sm:rounded-lg sm:overflow-hidden">
                  <div className="px-4 py-8 sm:px-10">
                    <div className="text-2xl font-bold">
                      Reset Your Password
                    </div>
                    <div className="mt-6">
                      <Formik
                        initialValues={{
                          email: "",
                        }}
                        validationSchema={Yup.object({
                          email: Yup.string()
                            .email("Invalid email address")
                            .required("Email is Required"),
                        })}
                        onSubmit={(values, { resetForm }) => {
                          setIsDisabled(true);
                          let _data = {
                            email: values.email,
                          };
                          setTimeout(async () => {
                            let response = await _requestPasswordResetLink(
                              _data
                            );
                            setIsDisabled(false);
                            const message = await response.text();
                            if (response.ok) {
                              resetForm();
                              notify(true, message);
                            } else {
                              notify(false, message);
                            }
                            // setSubmitting(false);
                          }, 400);
                        }}
                      >
                        <Form className="lg:grid grid-cols-2 gap-1">
                          <div className="col-start-3 col-end-4"></div>
                          <div className="col-span-3">
                            <TextInput
                              name="email"
                              type="email"
                              placeholder="Email address"
                            />
                          </div>
                          <div className="col-start-1 col-end-3 mt-3">
                            <button
                              type="submit"
                              className="block w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme-main hover:bg-theme-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              disabled={isDisabled}
                            >
                              Send Password reset link
                            </button>
                          </div>
                        </Form>
                      </Formik>
                      <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    </div>
                    <p className="text-center text-primary text-sm mt-2 underline font-medium">
                      <Link to="/">Login instead</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default RequestLink;
