import React from "react";
import NavBarLanding from "../../components/NavBarLanding/index";
import Footer from "../../components/Footer";

const Faq =()=>{
    return <div className="w-full bg-gray-400">
        <NavBarLanding/>
        <article>
            <h1 className=" font-bold text-2xl text-center pt-4">Frequently Asked Questions!</h1>
            <div className=" md:px-18 sm:px-48 lg:px24 px-8">
                <h3 className=" text-xl font-semibold py-4">1. What exactly is Seed Jamaica?</h3>
                <p className=" pl-2">
                    Seed Jamaica is a people first, digital microfinance company that provides loans to the everyday person in Jamaica. From the banked, to the underbanked and unbanked, we know how hard it is to get a loan from the traditional institutions so we now exist to provide access to finance to all Jamaicans.
                </p>
                <h3 className=" text-xl font-semibold py-4">2. You ask for a lot of Data, why is that ?</h3>
                <p className=" pl-2">
                    One of the reasons why majority of persons do not qualify for a loan at a typical financial institution is due to the fact that these banks have no information on you. The only information they end up with is when they request a credit report which will indicate your borrowing history ( the good and the bad). But, is that an adequate summary of who you are? At Seed, we won’t ask you for a credit report, we want to see you for who you are and to do that we need to collect as much data as possible to make the best decision as it relates to your situation. 
                </p>
                <h3 className=" text-xl font-semibold py-4">3. How does it Work?</h3>
                <p className=" pl-2">
                    a) Visit our Platform, www.seedja.com <br/>
                    b) Complete your profile by answering the questions on the following areas of your life <br/>
                    <span className="pl-4">
                        - Demographic <br/>
                        - Income <br/>
                        - Debt <br/>
                        - Employment <br/> 
                        - Expenses <br/>
                    </span>
                    c) Submit supporting documents so we can verify the information you submitted is true.<br/>
                    d) We review your profile, accepting or rejecting the information you submitted.<br/>
                    e) Once we accept/verify all your information, our system automatically generates a loan offer based on your specific datasets.  If we do not feel you qualify for a loan, you will be notified as well with an action plan of how to improve your financial situation.
                </p>
                <h3 className=" text-xl font-semibold py-4">4. Where is the application form? How do I Apply for a loan</h3>
                <p className=" pl-2">
                    There is no application form as you are more than an application. We have a reverse loan process where you first provide us your information by completing your profile  and once your profile is complete, you will receive a loan offer or advised if you did not qualify for a loan. Whether you get a loan offer or not, once your profile is complete, you become a Seed Customer and will enjoy additional benefits to come.
                </p>
                <h3 className=" text-xl font-semibold py-4">5.  What are your Loan Terms?</h3>
                <p className=" pl-2">
                    If you do qualify for a loan, The offer you receive will have the following features<br/>
                    a) Maximum amount you can borrow  (you can choose how much)<br/>
                    b) Interest Rate<br/>
                    c) Tenure<br/>
                    d) Monthly Payment<br/>
                    e) Associated Fees <br/>
                    <span className=" font-medium text-lg py-5 ">Loan Amount</span>
                    <p>Our maximum Loan amount is $500,000</p> 
                    <span className=" font-medium text-lg py-5 ">Tenure</span>
                    <p>Our Loans are for short term purposes  with a maximum tenure of Eight (8) months.</p> 
                    <span className=" font-medium text-lg py-5 ">Rates</span>
                    <p>Our loans are calculated using an Add-On Interest rate system.  We do risk based pricing an each individual will receive their own interest rate. Rates start at 4% Monthly</p> 
                    <span className=" font-medium text-lg py-5 ">Fees</span>
                    <p>We charge a processing fee which is deducted from the proceeds of the loan, no money is required from you up front.</p> 
                    <span className=" font-medium text-lg py-5 ">Repayments</span>
                    <p>Payments by default are all over the counter payments through our partner Paymaster. You can visit any Paymaster island wide and make payments on your loan. Your loan agreement will have your full loan details. You may choose to do Salary Deduction or Standing Order payments for ease of repayment and be rewarded for those choices.</p> 
                    <span className=" font-medium text-lg py-5 ">Collateral</span>
                    <p>All our loans are unsecured so no collateral is needed to be pledged.</p> 
                </p>
                <h3 className=" text-xl font-semibold py-4">6. Why don’t you give out more money as loans?</h3>
                <p>
                As we continue to grow our client base and our capital base we will be able to offer more solutions to the market such as loans for asset purchases (Car, Land) and for business purposes.
                </p>
                <h3 className=" text-xl font-semibold py-4">7. What documents will I be asked to submit as supporting documents</h3>
                <p className=" pl-2">
                a)  Identification Documents<br/> - Picture<br/>- ID Picture ( Drivers Licence, Passport, Voters ID)<br/>- Proof of Address<br/>
                b). Educational Document-  Degree Awarded, School Leaving Certificate, Certification Etc<br/>
                c) Proof of Income<br/>- Payslips<br/>-  Bank Statement Showing income for Self Employed Persons<br/>
                d) Occupational Information<br/>&ensp;- Job Letter indicating where you work confirming income/duration 
	            <br/> &emsp; •	Business Registration Documents for the Self Employed<br/><br/>

                There may be other documents requested by the system, but the above are key.
                </p>
                <h3 className=" text-xl font-semibold py-4">8. How long does the loan application process takes?</h3>
                <p className=" pl-2">
                The process is solely up to you. As long as you have all the required documents, the full process can be completed in as little as 20 minutes. However, we know you may not have all the required documents right away, so all you have to do is log in and upload the documents whenever you want to complete your profile. <br/> Once your profile is complete and all documents verified, you receive a loan offer decision within minutes. 
                </p>
                <h3 className=" text-xl font-semibold py-4">9. How long until I get the money into my hand once I accept a loan offer?</h3>
                <p className=" pl-2">
                After accepting a loan offer, a loan contract is prepared for signing and once signed by the client, the money is disbursed within 24 hours.
                </p>
                <h3 className=" text-xl font-semibold py-4">10. Do I need a bank account to access the loans?</h3>
                <p className=" pl-2">
                We can do cash disbursal through our partner, Lasco Financial, where you will visit your closest Lasco money location and present your ID and other particulars to access cash. 
                </p>
                <h3 className=" text-xl font-semibold py-4">11. Where are you located?</h3>
                <p className=" pl-2">
                We are online, so you can access us anywhere by visiting us at www.seedja.com.<br/>You can also find us on IG and TikTok and can reach out with any questions.  
                </p>
            </div>
        </article>
        <Footer/>
    </div>
}

export default Faq