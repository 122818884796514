import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { _getPartners } from "../../services/apiService";
import moment from "moment";

const columns = [
  {
    name: "Name",
    selector: "name",
    sortable: true,
    wrap: true,
  },
  {
    name: "Email",
    selector: "contactEmail",
    sortable: true,
    wrap: true,
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    wrap: true,
  },
  {
    name: "Created Date",
    selector: "created",
    sortable: true,
    wrap: true,
  },
];

export default function CardPartnersList(props) {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      let result = await _getPartners();
      // result.forEach((loan) => {}
      result.forEach((loan) => {
        loan.created = moment(loan.created).format("h:mm a, MMMM Do YYYY");
      });
      setUsers(result);
      // ...
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  function userClicked(e) {
    const history = props.props.history;
    const url = "/admin/partners/" + e.userId;
    history.push(url);
  }

  return users ? (
    <DataTable
      className="cursor-pointer"
      title="Partners"
      columns={columns}
      pagination={true}
      paginationPerPage={8}
      data={users}
      onRowClicked={(e) => userClicked(e)}
      // customStyles={customStyles}
    />
  ) : (
    <>
      <div className="my-4">
        <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          {[...Array(4)].map((e, i) => (
            <div key={i} className="animate-pulse my-10 flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                <div className="space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
