import * as yup from "yup";
import moment from "moment";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { _getUserFullInfo, _getUserQuiz } from "../../services/apiService";

import CardUser from "../../components/Cards/CardUser.js";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import UserNotesModal from "../../components/Modal/UserNotesModal";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";

const fieldsInfo = [
  {
    name: "userid",
    label: "User ID",
    placeholder: "User's ID here",
    hiddenOnForm: true,
    type: "text",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["User ID is required"],
      },
    ],
  },
  {
    name: "email",
    label: "Email",
    placeholder: "User's email here",
    type: "text",
    validationType: "email",
    value: "",
    validations: [
      {
        type: "required",
        params: ["Email required"],
      },
    ],
  },
  {
    name: "status",
    label: "Status",
    placeholder: "User's status here",
    type: "text",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["Status is required"],
      },
    ],
    options: [
      "Created",
      "Pending",
      "Suspended",
      "Completed",
      "Active",
      "Cancelled",
      "Reactivated",
    ],
  },
  {
    name: "fullName",
    label: "Full Name",
    placeholder: "User's Full Name here",
    type: "text",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["Full Name is required"],
      },
    ],
  },
  {
    name: "country",
    label: "Country",
    placeholder: "User's country here",
    type: "longText",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["country is required"],
      },
    ],
  },
  {
    name: "image",
    label: "Image",
    placeholder: "User's Image here",
    type: "text",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["category is required"],
      },
    ],
  },
  {
    name: "created",
    label: "Created date",
    placeholder: "User's Created Date",
    type: "date",
    validationType: "date",
    value: "",
    validations: [
      {
        type: "required",
        params: ["participants is required"],
      },
    ],
  },
  {
    name: "lastLoginDate",
    label: "User's Last login date",
    placeholder: "User's Last login date here",
    type: "date",
    validationType: "date",
    value: "",
    validations: [
      {
        type: "required",
        params: ["Winners is required"],
      },
    ],
  },
  {
    name: "promoCode",
    label: "Promo Code",
    placeholder: "Promo Code",
    type: "text",
    validationType: "string",
    value: "",
    validations: [
      {
        type: "required",
        params: ["Winners is required"],
      },
    ],
  },
];

export default function Users(props) {
  let { id } = useParams();
  const [user, setUSer] = useState(null);
  const [quiz, setQuiz] = useState(null);
  const [userFilter, setUserFilter] = useState("");
  const [userVariables, setUserVariables] = useState(null);
  const [validateSchema, setValidateSchema] = useState(null);
  const [itemsThatAppeal, setItemsThatAppeal] = useState(null);
  const [offersPreferences, setOffersPreferences] = useState(null);
  const [isUserNotesModalOpen, setIsUserNotesModalOpen] = useState(false);

  useEffect(() => {
    function processQuiz(data) {
      if (data) {
        const itemsThatAppeal = [];
        const offersPreferences = [];
        Object.keys(data.itemsThatAppeal).forEach((key) => {
          if (data.itemsThatAppeal[key]) {
            itemsThatAppeal.push(key.trim());
          }
        });
        Object.keys(data.offersPreferences).forEach((key) => {
          if (data.offersPreferences[key]) {
            offersPreferences.push(key.trim());
          }
        });
        // setOffersPreferences(offersPreferences);
        setItemsThatAppeal(itemsThatAppeal);
      }
    }

    async function fetchData() {
      function prepareUserForm(result) {
        const fieldsInfoCopy = [...fieldsInfo];
        fieldsInfoCopy.forEach((fieldObj) => {
          if (result[fieldObj["name"]]) {
            let value = result[fieldObj["name"]];
            if (fieldObj["validationType"] === "number") {
              value = parseInt(value);
            } else {
              if (value.constructor === Array) {
                value = value.map((el) => el.name).join(",");
              } else if (typeof value === "object" && value !== null) {
                value = value.name;
              }
            }
            if (fieldObj["validationType"] === "date") {
              fieldObj["value"] = moment(value).format("YYYY-MM-DD");
            } else {
              fieldObj["value"] = value;
            }
          }
        });
        const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
        const validateSchema = yup.object().shape(yepSchema);
        setValidateSchema(validateSchema);
        setUSer(fieldsInfoCopy);
      }

      let result = await _getUserFullInfo(id);
      if (result["user"]["variables"]) {
        setUserVariables(JSON.parse(result["user"]["variables"]));
      }
      prepareUserForm(result["user"]);
      let quizResult = await _getUserQuiz(id);
      quizResult = await quizResult.json();
      setQuiz(quizResult);
      processQuiz(quizResult);
    }

    if (id) fetchData();
  }, [id]);

  return (
    <>
      <UserNotesModal
        open={isUserNotesModalOpen}
        onClose={() => setIsUserNotesModalOpen(false)}
      />
      <div className="mx-4">
        <div className="flex items-center bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          <div className="w-1/2">Filter by user</div>
          <div className="w-1/2">
            <input
              type="text"
              placeholder="Enter user name or email"
              onChange={(e) => setUserFilter(e.target.value)}
              className="w-full rounded px-4 placeholder-black"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mt-4">
        {id ? (
          <>
            {user ? (
              <div className="w-full bg-white rounded-xl">
                <div className="flex justify-between px-8 py-4">
                  <button
                    onClick={(e) => props.history.goBack()}
                    className="ml-2 mt-3 text-sm flex flex-row"
                  >
                    <svg
                      version="1.1"
                      id="Capa_1"
                      className="w-5 h-5 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 26.676 26.676"
                    >
                      <g>
                        <path
                          d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                        />
                      </g>
                    </svg>
                    <div className="underline px-1">Go back</div>
                  </button>
                  <button
                    onClick={() => {
                      setIsUserNotesModalOpen(true);
                    }}
                    className="focus:outline-none text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-500 dark:focus:ring-red-700"
                  >
                    Notes
                  </button>
                </div>
                <DynamicInfoRender fields={user} validation={validateSchema} />
                {userVariables ? (
                  <div className="text-gray-500 p-4 ">
                    User Calculation variables Info
                    <br />
                    {Object.keys(userVariables).map((key, i) => (
                      <div key={i} className="items-center">
                        {key} : {userVariables[key]}
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
                {quiz ? (
                  <div className="bg-white p-4">
                    {itemsThatAppeal ? (
                      <div className="text-gray-500">
                        Items That Appeal To The User
                        <br />
                        {itemsThatAppeal.map((el, i) => (
                          <div key={i} className="inline-flex items-center">
                            {i === 0 ? (
                              <div className="text-xs inline-flex items-center font-bold leading-sm px-2 py-1 bg-green-200 text-green-700 rounded-full">
                                {el}
                              </div>
                            ) : (
                              <div className="ml-2 text-xs inline-flex items-center font-bold leading-sm px-2 py-1 bg-green-200 text-green-700 rounded-full">
                                {el}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                    {offersPreferences ? (
                      <div className="mt-1 text-gray-500">
                        User's Offer Preferences <br />
                        {offersPreferences.map((el, i) => (
                          <div key={i} className="inline-flex items-center">
                            {i === 0 ? (
                              <div className="text-xs inline-flex items-center font-bold leading-sm px-2 py-1 bg-green-200 text-green-700 rounded-full">
                                {el}
                              </div>
                            ) : (
                              <div className="ml-2 text-xs inline-flex items-center font-bold leading-sm px-2 py-1 bg-green-200 text-green-700 rounded-full">
                                {el}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <>
                <div className="my-4">
                  <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                    {[...Array(4)].map((e, i) => (
                      <div
                        key={i}
                        className="animate-pulse my-10 flex space-x-4"
                      >
                        <div className="flex-1 space-y-4 py-1">
                          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="w-full mb-12 px-4">
            <div className="flex justify-end bg-white pt-4 pr-4"></div>
            <CardUser props={props} userFilter={userFilter} color="dark" />
          </div>
        )}
      </div>
    </>
  );
}
