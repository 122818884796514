import React from "react";

export default function NotFoundPartner(props) {
  return (
    <>
      <section className="text-gray-600 body-font">
        <div className="container py-4 mx-auto">
          <div className="flex flex-wrap -my-4">
            <div className="p-6 rounded-lg">
              <img
                className="h-60 sm:h-64 rounded w-full object-contain mb-6"
                src="https://image.flaticon.com/icons/png/512/2748/2748558.png"
                alt="content"
              />
              <div className="text-center">
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                  {props.title}
                </h2>
                <p className="leading-relaxed text-base">{props.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
