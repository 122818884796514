import Avatar from "react-avatar";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import SendMessageModal from "../Modal/SendMessageModal";
import {
  _getMessagedUserList,
  _getMessages,
  _sendMessage,
} from "../../services/apiService";

export default function ChatUI(props) {
  const [selectedUserForChat, setSelectedUserForChat] = useState(null);

  return (
    <>
      <div className="flex bg-white" style={{ height: "80vh" }}>
        {selectedUserForChat ? (
          <ChatMessage
            userId={selectedUserForChat._id}
            name={selectedUserForChat.fullName}
            onBackClick={() => setSelectedUserForChat(null)}
          />
        ) : (
          <ChatListing
            isAdmin={props?.isAdmin}
            userChatSelectionHandler={(user) => setSelectedUserForChat(user)}
          />
        )}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

const CreateMessageIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" {...props}>
    <path
      d="M21 11h1v12.75A1.26 1.26 0 0 1 20.75 25H1.25A1.25 1.25 0 0 1 0 23.75V4.25A1.25 1.25 0 0 1 1.25 3H14v1H1.25a.25.25 0 0 0-.25.25v19.5a.25.25 0 0 0 .25.25h19.5a.25.25 0 0 0 .25-.25ZM8.72 12.87 8 16.4a.5.5 0 0 0 .49.6h.1l3.5-.71a.5.5 0 0 0 .25-.14L22.68 5.82l-3.5-3.5-10.33 10.3a.5.5 0 0 0-.13.25ZM24.54 1.75 23.25.46a1.56 1.56 0 0 0-2.2 0l-1.17 1.15 3.5 3.5L24.54 4a1.56 1.56 0 0 0 0-2.2Z"
      fill="#0e1d25"
    />
  </svg>
);

function ChatListing({ userChatSelectionHandler, isAdmin = true }) {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messagedUsers, setMessagedUsers] = useState([]);
  const [refetchMessages, setRefetchMessages] = useState(false);
  const [isSendMessageModalShown, setIsSendMessageModalShown] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    _getMessagedUserList()
      .then((data) => {
        setIsLoading(false);
        setMessagedUsers(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (refetchMessages) {
      setIsLoading(true);
      _getMessagedUserList()
        .then((data) => {
          setIsLoading(false);
          setMessagedUsers(data);
          setRefetchMessages(false);
        })
        .catch(console.error);
    }
  }, [refetchMessages]);

  return (
    <div className="w-full">
      {isSendMessageModalShown && (
        <SendMessageModal
          closeModal={() => {
            setRefetchMessages(true);
            setIsSendMessageModalShown(false);
          }}
          notifySuccess={() => {
            toast.success("Message send successfully", {
              position: toast.POSITION.TOP_CENTER,
            });
          }}
          notifyError={() => {
            toast.error("Oops! Something went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
          }}
        />
      )}
      {isAdmin && (
        <div className="flex justify-around mx-3 my-3">
          <div className="relative text-gray-600" style={{ width: "90%" }}>
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"
                className="w-6 h-6 text-gray-300"
              >
                <path d="m21 21-6-6m2-5a7 7 0 1 1-14 0 7 7 0 0 1 14 0z" />
              </svg>
            </span>
            <input
              type="search"
              value={searchText}
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
              className="block w-full py-2 pl-10 rounded outline-none placeholder-black"
            />
          </div>
          <CreateMessageIcon
            className="w-6"
            onClick={() => {
              setRefetchMessages(false);
              setIsSendMessageModalShown(true);
            }}
          />
        </div>
      )}
      <h2 className="my-2 mb-2 ml-2 text-lg text-gray-600">Chats</h2>
      <ul className="overflow-auto">
        {isLoading ? (
          <div className="text-center">Loading...</div>
        ) : messagedUsers.length > 0 ? (
          messagedUsers
            .filter(({ fullName }) => {
              if (searchText === "") {
                return true;
              }
              return fullName.toLowerCase().includes(searchText.toLowerCase());
            })
            .map((user, idx) => {
              const name = user.fullName;
              return (
                <UserWidget
                  key={idx}
                  name={name}
                  // lastMessage={lastMessage}
                  onClick={() => userChatSelectionHandler(user)}
                />
              );
            })
        ) : (
          <div className="text-center">
            {isAdmin
              ? "You haven't sent any messages yet"
              : "Messages from Admin will show up here"}
          </div>
        )}
      </ul>
    </div>
  );
}

function UserWidget({ name, onClick }) {
  return (
    <li onClick={onClick}>
      <div className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
        <Avatar name={name} round={true} size={50} />
        <div className="w-full pb-2">
          <div className="flex justify-between">
            <span className="block ml-2 font-semibold text-gray-600">
              {name}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
}

function ChatMessage({ name, userId, onBackClick }) {
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refetchMessages, setRefetchMessages] = useState(false);

  async function sendMessage() {
    if (text.trim() === "") return;
    const payload = {
      text: text,
      receiverId: userId,
    };
    try {
      const response = await _sendMessage(payload);
      if (response.ok) {
        setText("");
        setRefetchMessages(true);
      } else {
        console.error("Error sending message");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    _getMessages(userId)
      .then((data) => {
        setIsLoading(false);
        setMessages(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (refetchMessages) {
      setIsLoading(true);
      _getMessages(userId)
        .then((data) => {
          setIsLoading(false);
          setMessages(data);
          setRefetchMessages(false);
        })
        .catch(console.error);
    }
  }, [refetchMessages, userId]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center p-3 border-b border-gray-300">
        <div className="flex items-center">
          <Avatar name={name} round={true} size={30} />
          <span className="block ml-2 font-bold text-gray-600">{name}</span>
        </div>
        <div className="font-bold text-gray-600" onClick={onBackClick}>
          Back
        </div>
      </div>
      <div className="relative p-2 overflow-y-auto">
        <ul className="flex flex-col space-y-2">
          {isLoading ? (
            <div className="text-center">Loading...</div>
          ) : messages ? (
            messages.map(({ text, sent }, idx) => {
              return sent ? (
                <SentMessage key={idx} message={text} />
              ) : (
                <RecievedMessage key={idx} message={text} />
              );
            })
          ) : (
            <div className="text-center">You haven't send a message yet</div>
          )}
        </ul>
      </div>
      <div className="flex items-center justify-between w-full p-3 border-t border-gray-300 mt-auto">
        <input
          required
          type="text"
          value={text}
          placeholder="Message"
          onChange={(e) => setText(e.target.value)}
          className="block w-full py-2 pl-4 mx-3 rounded-full outline-none focus:text-gray-700 placeholder-black"
        />
        <button onClick={sendMessage}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5 text-gray-500 origin-center transform rotate-90"
          >
            <path d="M10.894 2.553a1 1 0 0 0-1.788 0l-7 14a1 1 0 0 0 1.169 1.409l5-1.429A1 1 0 0 0 9 15.571V11a1 1 0 1 1 2 0v4.571a1 1 0 0 0 .725.962l5 1.428a1 1 0 0 0 1.17-1.408l-7-14z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

function SentMessage({ message }) {
  return (
    <li className="flex" style={{ maxWidth: "60%" }}>
      <div className="relative max-w-xl px-4 py-2 text-gray-700 rounded shadow">
        <span className="block">{message}</span>
      </div>
    </li>
  );
}

function RecievedMessage({ message }) {
  return (
    <li className="flex self-end" style={{ maxWidth: "60%" }}>
      <div className="relative max-w-xl px-4 py-2 text-gray-700 bg-gray-100 rounded shadow">
        <span className="block">{message}</span>
      </div>
    </li>
  );
}
