import * as yup from "yup";
import moment from "moment";

function fileSize(FILE_SIZE, value) {
  // returns true if fileSize condition passes
  return (
    !value  || !value.size || (value && (value.size / (1024 * 1024)).toFixed(2) <= FILE_SIZE)
  );
}

function checkCustomCondition(validation, value) {
  if (typeof validation["value"] == "boolean") {
    value = value === "true";
  }
  let condition = true;
  if (validation.compareType === "not equal") {
    condition = !value && value !== validation["value"];
  } else if (validation.compareType === "equal") {
    condition = value && value === validation["value"];
  } else if (validation.compareType === "greaterThanEqual") {
    condition = value && value >= validation["value"];
  }
  return condition;
}
export function createYupSchema(schema, config) {
  try {
  const { name, validationType, validations = [] } = config;
  // if yup does nit support validationType like string or number
  if (!yup[validationType]) {
    return schema;
  }
  let conditionalValidation = config.conditionalValidations ? true : false;
  let validator = yup[validationType]();
  if (conditionalValidation) {
    if (validationType !== "mixed") {
      config.conditionalValidations.forEach((validation) => {
        const { params, type } = validation;
        if (!validator[type]) {
          return;
        }

        validator = validator["when"](validation["when"], {
          is: (value) => value && checkCustomCondition(validation, value),
          then: validator[type](...params),
          otherwise: validator,
        });
      });
      schema[name] = validator;
    } else {
      config.conditionalValidations.forEach((validation) => {
        const { params, type } = validation;
        if (!validator[type]) {
          return;
        }
        if (type !== "test") {
          validator = validator["when"](validation["when"], {
            is: (value) => value && checkCustomCondition(validation, value),
            then: validator[type](...params),
            otherwise: validator,
          });
        } else {
          const FILE_SIZE = validation["maxFileSize"];
          const SUPPORTED_FORMATS = validation["fileTypes"];

          validator = validator["when"](validation["when"], {
            is: (value) => value && checkCustomCondition(validation, value),
            then: validator
              .test("FILE_SIZE", "Uploaded file is too big.", (value) =>
                fileSize(FILE_SIZE, value)
              )
              .test(
                "FILE_FORMAT",
                "Uploaded file has unsupported format.",
                (value) =>
                  !value ||
                  !value.type ||
                  (value && value.type &&
                    SUPPORTED_FORMATS.join(",").indexOf(
                      value.type.split("/")[1]
                    ) !== -1)
              ),
            otherwise: validator,
          });
        }
      });
      schema[name] = validator;
    }
  } else {
    if (validationType !== "mixed") {
      validations.forEach((validation) => {
        const { params, type } = validation;
        if (!validator[type]) {
          return;
        }
        validator = validator[type](...params);
      });
      schema[name] = validator;
    } else {
      validations.forEach((validation) => {
        console.log("validation:",validation)
        const { params, type } = validation;
        if (!validator[type]) {
          console.log("noo:",type)
          return;
        }
        if (type !== "test") {
          validator = validator[type](...params);
        } else {
          if(type === "test" && !validation["subtype"]) {
            const FILE_SIZE = validation["maxFileSize"];
            const SUPPORTED_FORMATS = validation["fileTypes"];
            //validator = validator[type](...params);
            validator = validator
              .test(
                "FILE_SIZE",
                "Uploaded file is too big.",
                // value => (!value || (value && (value.size / (1024*1024)).toFixed(2) <= FILE_SIZE)))
                (value) => fileSize(FILE_SIZE, value)
              )
              .test(
                "FILE_FORMAT",
                "Uploaded file has unsupported format.",
                (value) =>
                  !value ||
                  !value.type ||
                  (value && value.type && 
                    SUPPORTED_FORMATS.join(",").indexOf(
                      value.type.split("/")[1]
                    ) !== -1)
              );
          } else if(type === "test" && validation["subtype"] === 'datetest') {
            console.log("datetest")
            validator = validator
            .test('Date of Birth', 'Should be greather than 18', function(value) {
              console.log("datetest:",value,moment().diff(moment(value), 'years') >= 18)
              return moment().diff(moment(value), 'years') >= 18;
            })
          } else if(type === "test" && validation["subtype"] === 'id') {
            console.log("id")
            validator = validator
            .test('id', 'Please provide an ID that does NOT expire for the next 30 days', function(value) {
              console.log("datetest:",value,moment().diff(moment(value), 'days'))
              return moment().diff(moment(value), 'days') <= -30;
            })
          } else if (type === "test" && validation["subtype"] === 'mobile_jamaica') {
            console.log("mobile_jamaica");
            validator = validator.test('mobile_jamaica', 'Please provide a valid Jamaican mobile number (876 or 658) with a total of 7 digits after', function(value) {
              // Check if the value is empty
              console.log("mob:",value)
              if (!value) {
                return true; // Allow empty input
              }
              return /^(876|658)\d{7}$/.test(value);
            });
          }
          
         
          // Yup.mixed() .test('fileSize', "File Size is too large", value => value.size <= FILE_SIZE) .test('fileType', "Unsupported File Format", value => SUPPORTED_FORMATS.includes(value.type) )
        }
      });
      schema[name] = validator;
    }
  }
  return schema;
} catch(err) {
  console.log(err)
}
}
