import * as Yup from "yup";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { _signUp } from "../../services/apiService";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";

const AdminSignup = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  const notify = (isSuccess, message) => {
    if (isSuccess) {
      setIsDisabled(false);
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setIsDisabled(false);
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <div className=" flex justify-center items-center w-full">
      <div className="bg-white border border-gray-300 solid shadow-lg sm:max-w-3xl sm:w-full sm:mx-auto mx-8 sm:rounded-lg sm:overflow-hidden">
        <div className="px-4 py-8 sm:px-10">
          <div className="text-2xl font-bold">Add New Admin User</div>
          <div className="mt-6">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                repeatPassword: "",
                userType: "",
              }}
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .max(15, "Must be 15 characters or less")
                  .required("First Name is Required"),
                lastName: Yup.string()
                  .max(20, "Must be 20 characters or less")
                  .required("Last Name is Required"),
                email: Yup.string()
                  .email("Invalid email address")
                  .required("Email is Required"),
                password: Yup.string()
                  .min(8, "minimum of 8 characters")
                  .required("Password is Required"),
                repeatPassword: Yup.string()
                  .min(8, "minimum of 8 characters")
                  .oneOf([Yup.ref("password"), null], "Password must match")
                  .required("Password is Required"),
                userType: Yup.string()
                  .required("User Type is Required")
                  .oneOf(["Admin", "Moderator"], "User Type is Required"),
              })}
              onSubmit={(values, { resetForm }) => {
                let _data = {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  password: values.password,
                  userType: values.userType,
                };
                setTimeout(async () => {
                  const response = await _signUp(_data);
                  console.log(response)
                  setIsDisabled(true);
                  if(response.ok) {
                    notify(response.ok, "Account created successfully");
                  } else {
                    notify(response.ok, "Failed! Only admin can create new admins");
                  }
                  
                  if (response.ok) {
                    resetForm();
                  }
                }, 400);
              }}
            >
              <Form className="lg:grid grid-cols-2 gap-1">
                <div>
                  <TextInput
                    name="firstName"
                    type="text"
                    placeholder="First name"
                  />
                </div>
                <div>
                  <TextInput
                    name="lastName"
                    type="text"
                    placeholder="Last name"
                  />
                </div>
                <div className="col-start-3 col-end-4"></div>
                <div className="col-span-3">
                  <TextInput
                    name="email"
                    type="email"
                    placeholder="Email address"
                  />
                </div>
                <div>
                  <TextInput
                    name="password"
                    type="password"
                    placeholder="Password"
                  />
                </div>
                <div>
                  <TextInput
                    name="repeatPassword"
                    type="password"
                    placeholder="Repeat password"
                  />
                </div>
                <div className="col-start-1 col-end-3 mt-3">
                  <SelectInput
                    name="userType"
                    label="Select user role"
                    options={[
                      { id: 1, name: "Admin" },
                      { id: 2, name: "Moderator" },
                    ]}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                  />
                </div>
                <div className="col-start-1 col-end-3 mt-3">
                  <button
                    type="submit"
                    className="block w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme-main hover:bg-theme-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={isDisabled}
                  >
                    Add User
                  </button>
                </div>
              </Form>
            </Formik>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSignup;
