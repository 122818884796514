import React from "react";
import NavBarLanding from "../../components/NavBarLanding/index";
import Footer from "../../components/Footer";

function TermsandConditions() {
  return (
    <div>
      <div
        className="border-b-1 shadow-lg border-gray-600"
        style={{ backgroundColor: "white" }}
      >
        <NavBarLanding pageType="signup" />
      </div>
      <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="relative z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                Terms And Conditions
              </h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Policies
              </p>
            </div>
          </div>
          <div className="relative">
            <svg
              className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
              />
            </svg>
            <svg
              className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
              />
            </svg>
            <div className="relative md:bg-white md:p-6">
              <div className="lg:grid lg:gap-6">
                <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                  <h1 className="mt-2 text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                    User
                  </h1>
                  <p>
                    Once someone signs up to the website by giving an email
                    address and creating a password, the person is classified as
                    a user.
                  </p>
                  <h1 className="mt-5 text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                    Usage Policy
                  </h1>
                  <p>
                    Users consent to SeedJa analyzing their data and
                    amalgamating it where necessary for data analytics purposes.
                  </p>
                  <h1 className="mt-5 text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                    Partner
                  </h1>
                  <p>Partner is a user who signs up as a company.</p>
                  <h1 className="mt-5 text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                    Data
                  </h1>
                  <p>
                    Data is any information submitted by a user to our SeedJa
                    system. Information encompasses Words, Images, and documents
                    in all forms. Only information submitted on the system is
                    stored as data.
                  </p>
                  <h1 className="mt-5 text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                    Data Security and Breaches
                  </h1>
                  <p>
                    SeedJa will do all it can to ensure Data is secured. In the
                    event of a data breach, users will be immediately notified
                    of any such breach.
                  </p>
                  <h1 className="mt-5 text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                    Data Policy
                  </h1>
                  <p>
                    Data Submitted is stored securely in the cloud with our
                    database for up to 24 months. Users will be able to delete
                    their information from the system permanently before that if
                    requested.
                  </p>
                  <p>
                    All Data collected by SeedJa as per website www.seedja.com
                    will be voluntary information. No biometric data will be
                    collected or stored by SeedJa. Information gathered in the
                    form of words, images, pictures will be stored on a secure
                    server. Data can only be always accessed by SeedJa
                    Investments. SeedJa Investments will be in full compliance
                    with Data Protection Act 2020 Jamaica.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative md:bg-white md:p-6">
              <div className="lg:grid lg:gap-6">
                <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                  <div className="text-base max-w-prose lg:max-w-none">
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      Sharing of Data
                    </p>
                  </div>
                  <p className="mt-8">
                    Individual user data may be amalgamated to create trends and
                    statistics of like-minded persons. This data which will not
                    be specific to an individual can be freely shared with
                    partners
                  </p>
                  <p className="">
                    Individual user data may be shared with partners Only with
                    the consent of the user. Users may be compensated for their
                    data.
                  </p>
                  <p>
                    SeedJa Investments will have specified partners. Data will
                    be shared with partners to determine your eligibility for
                    rewards. Each partner
                  </p>
                </div>
              </div>
            </div>
            <div className="relative md:bg-white md:p-6">
              <div className="lg:grid lg:gap-6">
                <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                  <div className="text-base max-w-prose lg:max-w-none">
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      Cookies
                    </p>
                  </div>
                  <p className="mt-8">
                    This website uses cookies to track users.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#253646" }}>
        <Footer />
      </div>
    </div>
  );
}

export default TermsandConditions;
