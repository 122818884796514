import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import SelectInput from "../../components/SelectInput";
import { _exportData } from "../../services/apiService";

export default function ExportData() {
  const [isDisabled, setIsDisabled] = React.useState(false);

  function handleExportData(values) {
    _exportData(values.model);
  }

  return (
    <div className="w-full h-full bg-white rounded p-6">
      <Formik
        initialValues={{
          model: "",
        }}
        validationSchema={Yup.object({
          model: Yup.string().required("You must select option to export data"),
        })}
        onSubmit={(values, { resetForm }) => {
          setIsDisabled(true);
          handleExportData(values);
          resetForm();
          setIsDisabled(false);
        }}
      >
        <Form className="md:max-w-md">
          <SelectInput
            name="model"
            options={["Account", "Loan"]}
            getOptionLabel={(option) => option}
            label="Select model to export data from"
            getOptionValue={(option) => option.toLowerCase()}
          />
          <button
            type="submit"
            disabled={isDisabled}
            className="text-white bg-green-500 rounded-md px-4 py-1 outline-none focus:outline-none mr-7 mb-5 mt-4 ease-linear transition-all duration-150"
          >
            Export Data
          </button>
        </Form>
      </Formik>
    </div>
  );
}
