import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
// import styled from 'styled-components';
import { _getPrizes } from "../../services/apiService";

const columns = [
  {
    name: "Title",
    selector: "name",
    sortable: true,
    wrap: true,
  },
  {
    name: "Type",
    selector: "type",
    sortable: true,
    wrap: true,
  },
  {
    name: "seeds Required",
    selector: "pointRequired",
    sortable: true,
    wrap: true,
  },
  {
    name: "Description",
    selector: "description",
    sortable: true,
    wrap: true,
  },
  {
    name: "Value",
    selector: "value",
    sortable: true,
    wrap: true,
  },
];

export default function CardPrizes(props) {
  const [prize, setPrize] = useState(null);

  function PrizeClicked(e) {
    const history = props.props.history;
    const url = "/admin/prizes/" + e._id;
    history.push(url);
  }

  useEffect(() => {
    async function fetchData() {
      // You can await here
      let result = await _getPrizes();
      setPrize(result);
      // setFilteredItems(result)
      // ...
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state
  return prize ? (
    <DataTable
      className="cursor-pointer"
      title="Prizes"
      columns={columns}
      pagination={true}
      paginationPerPage={8}
      data={prize}
      onRowClicked={(e) => PrizeClicked(e)}
    />
  ) : (
    <>
      <div className="my-4">
        <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          {[...Array(4)].map((e, i) => (
            <div key={i} className="animate-pulse my-10 flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                <div className="space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
