import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import TextAreaInput from "../TextAreaInput";
import SelectField from "../SelectInput";
import { _getUsers, _sendMessage } from "../../services/apiService";

export default function SendMessageModal({
  closeModal,
  notifyError,
  notifySuccess,
}) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [users, setUsers] = useState([{ id: "default", name: "Loading..." }]);
  const [sendEmail, setSendEmail] = useState(false);

  async function handleSendMessage(values) {
    const payload = {
      text: values.message,
      receiverId: values.receiverId,
      sendEmail: sendEmail,
    };

    try {
      const response = await _sendMessage(payload);
      if (response.ok) {
        notifySuccess();
        closeModal();
      } else {
        notifyError();
      }
    } catch (error) {
      notifyError();
      console.log(error);
    }
  }

  useEffect(() => {
    _getUsers()
      .then((data) => {
        console.log({ data });
        setUsers(data);
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-1/2">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between pt-5 pl-5  rounded-t">
              <h3 className="text-2xl font-semibold">Message User</h3>
            </div>
            {/*body*/}
            <div className="relative px-5 flex-auto">
              <Formik
                initialValues={{
                  message: "",
                  receiverId: "",
                }}
                validationSchema={Yup.object({
                  receiverId: Yup.string()
                    .required("You must select option to message users")
                    .notOneOf(
                      ["default"],
                      "You must select option to messsage users"
                    ),
                  message: Yup.string().required("You must enter a message"),
                })}
                onSubmit={(values, { resetForm }) => {
                  setIsDisabled(true);
                  handleSendMessage(values);
                  resetForm();
                }}
              >
                <Form className="md:max-w-md">
                  <SelectField
                    options={users}
                    name="receiverId"
                    label="Select User"
                    getOptionValue={(option) => option._id}
                    getOptionLabel={(option) => `${option.email}`}
                  />
                  <div className="my-4">
                    <label htmlFor="message">Message Content</label>
                    <TextAreaInput
                      name="message"
                      className="w-full h-60"
                      placeholder="Example Message Text..."
                    />
                  </div>
                  <div className="flex items-center">
                  <input
              onChange={() => {
                setSendEmail((prev) => !prev);
              }}
              type="checkbox"
              checked={sendEmail}
            />
            <p className="pl-2">Inform user via email</p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end">
                    <button
                      type="button"
                      className="text-white bg-red-500 rounded-md px-4 py-1 outline-none focus:outline-none mr-7 mb-5 mt-4 ease-linear transition-all duration-150"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      disabled={isDisabled}
                      className="text-white bg-green-500 rounded-md px-4 py-1 outline-none focus:outline-none mr-7 mb-5 mt-4 ease-linear transition-all duration-150"
                    >
                      Send Message
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
