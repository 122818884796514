import * as yup from "yup";
import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  _approveDocument,
  _rejectDocument,
  _getSubmittedInformationByUserId,
} from "../../services/apiService";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserNotesModal from "../../components/Modal/UserNotesModal";
import SendMessageModal from "../../components/Modal/SendMessageModal";
let TOTAL_SUBMISSION_COUNT = 9;

const submissionStatusSortingMapping = {
  Verified: 0,
  Rejected: 0,
  Submitted: 1,
  SubmittedForVerfication: 2,
};

export default function UserVerfications(props) {
  const hasModel5 = window.location.href.includes("model-5");
  let { id } = useParams();
  const submissionCount = useRef(0);
  const verificationCount = useRef(0);
  const pendingVerificationCount = useRef(0);
  const [apiData, setApiData] = React.useState(false);
  const [isSendMessageModalShown, setIsSendMessageModalShown] = useState(false);
  const [isUserNotesModalOpen, setIsUserNotesModalOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // Reset defaults
      submissionCount.current = 0;
      verificationCount.current = 0;
      pendingVerificationCount.current = 0;

      let result = await _getSubmittedInformationByUserId(id);
console.log(result);
if(hasModel5){
  result = result.filter((el) => el.submission.informationSchema.for &&
  el.submission.informationSchema.for === "PreQualUser");
  TOTAL_SUBMISSION_COUNT = 6;
} else {
  result = result.filter((el) => !el.submission.informationSchema.for ||
   el.submission.informationSchema.for !== "PreQualUser");
  TOTAL_SUBMISSION_COUNT = 9;
}
      submissionCount.current = result.length;
      result.forEach(({ _, verification }) => {
        if (verification) {
          verificationCount.current++;
          pendingVerificationCount.current +=
            verification.status === "Submitted" ||
            verification.status === "SubmittedForVerfication"
              ? 0
              : 1;
        }
      });
      const sortedResults = result.sort((a, b) => {
        return (
          submissionStatusSortingMapping[b.submission.status] -
          submissionStatusSortingMapping[a.submission.status]
        );
      });
      setApiData(sortedResults);
    }
    if (id) fetchData();
  }, [id]);

  if (!apiData) return "Loading...";

  return (
    <div className="w-full h-full bg-gray-200">
      <UserNotesModal
        open={isUserNotesModalOpen}
        onClose={() => setIsUserNotesModalOpen(false)}
      />
      {isSendMessageModalShown && (
        <SendMessageModal
          closeModal={() => {
            setIsSendMessageModalShown(false);
          }}
          notifySuccess={() => {
            toast.success("Message send successfully", {
              position: toast.POSITION.TOP_CENTER,
            });
          }}
          notifyError={() => {
            toast.error("Oops! Something went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
          }}
        />
      )}
      <div className="w-full flex items-center bg-white p-6">
        <div className="w-full flex flex-col gap-2">
          <h3 className="font-bold">Stats</h3>
          <div className="w-full flex flex-col gap-1">
            <label>
              {`Information Submitted (${submissionCount.current} / ${TOTAL_SUBMISSION_COUNT})`}
            </label>
            <div
              className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700"
              style={{
                width: "60%",
              }}
            >
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{
                  width: `${parseInt(
                    (submissionCount.current * 100) / TOTAL_SUBMISSION_COUNT
                  )}%`,
                }}
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-1">
            <label>
              {`Information Verified / Rejected (${pendingVerificationCount.current} / ${verificationCount.current})`}
            </label>
            <div
              className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700"
              style={{
                width: "60%",
              }}
            >
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{
                  width: `${parseInt(
                    (pendingVerificationCount.current * 100) /
                      verificationCount.current
                  )}%`,
                }}
              />
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            setIsSendMessageModalShown(true);
          }}
          className="ml-auto h-10 focus:outline-none text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-500 dark:focus:ring-red-700"
        >
          Message
        </button>
        <button
          onClick={() => {
            setIsUserNotesModalOpen(true);
          }}
          className="ml-auto h-10 focus:outline-none text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-500 dark:focus:ring-red-700"
        >
          Notes
        </button>
      </div>
      {apiData.map((data, idx) => {
        return (
          <VerificationCard key={idx} data={data} showGoBack={idx === 0} />
        );
      })}
    </div>
  );
}

function VerificationCard({ data, showGoBack }) {
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [submission, setSubmission] = useState(null);
  const [rejectReason, setRejectReason] = useState("");
  const [verification, setVerification] = useState(null);
  const [reject, setRejectHandle] = React.useState(false);
  const [rejectionNote, setRejectionNote] = React.useState("");
  const [approvalNote, setApprovalNote] = React.useState("");
  const [validateSchema, setValidateSchema] = useState(null);
  const [verified, setVerifiedHandle] = React.useState(false);
  const [currentView, setCurrentView] = useState("submission");
  const [submissionValidateSchema, setSubmissionValidateSchema] =
    useState(null);

  const handleClickOpenReject = () => {
    setRejectHandle(true);
  };

  const handleClickCloseReject = () => {
    setRejectHandle(false);
  };

  const rejectDocument = async () => {
    const body = {
      sid: data.submission._id,
      userId: data.submission.user,
      rejectionNote: rejectionNote,
      rejectionReason: rejectReason,
    };
    const response = await _rejectDocument(data.verification._id, body);
    // check response
    if (response.ok) {
      setRejectReason("");
      toast.success("Document Rejected", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // const url = "/admin/verification";
      // setTimeout(() => history.push(url), 3000);
      data.verification.status = "Rejected";
    } else {
      toast.error("Oops! an error occured", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    setRejectHandle(false);
  };

  const handleClickOpenVerify = () => {
    setVerifiedHandle(true);
  };

  const handleClickCloseVerify = () => {
    setVerifiedHandle(false);
  };

  const verifyDocument = async () => {
    const body = {
      sid: data.submission._id,
      approvalNote: approvalNote,
      userId: data.submission.user,
    };
    const response = await _approveDocument(data.verification._id, body);
    if (response.ok) {
      toast.success("Successfully verified", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      data.verification.status = "Verified";
      // const url = "/admin/verification";
      // setTimeout(() => history.push(url), 3000);
    } else {
      toast.error("Oops! an error occured", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    setVerifiedHandle(false);
  };

  function prepareVerificationForm(result, type) {
    const schema =
      type === "submission" ? "informationSchema" : "verificationSchema";
    const fields = result[schema].fields;
    const data = result.data;
    fields.forEach((fieldObj) => {
      if (data && data[fieldObj["name"]]) {
        let value = data[fieldObj["name"]];
        if (fieldObj["validationType"] === "number") {
          value = parseInt(value);
        } else if (fieldObj["validationType"] === "date") {
          fieldObj["type"] = "text";
        } else {
          if (value.constructor === Array) {
            value = value.map((el) => el.name).join(",");
          } else if (typeof value === "object" && value !== null) {
            value = value.name;
          }
        }
        fieldObj["value"] = value;
      }
    });
    const yepSchema = fields.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);
    setCurrentView("submission");
    if (type === "submission") {
      setSubmissionValidateSchema(validateSchema);
      setSubmission(fields);
    } else {
      setValidateSchema(validateSchema);
      setVerification(fields);
    }
  }

  useEffect(() => {
    prepareVerificationForm(data["verification"], "verification");
    prepareVerificationForm(data["submission"], "submission");
    setReady(true);
  }, [data]);

  if (!ready) return "Loading...";

  return (
    <>
      {data && (
        <div className="w-full my-6">
          {/* Verification is yet to be taken action on */}
          {data.verification.status === "Submitted" ? (
            <div className="flex justify-between bg-white pt-4 pr-4">
              {showGoBack && (
                <button
                  onClick={(e) => history.goBack()}
                  className="ml-2 mt-3 text-sm flex flex-row"
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    className="w-5 h-5 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 26.676 26.676"
                  >
                    <g>
                      <path
                        d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                      />
                    </g>
                  </svg>
                  <div className="underline px-1">Go back</div>
                </button>
              )}
              <div className="ml-auto">
                <button
                  className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600 mr-4"
                  onClick={handleClickOpenVerify}
                >
                  Verify
                </button>
                <Dialog
                  fullWidth={true}
                  maxWidth="sm"
                  open={verified}
                  onClose={handleClickCloseVerify}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogContent>
                    <DialogContentText>
                      Approve the document? Leave a note
                    </DialogContentText>
                    <textarea
                      placeholder="Leave a note"
                      value={approvalNote}
                      onChange={(e) => {
                        setApprovalNote(e.target.value);
                      }}
                      rows="3"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md placeholder-black"
                    ></textarea>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClickCloseVerify} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={verifyDocument} color="primary">
                      Approve
                    </Button>
                  </DialogActions>
                </Dialog>
                <button
                  className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                  onClick={handleClickOpenReject}
                >
                  Reject
                </button>
                <Dialog
                  fullWidth={true}
                  maxWidth="md"
                  open={reject}
                  onClose={handleClickCloseReject}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogContent>
                    <DialogContentText>
                      Please specify a reason for the rejection.
                    </DialogContentText>
                    <textarea
                      placeholder="Please specify a reason for the rejection."
                      required
                      value={rejectReason}
                      onChange={(e) => {
                        setRejectReason(e.target.value);
                      }}
                      rows="3"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md placeholder-black"
                    ></textarea>
                    <DialogContentText>Note</DialogContentText>
                    <textarea
                      placeholder="Leave a note"
                      value={rejectionNote}
                      onChange={(e) => {
                        setRejectionNote(e.target.value);
                      }}
                      rows="3"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md placeholder-black"
                    ></textarea>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClickCloseReject} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={rejectDocument} color="primary">
                      Reject
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          ) : (
            <div className="flex flex-row bg-white justify-between">
              {showGoBack && (
                <button
                  onClick={(e) => history.goBack()}
                  className="w-full ml-2 mt-3 text-sm flex flex-row"
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    className="w-5 h-5 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 26.676 26.676"
                  >
                    <g>
                      <path
                        d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                      />
                    </g>
                  </svg>
                  <div className="w-full text-left underline px-1">Go back</div>
                </button>
              )}
              <p
                className={`w-full text-right text-sm bg-white pt-4 pr-4 ${
                  data.verification.status &&
                  data.verification.status === "Rejected"
                    ? "text-red-600"
                    : "text-primary"
                }`}
              >
                this document is {data.verification.status}
              </p>
            </div>
          )}
          <div className="flex justify-start bg-white pt-4 pr-4">
            <div className="text-bold text-xl pl-4">
              Verification Information for{" "}
              {data.submission.informationSchema.name}
            </div>
          </div>
          <div className="flex bg-white">
            <button
              className={`flex-grow focus:outline-none border-b-2 py-2 text-lg px-1 ${
                currentView === "submission"
                  ? "text-indigo-500 border-indigo-500"
                  : ""
              }`}
              onClick={() => setCurrentView("submission")}
            >
              Submission
            </button>
            <button
              className={`flex-grow focus:outline-none border-b-2 py-2 text-lg px-1 ${
                currentView === "verification"
                  ? "text-indigo-500 border-indigo-500"
                  : ""
              }`}
              onClick={() => setCurrentView("verification")}
            >
              Verification
            </button>
          </div>
          {submission && verification ? (
            currentView === "verification" ? (
              <DynamicInfoRender
                fields={verification}
                validation={validateSchema}
              />
            ) : (
              <DynamicInfoRender
                fields={submission}
                validation={submissionValidateSchema}
              />
            )
          ) : (
            <div className="my-4">
              <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                {[...Array(4)].map((e, i) => (
                  <div key={i} className="animate-pulse my-10 flex space-x-4">
                    <div className="flex-1 space-y-4 py-1">
                      <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                      <div className="space-y-2">
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
