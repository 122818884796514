import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Cookies from "universal-cookie";
import Notify from "../Notification/Notification";
import useOuterClick from "../../services/clickDetector";
import Logo from "../../assets/img/seedja-logo2.png";
import { _logOut, _getUserInfo } from "../../services/apiService";

function NavBar(props) {
  const [showNotification, setShowNotification] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [userInfo, setUserInfo] = React.useState(null);
  React.useEffect(() => {
    async function fetchData() {
      let result = await _getUserInfo();
      setUserInfo(result);
    }
    fetchData();
  }, []);

  const logOut = async () => {
    const cookies = new Cookies();
    cookies.remove("kidUser");
    let result = await _logOut();
    if (result) {
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  };

  const innerRef = useOuterClick((e) => {
    // counter state is up-to-date, when handler is called

    if (showDropDown) {
      setShowDropDown(!showDropDown);
    }

    if (showNotification) {
      setShowNotification(!showNotification);
    }

    if (showNav) {
      setShowNav(!showNav);
    }
  });

  return (
    <div className="z-50 ">
      <nav className="w-full flex lg:px-8 px-4 py-2 items-center relative justify-between md:justify-start">
        <div className="mr-1 sm:mr-auto md:hidden block">
          <button
            className="p-2 text-gray-300 focus:outline-none focus:text-white"
            onClick={() => {
              setShowNav(!showNav);
            }}
          >
            {!showNav ? (
              <div>
                <svg
                  style={{ color: "#FBFA58" }}
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 fill-current"
                  viewBox="0 0 512 512"
                >
                  <g>
                    <g>
                      <path d="M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M492,76H20C8.954,76,0,84.954,0,96s8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,76,492,76z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M492,396H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20
    C512,404.954,503.046,396,492,396z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            ) : (
              <div>
                <svg
                  style={{ color: "#FBFA58" }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 fill-current"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                </svg>
              </div>
            )}
          </button>
        </div>
        <div className="flex text-xl font-bold tracking-wider space-x-2 md:mx-0 mx-2">
          {/* <svg
            className="mb-0.5"
            width="25"
            height="26"
            viewBox="0 0 716 800"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M699.66 20.2744C721.151 41.7661 721.151 76.6109 699.66 98.1025L493.369 304.394C436.352 361.41 436.352 453.852 493.369 510.869L699.717 717.217C721.24 738.74 721.24 773.637 699.717 795.16V795.16C678.194 816.683 643.298 816.683 621.774 795.16L337.598 510.983C280.581 453.967 280.581 361.525 337.598 304.508L621.832 20.2745C643.323 -1.21717 678.168 -1.21718 699.66 20.2744V20.2744Z"
              fill="#52CA95"
            />
            <rect width="126" height="815" rx="11" fill="#52CA95" />
          </svg> */}
          <img
            className="h-6 pl-0 sm:pl-6 sm:h-10"
            src={Logo}
            alt="SeedJa Logo"
          />
        </div>
        <ul
          className={`bg-white flex md:space-x-8 p-2  md:px-0 md:space-y-0 space-y-1 md:ml-4 md:relative absolute top-full flex-col md:flex-row left-0 right-0 md:hidden ${
            showNav ? "active" : "hidden"
          }`}
        >
          <NavLink activeClassName="font-semibold text-primary" exact to="/">
            <li className="font-semibold md:ml-4 py-2 text-black hover:text-gray-900 block border-b-2 border-transparent hover:border-white-400">
              My Dashboard
            </li>
          </NavLink>
          <NavLink activeClassName="font-semibold text-primary" exact to="/overview">
            <li className="font-semibold md:ml-4 py-2 text-black hover:text-gray-900 block border-b-2 border-transparent hover:border-white-400">
              Overview
            </li>
          </NavLink>
          <NavLink
            activeClassName="font-semibold text-primary"
            to="/earn-points"
          >
            <li className="font-semibold py-2 hover:text-gray-900 block border-b-2 border-transparent hover:border-white">
              Apply for a loan
            </li>
          </NavLink>
          <NavLink activeClassName="font-semibold text-primary" to="/redeem">
            <li className="font-semibold py-2 hover:text-gray-900 block border-b-2 border-transparent hover:border-white-400">
              Redeem
            </li>
          </NavLink>
          <NavLink activeClassName="font-semibold text-primary" to="/data">
            <li className="font-semibold py-2 hover:text-gray-900 block border-b-2 border-transparent hover:border-white-400">
              Your Data
            </li>
          </NavLink>
          <NavLink
            activeClassName="font-semibold text-primary"
            to="/loan-services"
          >
            <li className="font-semibold py-2 hover:text-gray-900 block border-b-2 border-transparent hover:border-white-400">
              Loan Services
            </li>
          </NavLink>
        </ul>
        <ul
          className={`flex md:space-x-8 p-2 md:px-0 md:space-y-0 space-y-1 md:ml-4 md:relative absolute top-full flex-col md:flex-row left-0 right-0 hidden md:flex`}
        >
          <NavLink
            activeClassName="font-semibold text-white border-b-2 "
            exact
            to="/"
          >
            <li
              style={{ color: "#FBFA58" }}
              className="font-semibold md:ml-4 py-2  hover:text-white block border-b-2 border-transparent hover:border-white-400"
            >
              My Dashboard
            </li>
          </NavLink>
          <NavLink
            activeClassName="font-semibold text-white border-b-2 "
            exact
            to="/overview"
          >
            <li
              style={{ color: "#FBFA58" }}
              className="font-semibold md:ml-4 py-2  hover:text-white block border-b-2 border-transparent hover:border-white-400"
            >
              Overview
            </li>
          </NavLink>
          <NavLink
            activeClassName="font-semibold text-white border-b-2 "
            to="/earn-points"
          >
            <li
              style={{ color: "#FBFA58" }}
              className="font-semibold py-2 hover:text-white block border-b-2 border-transparent hover:border-white-400"
            >
              Earn Seed Points
            </li>
          </NavLink>
          <NavLink
            activeClassName="font-semibold text-white border-b-2 "
            to="/redeem"
          >
            <li
              style={{ color: "#FBFA58" }}
              className="font-semibold py-2 hover:text-white block border-b-2 border-transparent hover:border-white-400"
            >
              Redeem
            </li>
          </NavLink>
          <NavLink
            activeClassName="font-semibold text-white border-b-2 "
            to="/data"
          >
            <li
              style={{ color: "#FBFA58" }}
              className="font-semibold py-2 hover:text-white block border-b-2 border-transparent hover:border-white-400"
            >
              Your Data
            </li>
          </NavLink>
          <NavLink
            activeClassName="font-semibold text-white border-b-2 "
            to="/loan-services"
          >
            <li
              style={{ color: "#FBFA58" }}
              className="font-semibold py-2 hover:text-white block border-b-2 border-transparent hover:border-white-400"
            >
              Loan Services
            </li>
          </NavLink>
          <NavLink
            to="/messages"
            activeClassName="font-semibold text-white border-b-2 "
          >
            <li
              style={{ color: "#FBFA58" }}
              className="font-semibold py-2 hover:text-white block border-b-2 border-transparent hover:border-white-400"
            >
              Messages
            </li>
          </NavLink>
        </ul>

        <div
          ref={innerRef}
          className="ml-auto px-5 flex space-x-6 items-center"
        >
          <button
            className="inline-flex p-1 rounded-full focus:outline-none"
            onClick={() => {
              setShowNotification(!showNotification);
            }}
          >
            <svg
              style={{ color: "#FBFA58" }}
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 fill-current"
              viewBox="0 0 24 24"
            >
              <path d="M15 21c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6zm.137-17.055c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.668 2.709-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.193-10.598-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm-6.451 16c1.189-1.667 1.605-3.891 1.964-5.815.447-2.39.869-4.648 2.354-5.509 1.38-.801 2.956-.76 4.267 0 1.485.861 1.907 3.119 2.354 5.509.359 1.924.775 4.148 1.964 5.815h-12.903z" />
            </svg>
            <div
              className={`absolute shadow-xl overflow-hidden top-full right-0  rounded text-gray-700 w-64 flex flex-col ${
                showNotification ? "active" : "hidden"
              }`}
            >
              <Notify props={props} />
            </div>
          </button>
          <div
            ref={innerRef}
            className="flex items-center relative space-y-1 text-gray-900"
          >
            <button
              className="text-2xl font-semibold"
              onClick={() => {
                setShowDropDown(!showDropDown);
              }}
            >
              <img
                style={{ color: "#FBFA58" }}
                className="inline object-cover w-8 h-8 mr-2 rounded-full"
                src={
                  userInfo && userInfo.image
                    ? userInfo.image
                    : "https://img.icons8.com/color/452/barack-obama.png"
                }
                alt="user avatar"
              />

              {/* {!showDropDown ? (
                <svg
                  aria-hidden="true"
                  className=""
                  data-reactid="266"
                  fill="none"
                  style={{color:'black'}}
                  height="24"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              ) : (
                <svg
                  aria-hidden="true"
                  className=""
                  data-reactid="266"
                  fill="none"
                  height="24"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transform: "rotate(180deg)", color:'black' }}
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              )} */}
            </button>
            <ul
              className={`absolute shadow-xl overflow-hidden top-full right-0  bg-white rounded text-gray-700 w-48 flex flex-col ${
                showDropDown ? "active" : "hidden"
              }`}
            >
              <NavLink
                activeClassName="font-semibold text-primary"
                to="/profile"
              >
                <li className="w-full block px-4 py-2 hover:bg-gray-100">
                  Your Profile
                </li>
              </NavLink>
              <NavLink
                activeClassName="font-semibold text-primary"
                to="/update-password"
              >
                <li className="w-full block px-4 py-2 hover:bg-gray-100">
                  Change Password
                </li>
              </NavLink>
              <NavLink to="/logout">
                <li
                  className="w-full block px-4 py-2 hover:bg-gray-100"
                  onClick={() => logOut()}
                >
                  Log out
                </li>
              </NavLink>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
