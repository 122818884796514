import { Link } from "react-router-dom";
import Logo from "../../assets/img/seedja-logo.png";
function NavBarLanding(props) {
  return (
    <div>
      <nav
        className="flex items-center justify-between px-3 md:px-32"
        aria-label="Global"
      >
        <div className="flex items-center flex-1">
          <div className="flex items-center justify-between w-full md:w-auto">
            <Link to="/">
              <span className="sr-only">KID</span>
              <div className="font-mono text-3xl text-gray-600 font-extrabold ">
                {/* <svg
                  width="25"
                  height="20"
                  viewBox="0 0 716 815"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M699.66 20.2744C721.151 41.7661 721.151 76.6109 699.66 98.1025L493.369 304.394C436.352 361.41 436.352 453.852 493.369 510.869L699.717 717.217C721.24 738.74 721.24 773.637 699.717 795.16V795.16C678.194 816.683 643.298 816.683 621.774 795.16L337.598 510.983C280.581 453.967 280.581 361.525 337.598 304.508L621.832 20.2745C643.323 -1.21717 678.168 -1.21718 699.66 20.2744V20.2744Z"
                    fill="#52CA95"
                  />
                  <rect width="126" height="815" rx="11" fill="#52CA95" />
                </svg> */}
                <img
                  className="h-20 w-18 sm:h-8"
                  style={{ height: "84px" }}
                  src={Logo}
                  alt="Seedja Logo"
                />
              </div>
            </Link>
            {/* <div className=" lg:inline-block hidden text-white">
              <span className=" px-8">Home</span>
              <span className=" px-8">My Dashboard</span>
            </div> */}
          </div>
        </div>
        <div className="md:flex items-center ">
          {/* <div className=" lg:inline-block hidden text-white">
            <span className=" px-8">Message</span>
            <span className=" px-8">Favorites</span>
          </div> */}
          {props.pageType && props.pageType === "signup" ? (
            <Link
              to="/"
              className="inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-theme-main-hover hover:bg-theme-main"
            >
              Login
            </Link>
          ) : (
            <>
              <Link
                to="/faq"
                className="mr-4 inline-flex items-center px-6 py-3 border  text-xs font-medium rounded-full text-white "
              >
                FAQ
              </Link>
              <Link
                to="/about-us"
                className="mr-4 inline-flex items-center px-6 py-3 border  text-xs font-medium rounded-full text-white "
              >
                About Us
              </Link>
              <Link
                to="/signup"
                className="hidden sm:block inline-flex items-center px-6 py-3 border  text-xs font-medium rounded-full text-white "
              >
                Sign Up
              </Link>
              {/* <Link
                to="/partner-signup"
                className="inline-flex items-center mx-4 px-4 py-2 border border-transparent text-xs font-medium rounded-md text-white bg-theme-main-hover hover:bg-theme-main"
              >
                Partner's Sign up
              </Link> */}
            </>
          )}
        </div>
      </nav>
    </div>
  );
}

export default NavBarLanding;
