import React from "react";
import {
  vector,
  pic8,
  bottom,
} from "../../assets/img/about-img/index";
import "../../index.css";
import NavBarLanding from "../../components/NavBarLanding/index";
import Footer from "../../components/Footer";

const About = () => {

  let imrgUrls = [
    { url: "https://seedja-prod.s3.amazonaws.com/seedja-prod/1.jpg" },
    { url: "https://seedja-prod.s3.amazonaws.com/seedja-prod/2.jpg" },
    { url: "https://seedja-prod.s3.amazonaws.com/seedja-prod/3.jpg" },
    { url: "https://seedja-prod.s3.amazonaws.com/seedja-prod/4.jpg" },
    { url: "https://seedja-prod.s3.amazonaws.com/seedja-prod/5.jpg" },
    { url: "https://seedja-prod.s3.amazonaws.com/seedja-prod/6.jpg" },
    { url: "https://seedja-prod.s3.amazonaws.com/seedja-prod/7.jpg" },
    { url: "https://seedja-prod.s3.amazonaws.com/seedja-prod/lastPic.jpg" },
  ]

  return (
    <>
      <div className="w-full bg-gray-400">
        <NavBarLanding />
      </div>
      <div className=" text-center">
        <div className=" relative text-center ">
          <div className=" flex flex-wrap  ">
            {imrgUrls.slice(0, 4).map((el, key) => {
              return (
                <img className=" flex-grow w-1/4" key={key} src={el.url} alt="" />
              );
            })}
          </div>
          <div className=" flex flex-wrap  ">
            {imrgUrls.slice(4).map((el, key) => {
              return (
                <img className=" flex-grow w-1/4" key={key} src={el.url} alt="" />
              );
            })}
          </div>
          <div className=" absolute w-full inset-y-1/3">
            <span
              style={{ color: "#1CE783" }}
              className=" text-5xl font-medium lg:text-9xl lg:font-semibold text-"
            >
              Never Invisible
            </span>
          </div>
        </div>
        <div className=" w-full absolute">
          <img src={vector} className=" relative" alt="" />
          <div className=" absolute md:text-center text-left lg:text-center sm:text-2xl md:text-3xl justify-center items-center w-full  sm:flex-col lg:text-6xl flex lg:flex-col font-normal lg:font-semibold  inset-y-10 md:inset-y-24 lg:inset-y-36 ">
            <div className="flex justify-center lg:p-0 p-5 flex-col sm:flex-row lg:flex-row">
              <span>People First,</span>
              <span style={{ color: "#1CE783" }}>People Always</span>
            </div>
            <span className=" px-10 py-8 md:px-32 lg:px-48">
              <p className="lg:px-48 p-1 lg:py-10 sm:py-6 font-normal text-base md:text-lg lg:text-2xl ">
                We are obsessed about people, particularly those who feel unseen
                and unimportant to the traditional financial oligarcy.
              </p>
              <p className=" lg:px-48 p-1 font-normal text-sm md:text-lg lg:text-2xl ">
                And so every product we create is designed to level the
                financial playing field and deliver extraordinary opportunities
                to the ordinary everyday people.
              </p>
            </span>
          </div>
          <div className=" absolute w-full text-center">
            <div className=" relative">
              <img className=" relative w-full" src={pic8} alt="" />
              <div className=" flex justify-center items-center">
                <p className=" text-white inset-y-80 absolute flex justify-center items-center flex-col  lg:text-6xl font-semibold w-full">
                  <span className=" flex-row ">
                    Innovation,{" "}
                    <span style={{ color: "#1CE783" }}>with heart</span>
                  </span>
                  <p className=" text-base md:text-lg lg:text-2xl  font-normal lg:mx-96 lg:my-16 text-white">
                    We believe technology should make life easier not harder,
                    and it must be accessible to all if it is to be truly fair,
                    because true innovation should inspire and improve humanity.
                  </p>
                </p>
              </div>
            </div>
            <div
              style={{ backgroundColor: "#253646" }}
              className="md:flex lg:flex"
            >
              <img className="object-cover" src={bottom} alt="" />
              <div
                className="text-white w-full px-10 lg:px-10 lg:w-2/3 text-left"
                style={{ backgroundColor: "#253646" }}
              >
                <h1 className=" text-2xl lg:text-4xl font-semibold py-8">
                  We see you.
                </h1>
                <div className=" text-sm lg:text-lg lg:pr-52">
                  <p className=" pb-4 ">
                    Yeah, we know It could sound creepy, but it’s actually not.
                  </p>
                  <p className=" pb-1">
                    We see you means, we see you working hard to provide for
                    your kids every day, we see you working your hustle so you
                    can build your business. We see you finally getting the car
                    you want so you don’t have to take the bus (if you don’t
                    want to).
                  </p>
                  <p className=" py-3">
                    We see you means your dreams are not invisible to us.
                  </p>
                  <p className="py-3">
                    And that’s important, because sometimes all the other
                    financial institutions see is your credit history, or the
                    fact that you only have a job with a month to month
                    contract. They see who you’re not, we see who you can
                    become.
                  </p>
                  <p className=" pb-3">
                    So our loans and financial solutions are tailored to help
                    you achieve not just what we see…
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-0.5" style={{ backgroundColor: "#253646" }}>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
