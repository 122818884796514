/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { _logOut } from "../../services/apiService";
import Cookies from "universal-cookie";
import NotificationDropdown from "../../components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "../../components/Dropdowns/UserDropdown.js";
import Logo from "../../assets/img/seedja-logo2.png";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const logOut = async () => {
    const cookies = new Cookies();
    cookies.remove("kidUser");
    let result = await _logOut();
    if (result) {
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  };
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap  shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <Link to="/admin">
            <button
              className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              type="button"
              onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
            ></button>
            {/* Brand */}
            <img className="object-contain h-8" alt="KID" src={Logo} />
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Notus React .......
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>
            {/* Heading */}
            <h6 className="mt-4 md:min-w-full text-lg uppercase font-semibold block pt-6 no-underline ">
              Main panel
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/contests") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/contests"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/contests") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Contests
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/prizes") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/prizes"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/prizes") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Prizes
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/redeem") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/redeem"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/redeem") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Redeem
                </Link>
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/email-user") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/email-user"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/email-user") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Email user
                </Link>
              </li>
            </ul>
            <h6 className="md:min-w-full text-xs text-lg uppercase font-semibold block pt-6 no-underline ">
              User related panel
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/users") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/users"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/users") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Users
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/partners") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/partners"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/partners") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Partner
                </Link>
              </li>
              {/* <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/quizes") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/quizes"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/quizes") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Quiz Info
                </Link>
              </li> */}
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/submission") !== -1
                    && window.location.href.indexOf("/admin/submission/model-5") === -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/submission"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/submission") !== -1
                      && window.location.href.indexOf("/admin/submission/model-5") === -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Submission
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/submission/model-5") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/submission/model-5"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/submission/model-5") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Submission (5 in 5)
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/verification") !== -1
                      && window.location.href.indexOf("/admin/verification/model-5") === -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/verification"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/verification") !== -1                      
                      && window.location.href.indexOf("/admin/verification/model-5") === -1
                      -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Verification
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/verification/model-5") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/verification/model-5"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/verification/model-5") !==
                      -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Verification (5 in 5)
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/loans") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/loans"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/loans") !==
                      -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Applied Loans
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf(
                      "/admin/configure-information"
                    ) !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/configure-information"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/configure-information"
                      ) !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Configure Information
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf(
                      "/admin/configure-verification"
                    ) !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/configure-verification"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/configure-verification"
                      ) !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Configure Verification
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/edit-copy") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/edit-copy"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/edit-copy") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Edit copy
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/add-admin") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/add-admin"
                >
                  <i className={"fas fa-tools mr-2 text-sm "}></i> Add Admin
                  User
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/messages") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/messages"
                >
                  <i className={"fas fa-tools mr-2 text-sm "}></i> Messages
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-base font-semibold block " +
                    (window.location.href.indexOf("/admin/export") !== -1
                      ? "text-blue-500 text-sm pt-1 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/export"
                >
                  <i className={"fas fa-tools mr-2 text-sm "}></i> Export Data
                </Link>
              </li>
              <li className="fas fa-sign-out-alt items-center text-base font-semibold block text-gray-400 cursor-pointer">
                <Link to="/admin/logout" onClick={() => logOut()}>
                  <p className="inline ml-3 text-gray-600">Log out</p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
