import DynamicForm from "../../components/DynamicForm";
import React, { useEffect, useState } from "react";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import * as yup from "yup";
import { _verifyInformation, _getTree } from "../../services/apiService";
import { _getOneSubmittedInformation}  from "../../services/apiService";

function Verification(props) {
  const [treeInfo, settreeInfo] = useState(null);
  const [prefilled, setprefilled] = useState(false);
  useEffect(() => {
    async function fetchData() {
      const image = await _getTree();
        settreeInfo(image)
      
    }
    async function prefill() {
      if(props.location.state.allData.name =="Income Information") {
        let result = await _getOneSubmittedInformation(props.location.state.allData.sId);
        console.log("props.location.state:",props.location.state.data)
        props.location.state.data[0].value = result.data.employment_status;
        props.location.state.data[1].value = result.data.how_often_paid;
      } else if(props.location.state.allData.name =="Occupational Information") {
        let result = await _getOneSubmittedInformation(props.location.state.allData.sId);
        console.log("props.location.state:",props.location.state.data)
        console.log("result:",result)
        props.location.state.data[0].value = result.data.employment_status;
      } else if(props.location.state.allData.name =="Housing Information") {
        // let result = await _getOneSubmittedInformation(props.location.state.allData.sId);
        // console.log("props.location.state:",props.location.state.data)
        // console.log("result:",result, props.location.state.data)
        // props.location.state.data[0].value = result.data.has_house;
      }
      setprefilled(true);
    }
    fetchData();
    prefill();
  }, []);
  // add code that nobody reaches here directly
  const { data, id, allData, allInfo,index } = props.location.state;
  console.log("index:",index);
  console.log(allData)
  let validateSchema;
  let dataCopy = [];
  if(allData && allData.name === 'Debt Information') {
    data.forEach(el => {
      // "mortgage_loan_bank_statement" = mortgage_loan
      const dataName = el.name.replace("_bank_statement","");
      if(allData.submittedInfo.data[dataName]) {
        dataCopy.push(el);
      }
    });
    const yepSchema = dataCopy.reduce(createYupSchema, {});
    validateSchema = yup.object().shape(yepSchema);

  } else if(allData && allData.name === 'Banking Information') {
    // re
    data.forEach(el => {
      // "bank_name_5_statement" = bank_name_5
      const dataName = el.name.replace("_statement","");
      console.log("allData.submittedInfo.data[dataName]:",allData.submittedInfo.data[dataName]);
      if(allData.submittedInfo.data[dataName] && 
          allData.submittedInfo.data[dataName]!=='') {
        dataCopy.push(el);
      }
    });
    console.log("dataCopy:",dataCopy);
    const yepSchema = dataCopy.reduce(createYupSchema, {});
    validateSchema = yup.object().shape(yepSchema);

  } else {
    dataCopy = data;
    const yepSchema = data.reduce(createYupSchema, {});
    validateSchema = yup.object().shape(yepSchema);
  }

  async function submitData(data = "") {
    if (data) {
      const extraInfo = {
        verificationId: id,
        sId: allData.sId,
        isVerificationRequired: Object.keys(data).length > 0 ? true : false,
      };
      const response = await _verifyInformation(data, extraInfo);
      return response;
    }
  }
  return (
    <div>
      <div className="flex items-center w-full">
        {/* sidebar */}
        {/* <div className="flex flex-col-reverse md:col-span-1" 
        style={{
          background: `rgba(255, 255, 255, 0.09)`,
  border: `1px solid #B8FFE4`,
  boxShadow: `0px 0px 30px rgba(0, 0, 0, 0.04)`,
  backdropFilter: 'blur(28px)',
  borderRadius: '20px'
  }}>
          <div className="flex justify-center  px-4 sm:px-0">
          {treeInfo ?  <img
                  alt="..."
                  src={require(`../../assets/img/${treeInfo}`).default}
                />: ''}
            
          </div>
        </div> */}
        {/* The form */}
        {/* {data && data.length > 0 ?
      <></>:<><p>No Verification is required. Just click submit</p></>
      } */}
        {prefilled && dataCopy ? (
          <DynamicForm
            onSubmit={submitData}
            allData={allData}
            allInfo={allInfo}
            index={index}
            history={props.history}
            fields={dataCopy}
            isVerification={true}
            validation={validateSchema}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Verification;
