import TaskCard from "../../components/Card/taskCard";
// import AsynchronousAutocomplete from "../../components/AutoComplete";
// import NotFound from "../../components/NotFound";
import Confetti from "react-dom-confetti";
import React, { useState, useEffect } from "react";
import {
  _getContestsUser,
  _joinContest,
  _getInformations,
  _getNextPrizeInformation,
} from "../../services/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

function Home(props) {
    // Create an error boundary using the useErrorBoundary hook
  const [isActive, setisActive] = useState(false);
  const [contestData, setContestData] = useState(null);
  const [nextPrizeData, setNextPrizeData] = useState(null);
  const [informationData, setInformationData] = useState(null);
  const [toggleView, setToggleView] = useState(true);

  const cookies = new Cookies();
  const cookieInfo = cookies.get('kidUser');

  async function joinContest(contest) {
    const response = await _joinContest(contest._id);
    if (response.ok) {
      // show success and error
      contest.isJoined = true;
      setisActive(true);
      toast.success("Contest has been joined", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }
  useEffect(() => {
    async function fetchContests() {
      let result = await _getContestsUser();
      setContestData(result);
    }
    async function fetchInformations() {
      let result = await _getInformations();
      console.log(cookieInfo,result)
      if(cookieInfo.infoStatus && cookieInfo.infoStatus=="FirstModel") {
        result = result.filter(el => el.for=="PreQualUser");
      }
      console.log("resulr",result)
      setInformationData(result);
    }
    async function getUserNext() {
      let result = await _getNextPrizeInformation();
      setNextPrizeData(result);
    }
    fetchContests();
    fetchInformations();
    getUserNext();
  }, []);

  const config = {
    angle: 90,
    spread: 180,
    startVelocity: 40,
    elementCount: 360,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "700px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  return (
    <div className="grid grid-cols-3 gap-4">
      {/* <AsynchronousAutocomplete type="prizes" label="Contest Prize"/> */}
      <div className="col-span-3 sm:col-span-2 px-2 sm:px-6 md:px-2">
        {/* <NotFound title="Not found" description="my description"/> */}
        <div className="cursor-pointer bg-white shadow rounded-md p-1 pb-1 mt-3 flex  justify-center">
        <div className={`w-1/2 flex-grow border-b-2 focus:outline-none py-2 text-xs sm:text-lg px-1 ${
                        toggleView 
                          ? "text-indigo-500 border-indigo-500"
                          : "border-gray-300"
                      }`}
                      onClick={() => {
                        setToggleView((prev) => !prev);
                      }}>
                         Submit Data
                      </div>
        <div className={`flex-grow w-1/2  border-b-2 focus:outline-none py-2 text-xs sm:text-lg px-1 ${
                        !toggleView
                          ? "text-indigo-500 border-indigo-500"
                          : "border-gray-300"
                      }`}
                      onClick={() => {
                        setToggleView((prev) => !prev);
                      }}>
                        Submit my supporting documents
                      </div>
                      </div>
        {/* <div className="bg-white shadow rounded-md p-2 mt-3">
          <label className="toggle">
            <input
              onClick={() => {
                setToggleView((prev) => !prev);
              }}
              className="toggle-checkbox"
              type="checkbox"
              checked={toggleView}
            />
            <div className="toggle-switch"></div>
            <span className="toggle-label">
              {toggleView ? "Information" : "Verification"}
            </span>
          </label>
        </div> */}
        {informationData && toggleView ? (
          informationData.filter(el => 
            !(el.userStatus === "Submitted" && el.status && el.status !== "Verified")).length !== 0 ?
          informationData.filter(el => 
            !(el.userStatus === "Submitted" && el.status && el.status !== "Verified")).map((information, index) => (
            <TaskCard
              key={index}
              keyIndex={index}
              data={information}
              allInfo={informationData.filter(el => 
                !(el.userStatus === "Submitted" && el.status && el.status !== "Verified"))}
              props={props}
              redirect={true}
              disablePossible={true}
            />
          ))
        :<div className="bg-white mt-4 p-2 text-center">Thank you {cookieInfo.fullName}, your information has been saved, please click Submit documents to complete your Loan Application</div>) : informationData && !toggleView ? (
          informationData.filter(el => 
            (el.userStatus === "Submitted" && el.status && el.status !== "Verified")).length !==0 ?
            informationData.filter(el => 
            (el.userStatus === "Submitted" && el.status && el.status !== "Verified")).map((information, index) => (
            <TaskCard
              key={index}
              keyIndex={index}
              data={information}
              allInfo={informationData.filter(el => 
                (el.userStatus === "Submitted" && el.status && el.status !== "Verified"))}
              props={props}
              redirect={true}
              disablePossible={true}
            />
          ))
          :informationData.filter(el => 
            !(el.userStatus === "Submitted" && el.status && el.status !== "Verified")).length === 0 ?
          <div className="bg-white mt-4 p-2 text-center">All Documents have been submitted. Your personalized assessment is in progress</div>
          :'') : (
          [...Array(4)].map((e, i) => (
            <div className="my-4" key={i}>
              <div className="bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                <div className="animate-pulse flex space-x-4">
                  <div className="rounded-full bg-gray-200 h-12 w-12"></div>
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                    <div className="space-y-2">
                      <div className="h-4 bg-gray-200 rounded"></div>
                      <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="col-span-3 my-3 sm:col-span-1 bg-white rounded-xl shadow-sm hover:shadow-lg duration-500 px-2 sm:px-6 md:px-5 py-4">
        {contestData &&
          contestData.map((contest, index) => (
            <div key={index}>
              <div
                style={{ backgroundColor: "#52CA95" }}
                className="flex-shrink-0 relative overflow-hidden rounded-xl shadow-lg"
              >
                <svg
                  className="absolute bottom-0 left-0 mb-8"
                  viewBox="0 0 375 283"
                  fill="none"
                  style={{ transform: `scale(0.6)`, opacity: 0.08 }}
                >
                  <rect
                    x="159.52"
                    y="175"
                    width="152"
                    height="152"
                    rx="8"
                    transform="rotate(-45 159.52 175)"
                    fill="white"
                  />
                  <rect
                    y="107.48"
                    width="152"
                    height="152"
                    rx="8"
                    transform="rotate(-45 0 107.48)"
                    fill="white"
                  />
                </svg>
                <div className="relative pt-10 px-10 flex items-center justify-center">
                  <div
                    className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
                    style={{
                      background: `radial-gradient(black, transparent 60%)`,
                      transform: `rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)`,
                      opacity: 0.2,
                    }}
                  ></div>
                  <img
                    className="relative w-40"
                    src={
                      contest.prize && contest.prize.name
                        ? contest.prize.imageUrl
                        : ""
                    }
                    alt=""
                  />
                </div>
                <div className="relative text-white px-6 pb-6 mt-6">
                  {/* <span className="block opacity-75 -mb-1">Indoor</span> */}
                  <div className="">
                    <div className="block font-semibold my-2 text-xl capitalize leading-none pr-8">
                      {contest.prize && contest.prize.name
                        ? contest.prize.name
                        : ""}{" "}
                    </div>
                    <span className="bg-white rounded-xl text-secondary text-xs font-semibold px-3 py-1">
                      {contest.prize && contest.prize.name
                        ? "$ " + contest.prize.value
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mb-5 w-full bg-gray-50 ">
                <Confetti active={isActive} config={config} />
                <div className="relative max-w-screen-sm text-white bg-theme-triad overflow-hidden rounded-xl my-1 shadow-xl">
                  {/* <svg
                    className="absolute bottom-0 left-0 mb-8"
                    viewBox="0 0 375 283"
                    fill="none"
                    style={{ transform: `scale(1.5)`, opacity: 0.05 }}
                  >
                    <rect
                      x="159.52"
                      y="175"
                      width="152"
                      height="152"
                      rx="8"
                      transform="rotate(-45 159.52 175)"
                      fill="white"
                    />
                    <rect
                      y="107.48"
                      width="152"
                      height="152"
                      rx="8"
                      transform="rotate(-45 0 107.48)"
                      fill="white"
                    />
                  </svg> */}

                  <div className="px-6 py-4">
                    <h1 className="font-semibold my-2 text-xl capitalize leading-none pr-8">
                      {contest.name}
                    </h1>
                    <div className="w-full flex-none text-xs font-medium text-gray-200 pb-1">
                      {contest.category}
                    </div>
                    <div>
                      <ul className="list-disc px-4 text-xs">
                        {contest.description.split(",").map((el, index) => (
                          <li key={index}>{el}</li>
                        ))}
                      </ul>
                    </div>
                    <p className="mt-1">
                      <button
                        onClick={() => joinContest(contest)}
                        id="addToCartButton"
                        className="text-button bg-theme-main hover:bg-theme-main-hover rounded-md px-2 py-1 mt-2 text-xs"
                        disabled={contest.isJoined}
                      >
                        {contest.isJoined
                          ? "Contest Joined"
                          : "Join the Contest"}
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {/* new */}
        {/* point away from coupon */}
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {nextPrizeData ? (
          <div className="my-5 w-full bg-gray-50 ">
            <div className="max-w-screen-sm bg-purple-800 overflow-hidden rounded-xl">
              <div className="flex-col">
                {nextPrizeData.hasPoints && nextPrizeData.hasPoints.prize ? (
                  <div className="flex flex-auto p-6 flex-wrap">
                    <h1 className="text-white font-bold flex-auto text-xl font-semibold">
                      {nextPrizeData.hasPoints
                        ? nextPrizeData.prize.pointRequired
                          ? "Use " +
                            nextPrizeData.prize.pointRequired +
                            " seeds to redeem your next coupon"
                          : "Use " +
                            nextPrizeData.prize.pointRequired +
                            " seeds to redeem your first coupon"
                        : nextPrizeData.redeems.length > 0
                        ? nextPrizeData.prize.pointRequired -
                          nextPrizeData.points +
                          " seeds away from your next coupon"
                        : nextPrizeData.prize.pointRequired -
                          nextPrizeData.points +
                          " seeds away from your first coupon"}
                    </h1>

                    <div className="w-full flex-none text-sm font-bold text-green-300 mt-2 text-xl">
                      {nextPrizeData.hasPoints
                        ? nextPrizeData.prize.pointRequired
                        : nextPrizeData.prize.pointRequired -
                          nextPrizeData.points}{" "}
                      <sup className="font-light text-xs">Pt</sup>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <p className="text-sm text-gray-200 p-6">
                  Answer Questions to earn seed points and redeem rewards
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Home;
