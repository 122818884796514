import { useField } from "formik";

const TextAreaInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="relative">
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea
        className={`block box w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mb-2 text-sm border-gray-300 rounded-md ${
          meta.touched && meta.error ? "border-red-700" : ""
        }`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="error text-xs font-semibold text-red-700">
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default TextAreaInput;
