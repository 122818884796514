import "./index.css";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./routes/Home";
import Redeem from "./routes/Redeem";
import Information from "./routes/Information";
import Verification from "./routes/Verification";
import Dashboard from "./routes/Dashboard";
import Overview from "./routes/Overview";
import Profile from "./routes/Profile";
import Quiz from "./routes/Quiz";
import Data from "./routes/Data";
import Activity from "./routes/Activity";
import Landing from "./routes/Landing";
import Signup from "./routes/Signup";
import PartnerSignup from "./routes/PartnerSignup";
import LoanServices from "./routes/LoanService/index";
import LoanListView from "./routes/LoanService/LoanListView";
import PartnersLoan from "./routes/LoanService/PartnersLoan";
import UserLoanApplicationListView from "./routes/UserLoanApplication/UserLoanApplicationList";
import RequestLink from "./routes/PasswordReset/requestLink";
import ResetPassword from "./routes/PasswordReset/resetPassword";
import Messages from "./routes/Messages/messages";

import Admin from "./routes/Admin";
import AdminLogin from "./routes/Landing/admin";
import Partner from "./routes/Partner";
import About from "./routes/AboutUs";
import TermsandConditions from "./routes/Tnc";
import LogoutLoader from "./components/Cards/LogoutLoader";
import CookieConsent from "react-cookie-consent";
import {
  Switch,
  Redirect,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import Cookies from "universal-cookie";
import FinTips from "./routes/Tips";
import ReactGA from "react-ga4";
import UpdatePassword from "./routes/UpdatePassword";
import Faq from "./routes/FAQ";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useEffect, useRef } from "react";

ReactGA.initialize("G-RNXMBV616Q");
ReactGA.send("pageview");

function App() {
  const cookies = new Cookies();
  const isLogged = cookies.get("kidUser") ? true : false;
  const cookieInfo = cookies.get("kidUser");
  const userType =
    cookieInfo && cookieInfo.userType ? cookieInfo.userType.toLowerCase() : "";
  // const userType = "admin";
  // const userType = "partner";
  const userStatus = cookieInfo && cookieInfo.status ? cookieInfo.status : "";

  const tawkMessengerRef = useRef();
  const onLoad = () => {
    tawkMessengerRef.current.visitor({
      name : cookieInfo.fullName ? cookieInfo.fullName : "",
      email : cookieInfo.email ? cookieInfo.email : "",
  },function(error) {
    console.log("error onload");
    console.log(error);
      // do something if error
  });
  //   tawkMessengerRef.current.setAttributes({
  //     name : cookieInfo.fullName ? cookieInfo.fullName : "",
  //     email : cookieInfo.email ? cookieInfo.email : "",
  // }, function(error) {
  //   console.log("error onload");
  //   console.log(error);
  //     // do something if error
  // });
    console.log("onLoad works!");
  };

  // const userStatus = "UserQuiz";
  return isLogged && userType !== "admin" && userType !== "moderator" ? (
    <>
      <CookieConsent>
        Seed Investments has applied to the Bank of Jamaica for a Microcredit
        License as per Jamaica’s Microcredit Act 2021
      </CookieConsent>
      {isLogged && userType === "partner" ? (
        <>
          <Router>
            <Switch>
              <Route
                exactly
                path="/partner"
                render={(props) => <Partner {...props} />}
              />
            </Switch>
            <Redirect from="/" to="/partner/dashboard" />
          </Router>
        </>
      ) : (
        <div
          className="flex flex-col min-h-screen"
          style={{ background: "#253646" }}
        >
          <Router>
            <NavBar />
            <main className="pt-8 flex-1">
              {userStatus === "UserQuiz" ? (
                <div>
                  <Switch>
                    <Route
                      path="/quiz"
                      render={(props) => <Quiz {...props} />}
                    />
                    <Route path="/" render={(props) => <Quiz {...props} />} />
                  </Switch>
                  <Redirect from="/" to="/quiz" />
                </div>
              ) : (
                <div className="mx-4 h-full flex-grow lg:mx-32 md:mx-32 sm:p-4">
                  <TawkMessengerReact
                    propertyId="639089b1daff0e1306db5b8c"
                    widgetId="1gjm9jnjo"
                    ref={tawkMessengerRef}
                    onLoad={onLoad}
                  />
                  <Switch>
                    <Route component={Redeem} path="/redeem/:id" />
                    <Route component={Redeem} path="/redeem" />
                    <Route component={FinTips} path="/financial-tip" />
                    {/* <Route component={Information} path="/information" /> */}
                    <Route
                      path="/information"
                      render={(props) => <Information {...props} />}
                    />
                    {/* <Route component={Verification} path="/verification" /> */}
                    <Route
                      path="/verification"
                      render={(props) => <Verification {...props} />}
                    />
                    <Route path="/logout" exact component={LogoutLoader} />
                    <Route component={Data} path="/data" />
                    <Route component={LoanListView} path="/loans/:id" />
                    <Route component={LoanListView} path="/loans" />
                    <Route component={PartnersLoan} path="/partnersloan/:id" />
                    <Route component={LoanServices} path="/loan-services" />

                    <Route
                      component={UserLoanApplicationListView}
                      path="/loan-application/:id"
                    />
                    <Route
                      component={UserLoanApplicationListView}
                      path="/loan-application"
                    />
                    <Route component={Messages} path="/messages" />
                    <Route component={Home} path="/earn-points" />
                    
                      <Route
                        path="/overview"
                        render={(props) => <Overview {...props} />}
                      />
                    <Route component={Activity} path="/activity" />
                    {/* <Route component={Profile} path="/profile" /> */}
                    <Route
                      path="/profile"
                      render={(props) => <Profile {...props} />}
                    />
                    <Route
                      path="/update-password"
                      render={(props) => <UpdatePassword {...props} />}
                    />
                    {/* <Route exactly component={Home} pattern="/" /> */}
                    
                    <div className=" flex-1 h-full">
                      <Route
                        exactly
                        path="/"
                        render={(props) => <Dashboard {...props} />}
                      />
                    </div>
                    {/* <Route exactly component={Dashboard} pattern="/" /> */}
                  </Switch>
                </div>
              )}
            </main>
            <Footer />
          </Router>
        </div>
      )}
    </>
  ) : isLogged && (userType === "admin" || userType === "moderator") ? (
    <Router>
      <Switch>
        <Route exactly path="/admin" render={(props) => <Admin {...props} />} />
      </Switch>
      <Redirect from="/" to="/admin/dashboard" />
    </Router>
  ) : (
    <Router>
      <Switch>
        <Route component={TermsandConditions} path="/termsandconditions" />
        <Route component={Faq} path="/faq" />
        <Route component={About} path="/about-us" />
        <Route component={Signup} path="/signup" />
        <Route component={AdminLogin} path="/admin" />
        <Route component={PartnerSignup} path="/partner-signup" />
        <Route component={RequestLink} path="/request-link" />
        <Route
          exactly
          path="/password-change"
          render={(props) => <ResetPassword {...props} />}
        />
        {/* <Route exactly component={Landing} pattern="/" /> */}
        <Route
          exactly
          path="/admin-login"
          render={(props) => <Landing {...props} />}
        />
        <Route exactly path="/" render={(props) => <Landing {...props} />} />
      </Switch>
    </Router>
  );
}

export default App;
