import * as Yup from "yup";
import { Form, Formik } from "formik";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import Footer from "../../components/Footer";
import TextInput from "../../components/TextInput";
import { _login } from "../../services/apiService";
import { toast, ToastContainer } from "react-toastify";


const AdminLogin = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const notify = (isSuccess, message = "") => {
    if (isSuccess) {
      toast.success("Welcome! Login successful", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setIsDisabled(false);
      toast.error("Login failed! " + message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <div className="w-full min-h-screen flex flex-col justify-between">
      <div className="flex justify-center mt-24">
              <div className="solid w-full shadow-lg md:max-w-lg sm:rounded-lg sm:overflow-hidden mx-5 md: px-6 py-10 sm:px-10 form-bg">
        <div className="text-xl font-bold	mb-4 text-black">Admin Login</div>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Invalid email address")
              .required("Email is Required"),
            password: Yup.string().required("Password is Required"),
          })}
          onSubmit={(values, { resetForm }) => {
            let _data = {
              email: values.email,
              password: values.password,
            };
            setIsDisabled(true);
            setTimeout(async () => {
              let response = await _login(_data);

              if (response.ok) {
                let result = await response.json();
                resetForm();
                notify(response.ok);
                const cookies = new Cookies();
                cookies.set("kidUser", JSON.stringify(result), {
                  path: "/",
                });
                window.location.href = "/";
              } else {
                const message = await response.text();
                notify(response.ok, message);
              }

              // setSubmitting(false);
            }, 400);
          }}
        >
          <Form className="md:max-w-md">
            <div>
              <TextInput
                name="email"
                type="email"
                placeholder="Email address"
              />
            </div>
            <div>
              <TextInput
                name="password"
                type="password"
                placeholder="Password"
              />
            </div>
            <div className="py-2 ">
              <button
                style={{ backgroundColor: "#1CE783" }}
                type="submit"
                className="block w-full py-3 my-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-black  hover:bg-theme-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={isDisabled}
              >
                Log in
              </button>
            </div>
            <div className="py-1">
              <p className="text-center text-black text-sm font-medium hover:underline">
                <Link to="/request-link">Forgot Password?</Link>
              </p>
            </div>
          </Form>
        </Formik>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      </div>
      <div style={{ backgroundColor: "#253646" }}>
        <Footer />
      </div>
    </div>
  );
};

export default AdminLogin;
