

import OverviewComponent2  from "../../components/Dashboard/OverviewComponent2";

function Overview(props) {


    return(
        <>
        <OverviewComponent2 props={props}/>
        </>
    )
 }
 
 export default Overview;