import React, { useState, useEffect } from "react";
import NotFound from "../../components/NotFound/index";
import { _getUserInformations } from "../../services/apiService";
import Cookies from "universal-cookie";
import TaskCard from "../../components/Card/taskCard";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import * as yup from "yup";

function Data() {
  const [currentView, setCurrentView] = useState("sumbitted");
  const [response, setResponse] = useState(null);
  const [userData, setUserData] = useState(null);

  const [dataView, setDataView] = useState("list");
  const [oneData, setOneData] = useState(null);
  const [oneDataValidateSchema, setOneDataValidateSchema] = useState(null);

  function prepareForm(information) {
    const schema =
      information.status === "Submitted"
        ? "informationSchema"
        : "verificationSchema";
    const fields = information[schema].fields;
    const data =
      information.status === "Submitted"
        ? information["data"]
        : information["submittedVerification"]["data"];
    fields.forEach((fieldObj) => {
      if (data[fieldObj["name"]]) {
        let value = data[fieldObj["name"]];
        if (fieldObj["validationType"] === "number") {
          value = parseInt(value);
        } else if (fieldObj["validationType"] === "date") {
          fieldObj["type"] = "text";
        } else {
          if (value.constructor === Array) {
            value = value.map((el) => el.name).join(",");
          } else if (typeof value === "object" && value !== null) {
            value = value.name;
          }
        }
        fieldObj["value"] = value;
      }
    });
    const yepSchema = fields.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);
    setOneData(fields);
    setOneDataValidateSchema(validateSchema);
  }

  function viewOneData(information) {
    prepareForm(information);
    setDataView("one");
  }

  useEffect(() => {
    async function fetchData() {
      // You can await here
      let result = await _getUserInformations();
      setResponse(result);
      const cookies = new Cookies();
      // const isLogged = cookies.get('kidUser') ? true : false;
      const cookieInfo = cookies.get("kidUser");
      setUserData(cookieInfo);
      // ...
    }
    fetchData();
  }, []);

  return (
    <div className="md:grid md:grid-cols-3 md:gap-6">
      {/* sidebar */}
      {/* The content */}
      <div className="md:col-span-3">
        <div>
          <section className="min-h-screen bg-white text-gray-600 body-font overflow-hidden">
            <div className="container px-5 py-4 mx-auto">
              <div className="lg:w-4/5 mx-auto flex flex-wrap">
                <div className="w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
                  <h1 className="text-gray-900 text-3xl title-font font-medium mb-4">
                    {userData && userData.fullName
                      ? userData.fullName + "'s Info Center"
                      : "Info Center"}
                  </h1>
                  <div className="flex mb-4 flex-wrap">
                    <button
                      // style={{ color: "#FBFA58" }} TODO
                      className={`flex-grow border-b-2 focus:outline-none py-2 text-lg px-1 ${
                        currentView === "sumbitted"
                          ? "text-indigo-500 border-indigo-500"
                          : "border-gray-300"
                      }`}
                      onClick={() =>
                        setDataView("list") || setCurrentView("sumbitted")
                      }
                    >
                      Submitted
                    </button>
                    <button
                      className={`flex-grow border-b-2 focus:outline-none py-2 text-lg px-1 ${
                        currentView === "SubmittedForVerfication"
                          ? "text-indigo-500 border-indigo-500"
                          : "border-gray-300"
                      }`}
                      onClick={() =>
                        setDataView("list") ||
                        setCurrentView("SubmittedForVerfication")
                      }
                    >
                      Submitted Verification
                    </button>
                    <button
                      className={`flex-grow border-b-2 focus:outline-none py-2 text-lg px-1 ${
                        currentView === "approved"
                          ? "text-indigo-500 border-indigo-500"
                          : "border-gray-300"
                      }`}
                      onClick={() =>
                        setDataView("list") || setCurrentView("approved")
                      }
                    >
                      Approved
                    </button>
                    <button
                      className={`flex-grow border-b-2 focus:outline-none py-2 text-lg px-1 ${
                        currentView === "rejected"
                          ? "text-indigo-500 border-indigo-500"
                          : "border-gray-300"
                      }`}
                      onClick={() =>
                        setDataView("list") || setCurrentView("rejected")
                      }
                    >
                      Rejected
                    </button>
                  </div>
                  <div>
                    {currentView === "sumbitted" ? (
                      // 'Submitted', 'SubmittedForVerfication','Verified','Rejected'
                      <div className="">
                        {response && response["Submitted"] ? (
                          <div className="grid grid-cols-3 gap-4">
                            <div className="col-span-3 px-2 flex flex-col space-y-2">
                              {/* <NotFound title="Not found" description="my description"/> */}
                              {response["Submitted"].map((information, index) =>
                                dataView === "list" ? (
                                  <div
                                    className="border border-gray-800 rounded-md"
                                    key={index}
                                    onClick={() => viewOneData(information)}
                                  >
                                    <TaskCard
                                      data={information}
                                      status={information.status}
                                    />
                                  </div>
                                ) : index === 0 ? (
                                  <div key={index}>
                                    {/* <h1>one</h1> */}
                                    <div
                                      className="flex bg-white pt-2 pr-4 pb-6"
                                      key={index}
                                    >
                                      <button
                                        className="underline my-5 ml-2 text-sm flex flex-row"
                                        onClick={() => setDataView("list")}
                                      >
                                        <svg
                                          version="1.1"
                                          id="Capa_1"
                                          className="w-5 h-5 fill-current"
                                          xmlns="http://www.w3.org/2000/svg"
                                          x="0px"
                                          y="0px"
                                          viewBox="0 0 26.676 26.676"
                                        >
                                          <g>
                                            <path
                                              d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                                            />
                                          </g>
                                        </svg>
                                        <div className="underline px-1">
                                          Go back
                                        </div>
                                      </button>
                                    </div>
                                    <DynamicInfoRender
                                      fields={oneData}
                                      validation={oneDataValidateSchema}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-center">
                            <NotFound
                              title="You have not submitted anything yet."
                              description=" Please submit informations from Home"
                              background="white"
                            />
                          </div>
                        )}
                      </div>
                    ) : currentView === "SubmittedForVerfication" ? (
                      // 'Submitted', 'SubmittedForVerfication','Verified','Rejected'
                      <div className="">
                        {response && response["SubmittedForVerfication"] ? (
                          <div className="grid grid-cols-3 gap-4">
                            <div className="col-span-3 px-2 flex flex-col space-y-2">
                              {/* <NotFound title="Not found" description="my description"/> */}
                              {response["SubmittedForVerfication"].map(
                                (information, index) =>
                                  dataView === "list" ? (
                                    <div
                                      className="border border-gray-800 rounded-md"
                                      key={index}
                                      onClick={() => viewOneData(information)}
                                    >
                                      <TaskCard
                                        data={information}
                                        status={information.status}
                                      />
                                    </div>
                                  ) : index === 0 ? (
                                    <div key={index}>
                                      {/* <h1>one</h1> */}
                                      <div className="flex bg-white pt-4 pr-4 pb-4">
                                        <button
                                          className="flex flex-row"
                                          onClick={() => setDataView("list")}
                                        >
                                          <svg
                                            version="1.1"
                                            id="Capa_1"
                                            className="w-5 h-5 fill-current"
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 26.676 26.676"
                                          >
                                            <g>
                                              <path
                                                d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                                              />
                                            </g>
                                          </svg>
                                          <div className="underline px-1">
                                            Go back
                                          </div>
                                        </button>
                                      </div>
                                      <DynamicInfoRender
                                        fields={oneData}
                                        validation={oneDataValidateSchema}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-center">
                            <NotFound
                              title={
                                response && response["SubmittedForVerfication"]
                                  ? "Approval is in process"
                                  : "Please submit verification information from Home"
                              }
                              description={
                                response && response["SubmittedForVerfication"]
                                  ? "Thank you for waiting. We are on it!"
                                  : "You will be able to check the status after submission of verification information"
                              }
                              background="white"
                            />
                          </div>
                        )}
                      </div>
                    ) : currentView === "approved" ? (
                      // 'Submitted', 'SubmittedForVerfication','Verified','Rejected'
                      <div className="">
                        {response && response["Verified"] ? (
                          <div className="grid grid-cols-3 gap-4">
                            <div className="col-span-3 px-2 flex flex-col space-y-2">
                              {/* <NotFound title="Not found" description="my description"/> */}
                              {response["Verified"].map((information, index) =>
                                dataView === "list" ? (
                                  <div
                                    className="border border-gray-800 rounded-md"
                                    key={index}
                                    onClick={() => viewOneData(information)}
                                  >
                                    <TaskCard
                                      data={information}
                                      status={information.status}
                                    />
                                  </div>
                                ) : index === 0 ? (
                                  <div key={index}>
                                    {/* <h1>one</h1> */}
                                    <div className="flex bg-white pt-4 pr-4 pb-4">
                                      <button
                                        className="flex flex-row"
                                        onClick={() => setDataView("list")}
                                      >
                                        <svg
                                          version="1.1"
                                          id="Capa_1"
                                          className="w-5 h-5 fill-current"
                                          xmlns="http://www.w3.org/2000/svg"
                                          x="0px"
                                          y="0px"
                                          viewBox="0 0 26.676 26.676"
                                        >
                                          <g>
                                            <path
                                              d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                                            />
                                          </g>
                                        </svg>
                                        <div className="underline px-1">
                                          Go back
                                        </div>
                                      </button>
                                    </div>
                                    <DynamicInfoRender
                                      fields={oneData}
                                      validation={oneDataValidateSchema}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-center">
                            <NotFound
                              title={
                                response && response["Verified"]
                                  ? "Approval is in process"
                                  : "Please submit information from Home"
                              }
                              description={
                                response && response["Verified"]
                                  ? "Thank you for waiting. We are on it!"
                                  : "You will be able to check the status after submission of information"
                              }
                              background="white"
                            />
                          </div>
                        )}
                      </div>
                    ) : currentView === "rejected" ? (
                      // 'Submitted', 'SubmittedForVerfication','Verified','Rejected'
                      <div className="">
                        {response && response["Rejected"] ? (
                          <div className="grid grid-cols-3 gap-4">
                            <div className="col-span-3 px-2 flex flex-col space-y-2">
                              {/* <NotFound title="Not found" description="my description"/> */}
                              {response["Rejected"].map((information, index) =>
                                dataView === "list" ? (
                                  <div
                                    className="border border-gray-800 rounded-md"
                                    key={index}
                                    onClick={() => viewOneData(information)}
                                  >
                                    <TaskCard
                                      data={information}
                                      status={information.status}
                                    />
                                  </div>
                                ) : index === 0 ? (
                                  <div key={index}>
                                    {/* <h1>one</h1> */}
                                    <div className="flex bg-white pt-4 pr-4 pb-4">
                                      <button
                                        className="flex flex-row"
                                        onClick={() => setDataView("list")}
                                      >
                                        <svg
                                          version="1.1"
                                          id="Capa_1"
                                          className="w-5 h-5 fill-current"
                                          xmlns="http://www.w3.org/2000/svg"
                                          x="0px"
                                          y="0px"
                                          viewBox="0 0 26.676 26.676"
                                        >
                                          <g>
                                            <path
                                              d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                                            />
                                          </g>
                                        </svg>
                                        <div className="underline px-1">
                                          Go back
                                        </div>
                                      </button>
                                    </div>
                                    <DynamicInfoRender
                                      fields={oneData}
                                      validation={oneDataValidateSchema}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-center">
                            <NotFound
                              title="Wooo!!!"
                              description="Nothing yet rejected. If any information submitted by you is rejected, you can see that here"
                              background="white"
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Data;
