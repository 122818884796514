import React, { useState, useEffect } from "react";
import { _getNotifications } from "../../services/apiService";
import moment from "moment";
import { Link } from "react-router-dom";

function Notify(props) {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      let result = await _getNotifications();
      setResponse(result);
      // ...
    }

    fetchData();
  }, []);

  return (
    <div className="p-1 bg-gray-50 text-sm rounded-xs shadow-lg w-full">
      {response && response.length > 0
        ? response.map((activity, index) => {
            let message = "";
            if (
              activity.action === "submittedInfo" &&
              activity.submittedInformation
            ) {
              message =
                "You submitted your " +
                activity.submittedInformation.informationSchema.name +
                " on " +
                moment(activity.created).format(
                  "dddd, MMMM Do, YYYY h:mm:ss A"
                ) +
                ". The submitted info's status is " +
                activity.submittedInformation.status;
            }
            if (
              activity.action === "submittedForVerification" &&
              activity.submittedInformation
            ) {
              message =
                "You submitted for verification " +
                activity.submittedInformation.informationSchema.name +
                " on " +
                moment(activity.created).format(
                  "dddd, MMMM Do, YYYY h:mm:ss A"
                ) +
                ". The submitted info's status is " +
                activity.submittedInformation.status;
            }
            if (
              activity.action === "infoVerified" &&
              activity.submittedInformation
            ) {
              message =
                "You got verified for " +
                activity.submittedInformation.informationSchema.name +
                " on " +
                moment(activity.created).format(
                  "dddd, MMMM Do, YYYY h:mm:ss A"
                ) +
                ". The submitted info's status is " +
                activity.submittedInformation.status;
            }
            if (
              activity.action === "infoRejected" &&
              activity.submittedInformation
            ) {
              message =
                "Got rejected for " +
                activity.submittedInformation.informationSchema.name +
                " on " +
                moment(activity.created).format(
                  "dddd, MMMM Do, YYYY h:mm:ss A"
                ) +
                ". The submitted info's status is " +
                activity.submittedInformation.status;
            }
            if (
              activity.action === "pointsReceived" &&
              activity.submittedInformation
            ) {
              message =
                "Got recieved" +
                activity.pointsInformation.point +
                "seeds for " +
                activity.submittedInformation.informationSchema.name +
                " on " +
                moment(activity.created).format(
                  "dddd, MMMM Do, YYYY h:mm:ss A"
                );
            }
            if (
              activity.action === "pointsReceived" &&
              activity.submittedInformation
            ) {
              message =
                "Got recieved" +
                activity.pointsInformation.point +
                "seeds for " +
                activity.submittedInformation.informationSchema.name +
                " on " +
                moment(activity.created).format(
                  "dddd, MMMM Do, YYYY h:mm:ss A"
                );
            }
            if (
              activity.action === "loanRejected"
            ) {
                message = `Your loan for ${activity.loanInformation.loanAmount} at rate ${activity.
                  loanInformation.interestRate} was rejected`;
            }
            if (
              activity.action === "loanApproved"
            ) {
              message = `Your loan for ${activity.loanInformation.loanAmount} at rate ${activity.
                loanInformation.interestRate} was approved. Someone from our team will contact you soon`;
            }
            if (
              activity.action === "prizeRedeemed" &&
              activity.acquisitionInformation
            ) {
              message =
                "You redeemed " +
                activity.acquisitionInformation.prize +
                " as a prize for " +
                activity.acquisitionInformation.pointSpent +
                " seeds on " +
                moment(activity.created).format("dddd, MMMM Do, YYYY");
            }
            if (
              activity.action === "contestJoined" &&
              activity.contestInformation
            ) {
              message =
                "You Joined Contest " +
                activity.contestInformation.name +
                " on " +
                moment(activity.contestInformation.created).format(
                  "dddd, MMMM Do, YYYY h:mm:ss A"
                );
            }

            return (
              <div
                className="m-1 rounded-md text-xs font-semibold shadow"
                key={index}
              >
                <Link to={{ pathname: "/profile", state: { view: "activity" } }}>
                  <div className="border-b border-gray-200">
                    <p className="p-2">{message}</p>
                  </div>
                </Link>
              </div>
            );
          })
        : ""}

      {response && response.length === 5 ? (
        <div className="text-center">
          <Link to={{ pathname: "/profile", state: { view: "activity" } }}>
            <span
              className="text-xs text-primary"
            >
              See More...
            </span>
          </Link>
        </div>
      ) : (
        ""
      )}
      {response && response.length === 0 ? (
        <div className="m-1 rounded-md text-xs font-semibold shadow">
          <div className="border-b border-gray-200">
            <p className="p-2">No notifications to show</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Notify;
