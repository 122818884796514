import React, { useState, useEffect } from "react";
import { _getPartner, _submitPartner } from "../../services/apiService";
import NotFoundPartner from "../../components/NotFound/NotFoundPartner";
import DynamicForm from "../../components/DynamicForm";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import * as yup from "yup";
import moment from "moment";

export default function Company(props) {
  const initialView =
    props.location && props.location.state && props.location.state.add
      ? true
      : false;
  const [isApiCallDone, setIsApiCallDone] = useState(false);
  const [runEffect, setRunEffect] = useState(false);
  const [companyId, setCompanyId] = useState(false);
  const [addCompany, setAddCompany] = useState(initialView);
  const [formData, setformData] = useState(null);
  const [formValidateSchema, setFormValidateSchema] = useState(null);
  const [validateSchema, setValidateSchema] = useState(null);
  const [company, setCompany] = useState(null);
  const [contestApiData, setContestApiData] = useState(null);
  const [formEditValidateSchema, setFormEditValidateSchema] = useState(null);
  const [companyFormDataEdit, setCompanyFormDataEdit] = useState(null);
  const [view, setView] = useState("viewForm");

  const messageAdd = {
    success: "Company details added successfully",
    error: "Oops an error occured!",
  };

  const messagesEdit = {
    success: "Company details edited successfully",
    error: "Can not edit the details",
  };

  const fieldsInfo = React.useMemo(
    () => [
      {
        name: "name",
        label: "Name of the company",
        placeholder: "Your company's name here",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Company's name is required"],
          },
        ],
      },
      {
        name: "contactPhone",
        label: "Company's Contact Phone number",
        placeholder: "Enter your company's phone number here",
        type: "number",
        validationType: "number",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Company's phone number is required"],
          },
          {
            type: "typeError",
            params: ["Must be a number"],
          },
        ],
      },
      {
        name: "contactEmail",
        label: "Company's Contact Email Address",
        placeholder: "Enter your Company's Contact Email Address here",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Company's Contact Email Address is required"],
          },
          {
            type: "email",
            params: ["Must be a valid email"],
          },
        ],
      },
      {
        name: "description",
        label: "Company Description",
        placeholder: "Enter Company Description",
        type: "longText",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Company's Description is required"],
          },
        ],
      },
      {
        name: "address",
        label: "Company's address",
        placeholder: "Enter your company's address here",
        type: "longText",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Company's address is required"],
          },
        ],
      },
      {
        name: "logo",
        label: "Company's logo",
        placeholder: "Upload Your company's logo",
        type: "file",
        validationType: "mixed",
        value: "",
        validations: [
          {
            type: "required",
            params: ["company's logo is required"],
          },
          {
            type: "test",
            maxFileSize: 10,
            fileTypes: ["jpg", "jpeg", "png"],
          },
        ],
      },
    ],
    []
  );

  function prepareCompanyEditForm() {
    const result = contestApiData;
    let fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
    fieldsInfoCopy = fieldsInfoCopy.filter((el) => el.hiddenOnForm !== true);
    fieldsInfoCopy.forEach((fieldObj) => {
      if (result[fieldObj["name"]] || result[fieldObj["name"]] === 0) {
        let value = result[fieldObj["name"]];
        if (fieldObj["validationType"] === "number") {
          value = parseInt(value);
        } else {
          if (value.constructor === Array) {
            value = value.map((el) => el.name).join(",");
          } else if (typeof value === "object" && value !== null) {
            value = value._id;
          }
        }
        if (fieldObj["validationType"] === "date") {
          fieldObj["value"] = moment(value).format("YYYY-MM-DD");
        }
        // else if (fieldObj["validationType"] === "mixed") {
        //   fieldObj["value"] = "";
        // }
        else {
          fieldObj["value"] = value;
        }
      }
    });

    const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);
    setFormEditValidateSchema(validateSchema);
    setCompanyFormDataEdit(fieldsInfoCopy);
  }

  async function editData(data = "") {
    if (data) {
      data._id = companyId;
      const response = await _submitPartner(data);
      return response;
    }
  }
  function editCompany() {
    prepareCompanyEditForm();
    setView("editForm");
    //id='add';
  }

  useEffect(() => {
    function prepareCompanyForm(result) {
      result = result.partner;
      const fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
      fieldsInfoCopy.forEach((fieldObj) => {
        if (result[fieldObj["name"]] || result[fieldObj["name"]] === 0) {
          let value = result[fieldObj["name"]];
          if (fieldObj["validationType"] === "number") {
            value = parseInt(value);
          } else if (fieldObj["validationType"] === "date") {
            fieldObj["type"] = "text";
          } else {
            if (value.constructor === Array) {
              value = value.map((el) => el.name).join(",");
            } else if (typeof value === "object" && value !== null) {
              value = value.name;
            }
          }
          if (fieldObj["validationType"] === "date") {
            fieldObj["value"] = moment(value).format("YYYY-MM-DD");
          } else {
            fieldObj["value"] = value;
          }
        }
      });
      const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
      const validateSchema = yup.object().shape(yepSchema);
      setValidateSchema(validateSchema);
      setCompany(fieldsInfoCopy);
    }

    async function fetchApiData() {
      let result = await _getPartner();
      setContestApiData(result.partner);
      setIsApiCallDone(true);
      if (result.partner) {
        setCompanyId(result.partner._id);
        prepareCompanyForm(result);
      }
    }

    async function fetchData() {
      let fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
      fieldsInfoCopy = fieldsInfoCopy.filter((el) => el.hiddenOnForm !== true);

      const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
      const validateSchema = yup.object().shape(yepSchema);

      setFormValidateSchema(validateSchema);
      setformData(fieldsInfoCopy);
    }

    fetchData();
    fetchApiData();
  }, [fieldsInfo, runEffect]);

  async function submitData(data = "") {
    if (data) {
      const response = await _submitPartner(data);
      return response;
    }
  }

  return (
    <>
      <div>
        {isApiCallDone ? (
          company ? (
            <div>
              {view === "viewForm" ? (
                <>
                  <div className="bg-white p-3">
                    <div className="bg-white flex justify-end space-x-2">
                      <button
                        className="inline-flex justify-center focus:outline-none mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-complement-hover"
                        onClick={() => editCompany()}
                      >
                        Edit Company data
                      </button>
                    </div>
                    <DynamicInfoRender
                      fields={company}
                      validation={validateSchema}
                    />
                  </div>
                </>
              ) : companyFormDataEdit ? (
                <>
                  <button
                    className="inline-flex justify-center py-2 border border-transparent shadow-sm text-sm underline rounded-md"
                    onClick={() => setView("viewForm")}
                  >
                    I will edit details later
                  </button>
                  <DynamicForm
                    onSubmit={editData}
                    history={props.history}
                    messages={messagesEdit}
                    onSuccess={() => {
                      setRunEffect(true);
                      setView("viewForm");
                    }}
                    redirect={false}
                    fields={companyFormDataEdit}
                    validation={formEditValidateSchema}
                  />
                </>
              ) : (
                <>
                  <div className="my-4">
                    <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                      {[...Array(4)].map((e, i) => (
                        <div
                          key={i}
                          className="animate-pulse my-10 flex space-x-4"
                        >
                          <div className="flex-1 space-y-4 py-1">
                            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                            <div className="space-y-2">
                              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                              <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : addCompany ? (
            <div className="mx-6">
              <button
                className="inline-flex justify-center py-2 border border-transparent shadow-sm text-sm underline rounded-md"
                onClick={() => setAddCompany(false)}
              >
                I will add details later
              </button>
              {formData ? (
                <DynamicForm
                  onSubmit={submitData}
                  messages={messageAdd}
                  dialogMessage="Are you sure you want to submit?"
                  onSuccess={() => {
                    setRunEffect(true);
                  }}
                  redirect={false}
                  fields={formData}
                  validation={formValidateSchema}
                />
              ) : (
                <>
                  <div className="my-4">
                    <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                      {[...Array(4)].map((e, i) => (
                        <div
                          key={i}
                          className="animate-pulse my-10 flex space-x-4"
                        >
                          <div className="flex-1 space-y-4 py-1">
                            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                            <div className="space-y-2">
                              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                              <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="mx-10 flex flex-col flex-wrap content-center">
              <NotFoundPartner title="No company data found" description="" />
              <button
                className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-main hover:bg-theme-main-hover"
                onClick={() => setAddCompany(true)}
              >
                Add company now
              </button>
            </div>
          )
        ) : (
          <>
            <div className="my-4">
              <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                {[...Array(4)].map((e, i) => (
                  <div key={i} className="animate-pulse my-10 flex space-x-4">
                    <div className="flex-1 space-y-4 py-1">
                      <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                      <div className="space-y-2">
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
