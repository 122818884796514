import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { _getAacquisitionDetail } from "../../services/apiService";
import * as yup from "yup";
import moment from "moment";
// import { Link } from 'react-router-dom';

// ../../components
import CardRedeem from "../../components/Cards/CardRedeen.js";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";

export default function Redeem(props) {
  let { id } = useParams();
  const [acquisition, setAcquisition] = useState(null);
  const [validateSchema, setValidateSchema] = useState(null);

  const fieldsInfo = React.useMemo(
    () => [
      {
        name: "created",
        label: "Created",
        placeholder: "Created Date",
        hiddenOnForm: false,
        type: "date",
        validationType: "date",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Created date is required"],
          },
        ],
      },
      {
        name: "user",
        label: "User's Name",
        placeholder: "User's Name here",
        typeAheadApiType: "user",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["User's Name is required"],
          },
        ],
      },
      {
        name: "pointSpent",
        label: "Points Spent",
        placeholder: "Number of seeds spent for the",
        type: "number",
        validationType: "number",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Seeds Spent is required"],
          },
        ],
      },
      {
        name: "prizeType",
        label: "Prize Type",
        placeholder: "Type of prize acquired",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Prize Type is required"],
          },
        ],
      },
      {
        name: "prize",
        label: "Prize",
        placeholder: "Prize name here",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Prize is required"],
          },
        ],
      },
      {
        name: "status",
        label: "Status",
        placeholder: "Prize status here",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Status is required"],
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    async function fetchData() {
      function prepareUserForm(result) {
        const fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
        fieldsInfoCopy.forEach((fieldObj) => {
          if (result[fieldObj["name"]]) {
            let value = result[fieldObj["name"]];
            if (fieldObj["validationType"] === "number") {
              value = parseInt(value);
            } else {
              if (value.constructor === Array) {
                value = value.map((el) => el.name).join(",");
              } else if (typeof value === "object" && value !== null) {
                value = value.name;
              }
            }
            if (fieldObj["validationType"] === "date") {
              fieldObj["value"] = moment(value).format("YYYY-MM-DD");
            } else {
              fieldObj["value"] = value;
            }
            if (fieldObj["name"] === "user") {
              fieldObj["value"] = result.user.fullName;
            }
          }

          // else if (fieldObj['name'] === 'user') {
          //   fieldObj['value'] = result.user.fullName;
          // }
        });
        const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
        const validateSchema = yup.object().shape(yepSchema);
        setValidateSchema(validateSchema);
        setAcquisition(fieldsInfoCopy);
      }
      let result = await _getAacquisitionDetail(id);
      prepareUserForm(result);
    }
    if (id) {
      fetchData();
    }
  }, [id, fieldsInfo]);

  return (
    <>
      <div className="flex flex-wrap mt-4">
        {id ? (
          <>
            {acquisition ? (
              <div className="w-full bg-white rounded-xl">
                <button
                  onClick={(e) => props.history.goBack()}
                  className="ml-2 mt-3 text-sm flex flex-row"
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    className="w-5 h-5 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 26.676 26.676"
                  >
                    <g>
                      <path
                        d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                      />
                    </g>
                  </svg>
                  <div className="underline px-1">Go back</div>
                </button>
                <DynamicInfoRender
                  fields={acquisition}
                  validation={validateSchema}
                />
              </div>
            ) : (
              <>
                <div className="my-4">
                  <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                    {[...Array(4)].map((e, i) => (
                      <div
                        key={i}
                        className="animate-pulse my-10 flex space-x-4"
                      >
                        <div className="flex-1 space-y-4 py-1">
                          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="w-full mb-12 px-4">
            <div className="flex justify-end bg-white pt-4 pr-4"></div>
            <CardRedeem props={props} color="dark" />
          </div>
        )}
      </div>
    </>
  );
}
