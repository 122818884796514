import React from "react";

export default function NotFound(props) {
  return (
    <>
      <section
        style={{ color: `${props.background === "white" ? "black" : "white"}` }}
        className="body-font"
      >
        <div className="container py-4 mx-auto">
          <div className="flex flex-wrap -my-4">
            <div className="p-6 rounded-lg">
              <img
                className="h-40 sm:h-80 rounded w-full object-cover object-center mb-6"
                src="https://ns-prod-kid.s3.amazonaws.com/assets/not-found.png"
                alt="content"
              />
              <div className="text-center">
                <h2 className="text-lg font-medium title-font mb-4">
                  {props.title}
                </h2>
                <p className="leading-relaxed text-base">{props.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
