import { useParams } from "react-router";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { _getUserNotes } from "../../services/apiService";

export default function UserNotesModal({ open, onClose }) {
  const { id } = useParams();
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    _getUserNotes(id)
      .then((notes) => {
        console.log(notes);
        setNotes(notes);
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [id]);

  return (
    <Dialog fullWidth={true} open={open} onClose={onClose} scroll="paper">
      <DialogTitle>
        <div className="w-full flex">
          <p>User Notes</p>
          <button className="ml-auto cursor-pointer" onClick={onClose}>
            &#x2715;
          </button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="w-full overflow-auto pt-4 flex flex-col gap-2">
          {!isLoading ? (
            notes.length > 0 ? (
              notes.map((note, idx) => {
                return (
                  <div className="w-full p-4 border" key={idx}>
                    <p>By: {note.by.fullName}</p>
                    <p>Note: {note.text}</p>
                  </div>
                );
              })
            ) : (
              <p>No Notes yet..</p>
            )
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
