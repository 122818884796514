import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { _getSubmissions } from "../../services/apiService";

const columns = [
  {
    name: "Status",
    selector: "status",
    sortable: true,
    wrap: true,
  },
  {
    name: "Submission Date",
    selector: "created",
    sortable: true,
    wrap: true,
  },
  {
    name: "Submission For",
    selector: "informationSchema.name",
    sortable: true,
    wrap: true,
  },
  {
    name: "Submission Stat",
    selector: "SubmissionStat",
    wrap: true,
    sortable: true,
  },
  {
    name: "User Name",
    selector: "user.fullName",
    sortable: true,
    wrap: true,
  },
];

function groupSubmissionData(submissions) {
  const groupedData = {};
  submissions.forEach((submission) => {
    const userEmail = submission?.user?.email;
    if (!groupedData[userEmail]) {
      groupedData[userEmail] = [submission];
    } else {
      groupedData[userEmail].push(submission);
    }
  });
  return groupedData;
}

function getMostRecentDataFromGroup(group) {
  const hasModel5 = window.location.href.includes("model-5");
  const data = [];
  Object.values(group).forEach((arr) => {
    const mostRecent = arr.reduce((prev, current) =>
      prev.created > current.created ? prev : current
    );
    data.push(mostRecent);
  });
  const modifiedData = data.map((s) => {
    const submissions = group[s.user.email];
    
    return {
      ...s,
      SubmissionStat: `${submissions.length > 0 ? submissions.length:0} / ${hasModel5 ? 6 : 9}`,
    };
  });

  return modifiedData;
}

export default function CardSubmission(props) {
  const hasModel5 = window.location.href.includes("model-5");
  console.log("hasModel5", hasModel5);
  const history = useHistory();
  const [submissionItems, setSubmissionItems] = useState(null);
  const [submissionItemsFilter, setSubmissionItemsFilter] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let response = await _getSubmissions();
      if(hasModel5){
        response = response.filter((el) => el.informationSchema.for &&
        el.informationSchema.for === "PreQualUser");
      } else {
        response = response.filter((el) => !el.informationSchema.for ||
         el.informationSchema.for !== "PreQualUser");
      }
    
      console.log("response", response);
      const groupedData = groupSubmissionData(response);
      const mostRecentData = getMostRecentDataFromGroup(groupedData);
      setSubmissionItems(mostRecentData);
      setSubmissionItemsFilter(mostRecentData);
    }
    fetchData();
  }, [hasModel5]);

  const handleChange = (val) => {
    if (val) {
      const filter = submissionItems.filter(
        (el) => el.user.fullName.toLowerCase().indexOf(val.toLowerCase()) != -1
      );
      setSubmissionItemsFilter(filter);
    } else {
      setSubmissionItemsFilter(submissionItems);
    }
  };

  function SubmissionItemClicked(e) {
    const url = "/admin/submission/user/" + e.user._id;
    history.push(url);
  }
  return (
    <>
      <div className="my-4">
        <div className="flex bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          <div className="w-1/2">Filter by user</div>
          <div className="w-1/2">
            <input
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              type="text"
              className="w-full sm:w-1/2 px-4 loan"
              placeholder="Enter user name"
              style={{ color: "black" }}
            ></input>
          </div>
        </div>
      </div>
      {submissionItemsFilter ? (
        <DataTable
          className="cursor-pointer"
          title="Submission Panel"
          columns={columns}
          pagination={true}
          paginationPerPage={8}
          data={submissionItemsFilter}
          onRowClicked={(e) => SubmissionItemClicked(e)}
          // customStyles={customStyles}
        />
      ) : (
        <>
          <div className="my-4">
            <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
              {[...Array(4)].map((e, i) => (
                <div className="animate-pulse my-10 flex space-x-4" key={i}>
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                    <div className="space-y-2">
                      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                      <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}
