import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { _getUsers } from "../../services/apiService";
import AssignUserModal from "../Modal/AssignUserModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const columns = [
  {
    name: "Name",
    selector: "fullName",
    sortable: true,
    wrap: true,
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
    wrap: true,
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    wrap: true,
  },
  {
    name: "Created Date",
    selector: "created",
    sortable: true,
    wrap: true,
  },
];

export default function CardUser(props) {
  const history = useHistory();
  const [users, setUsers] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [isAssignUserModalShows, setIsAssignUserModalShows] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const result = await _getUsers();
      setUsers(result);
    }

    fetchData();
  }, []);

  function onRowClick(e) {
    history.push(`/admin/users/${e._id}`);
  }

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = React.useMemo(() => {
    return (
      <button
        onClick={() => setIsAssignUserModalShows(true)}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Assign Users
      </button>
    );
  }, [setIsAssignUserModalShows]);

  return users ? (
    <>
      {isAssignUserModalShows && (
        <AssignUserModal
          selectedRows={selectedRows}
          closeModal={() => {
            setIsAssignUserModalShows(false);
            setClearSelectedRows(true);
          }}
          notifySuccess={() => {
            toast.success("Users assigned successfully", {
              position: toast.POSITION.TOP_CENTER,
            });
          }}
          notifyError={() => {
            toast.error("Oops! Something Went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
          }}
        />
      )}
      <DataTable
        data={users.filter((user) => {
          if (props.userFilter) {
            return (
              user.email.includes(props.userFilter) ||
              user.fullName.includes(props.userFilter)
            );
          } else {
            return true;
          }
        })}
        title="Users"
        columns={columns}
        pagination={true}
        paginationPerPage={8}
        className="cursor-pointer"
        onRowClicked={(e) => onRowClick(e)}
        selectableRows
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={clearSelectedRows}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  ) : (
    <>
      <div className="my-4">
        <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          {[...Array(4)].map((e, i) => (
            <div key={i} className="animate-pulse my-10 flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                <div className="space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
