import React from "react";
import CardLoanApplications from "../../components/Cards/CardLoanApplications";
import CardLoanApplicationDetailedPartner from "../../components/Cards/CardLoanApplicationsDetailedPartner";
import { useParams } from "react-router-dom";

export default function LoanApplications(props) {
  let { id } = useParams();
  return (
    <>
      {id ? (
        <div>
          <CardLoanApplicationDetailedPartner />
        </div>
      ) : (
        <div>
          <CardLoanApplications props={props} />
        </div>
      )}
    </>
  );
}
