import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { _getUserInformations } from "../../services/apiService";

function LoanServices() {
  const [verified, setVerified] = useState([]);

  useEffect(() => {
    _getUserInformations()
      .then((res) => {
        setVerified(res.Verified);
      })
      .catch((err) => {});
  }, []);
  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 lg:mt-12 mx-auto">
          <div className="flex flex-col lg:flex-row text-center">
            <div className="m-2 p-4 lg:w-1/2 sm:px-2  sm:p-6 sm:m-5 bg-white rounded-xl">
              <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">
              Your Offers
              </h2>
              <p className="leading-relaxed text-base px-4 lg:pb-6 sm:px-8">
              Loans personalized for your needs and lifestyle
              </p>
              <Link to="loans">
                <button className="flex mx-auto mt-6 text-white bg-theme-main border-0 py-2 px-5 focus:outline-none hover:bg-theme-main-hover rounded">
                  Check offers
                </button>
              </Link>
            </div>
            <div className="m-2 p-4 lg:w-1/2  sm:px-0 sm:py-6 sm:m-5 bg-white rounded-xl">
              <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">
                Your Applications
              </h2>
              <p className="leading-relaxed text-base px-4 sm:px-8">
                Check the details and list of your loan applications. In case,
                you have applied for loans, you will find them here.
              </p>
              <Link to="loan-application">
                <button className="flex mx-auto mt-6 text-white bg-theme-main border-0 py-2 px-5 focus:outline-none hover:bg-theme-main-hover rounded">
                  Applied loans here
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LoanServices;
