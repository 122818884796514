import React, { useEffect, useState } from "react";
import {
  _getDashboardInfo,
  _getInformations,
  _joinContest,
  _getTree,
  _getUserInformations,
} from "../../services/apiService";
import TaskCard from "../../components/Card/taskCard";
import moment from "moment";
import Confetti from "react-dom-confetti";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { dashboardSvg } from "../../assets/svgs/svg";
import TreeAnimation from "./TreeAnimation";

function OverviewComponent1(props) {
  const [response, setResponse] = useState(null);
  const [informationData, setInformationData] = useState(null);
  const [isActive, setisActive] = useState(false);
  const [userData, setUserData] = useState(null);
  const [treeInfo, settreeInfo] = useState(null);
  const [percentComplete, setpercentComplete] = useState(null);
  const [pointInPercent, setPointInPercent] = useState(0);
  const [profileStatus, setProfileStatus] = useState({
    verification: false,
    submission: false,
    profileComplete: true,
    DocumentForVerification: true,
    loanServices: false,
    rewards: false,
    financialTips: true,
  });

  async function joinContest(contest) {
    const result = await _joinContest(contest._id);
    if (result.ok) {
      // show success and error
      contest.isJoined = true;
      setisActive(true);
      toast.success("Contest has been joined", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }

  const config = {
    angle: 90,
    spread: 180,
    startVelocity: 40,
    elementCount: 360,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "700px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  useEffect(() => {
    async function fetchInformations() {
      let response = await _getUserInformations();
      const statuses = ["Submitted", "SubmittedForVerfication", "Verified"];
      const result = [];
      statuses.forEach((status) => {
        if (response[status]) {
          response[status].forEach((record) => {
            result.push({
              name: record.informationSchema.name,
              status: status,
            });
          });
        }
      });
      setInformationData(result);
      if (result["Verified"]) {
        const percent = (result["Verified"].length / 9) * 100;
        setpercentComplete(percent);
      } else {
        setpercentComplete(0);
      }
      const image = await _getTree();
      settreeInfo(image);
    }
    async function fetchData() {
      // You can await here
      let result = await _getDashboardInfo();

      let information = await _getInformations();
      const isVerified =
        information.filter((el) => el.userStatus === "Verified").length == 9;
      const isSubmitted =
        information.filter((el) => el.userStatus === "Submitted").length == 9;
      setProfileStatus({
        verification: isVerified,
        submission: isSubmitted,
        profileComplete: true,
        DocumentForVerification: true,
        loanServices: false,
        rewards: false,
        financialTips: true,
      });

      console.log("information:", information);
      setResponse(result);
      let totalLeftPoint = result.points;
      let totalPointsAval = 0;
      information.map((item) => {
        totalPointsAval = totalPointsAval + item.points;
        return totalPointsAval;
      });
      let pointInPercent = (totalLeftPoint * 100) / totalPointsAval;
      setPointInPercent(pointInPercent);
      // ...
      const cookies = new Cookies();
      const cookieInfo = cookies.get("kidUser");
      setUserData(cookieInfo);
      console.log("userData:", userData);
    }
    fetchInformations();
    fetchData();
  }, []);

  return (
    <>
      <Confetti active={isActive} config={config} />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="pt-8 md:pt-2">
        <div className="w-full md:w-1/4">
          <h2 style={{ color: "#FBFA58" }} className=" text-xl font-bold">
            {userData && userData.fullName
              ? userData.fullName + "'s Dashboard"
              : "My dashboard"}
          </h2>

          <div
            className="flex flex-col-reverse h-full md:col-span-1"
            style={{
              background: `rgba(255, 255, 255, 0.09)`,
              // border: `1px solid #B8FFE4`,
              boxShadow: `0px 0px 30px rgba(0, 0, 0, 0.04)`,
              backdropFilter: "blur(28px)",
              borderRadius: "20px",
            }}
          >
            {/* <div className="flex justify-center  px-4 md:px-0">
              {treeInfo ? (
                <img
                  alt="..."
                  src={require(`../../assets/img/${treeInfo}`).default}
                />
              ) : (
                ""
              )}
            </div> */}
          </div>
        </div>
        <h2 className="text-white text-base font-bold">
          {!profileStatus.verification
            ? "Complete your Profile to get a loan offer"
            : "Congratulations, all documents have been submitted, we are reviewing your application now to find the best product for you. You will be updated via email or phone."}
        </h2>

        <div className="mt-6">
          <div className="flex flex-col">
            <h1 className="font-medium text-white">Check Loan Offers:</h1>
            {/* <div className="mt-4 flex flex-col md:flex-row w-full justify-center items-center text-center">
                    <div className="my-2 w-full md:w-1/3 p-4 mr-2" style={{
                        background: '#1CE783',
                        backdropFilter: 'blur(23px)',
                        borderRadius: '15px'
                    }}>
                        <p className="font-bold">Overall Points</p>
                        <p className="text-white text-4xl">{response ? response.points : ''}</p>
                    </div>
                    <div className="my-2 w-full md:w-1/3 p-4 mr-2" style={{
                        background: '#1CE783',
                        backdropFilter: 'blur(23px)',
                        borderRadius: '15px'
                    }}>
                        <p className="font-bold">Points Redeemed</p>
                        <p className="text-white text-4xl">{response ? response.redeemPoints : ''}</p>
                    </div>
                    <div className="my-2 w-full md:w-1/3 p-4" style={{
                        background: '#1CE783',
                        backdropFilter: 'blur(23px)',
                        borderRadius: '15px'
                    }}>
                        <p className="font-bold">Categories</p>
                        <p className="text-white text-4xl">{response ? 6 : ''}</p>
                    </div>
                  </div>
                  <div className="pt-6 flex flex-row" >
                  <h1 className="w-1/2 font-bold">Categories</h1>
                  
                  </div> */}
            <div className="flex flex-row flex-wrap">
              {/* <div
              onClick={() => props.props.history.push("/profile")}
                className="w-full md:w-1/4 p-4 mr-4 my-2 h-36 flex flex-col justify-between"
                style={{
                  background: `rgba(255, 255, 255, 0.15)`,
                  // border: `2px solid #637484`,
                  backdropFilter: "blur(23px)",
                  borderRadius: "10px",
                }}
              >
                <p className="font-semibold text-white">
                  Complete <br /> My profile
                </p>
                <div className="flex justify-between">
                  <div>
                    <span className="text-gray-200 font-semibold text-sm">
                      Current Status
                    </span>
                    <span className="ml-1 text-gray-200 font-semibold text-xs">
                      ({percentComplete}%)
                    </span>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-0.5">
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill={`${
                            profileStatus.profileComplete
                              ? "#32ed83"
                              : "#f63232"
                          }`}
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div> */}
              <div
                onClick={() => props.props.history.push("/earn-points")}
                className="w-full md:w-1/4 p-4 mr-4 my-2 h-36 basis-1/4 flex flex-col justify-between cursor-pointer"
                style={{
                  background: `rgba(255, 255, 255, 0.15)`,
                  // border: `2px solid #637484`,
                  backdropFilter: "blur(23px)",
                  borderRadius: "10px",
                }}
              >
                <p className="font-semibold text-white">My Profile</p>
                {profileStatus.submission ? (
                  <p className="text-xs text-white">
                    Complete your profile for a loan offer
                  </p>
                ) : (
                  ""
                )}
                <div className="flex justify-between">
                  <div>
                    <span className="text-gray-200 font-semibold text-sm">
                      Current Status
                    </span>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-0.5">
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill={`${
                            profileStatus.DocumentForVerification
                              ? "#32ed83"
                              : "#f63232"
                          }`}
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div
                onClick={() => props.props.history.push("/loan-services")}
                className="w-full md:w-1/4 p-4 mr-4 my-2 h-36 basis-1/4 flex flex-col justify-between cursor-pointer"
                style={{
                  background: `rgba(255, 255, 255, 0.15)`,
                  // border: `2px solid #637484`,
                  backdropFilter: "blur(23px)",
                  borderRadius: "10px",
                }}
              >
                <p className="font-semibold text-white">My Loans</p>
                <div className="flex justify-between">
                  <div>
                    <span className="text-gray-200 font-semibold text-sm">
                      Current Status
                    </span>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-0.5">
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill={`${
                            profileStatus.loanServices ? "#32ed83" : "#f63232"
                          }`}
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>

              <div
                onClick={() => props.props.history.push("/redeem")}
                className="w-full md:w-1/4 p-4 mr-4 my-2 h-36 basis-1/4 flex flex-col justify-between cursor-pointer"
                style={{
                  background: `rgba(255, 255, 255, 0.15)`,
                  // border: `2px solid #637484`,
                  backdropFilter: "blur(23px)",
                  borderRadius: "10px",
                }}
              >
                <p className="font-semibold text-white">My Rewards</p>
                <div className="flex justify-between">
                  <div>
                    <span className="text-gray-200 font-semibold text-sm">
                      Current Status
                    </span>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-0.5">
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill={`${
                            profileStatus.loanServices ? "#32ed83" : "#f63232"
                          }`}
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>

              <Link
                className="w-full md:w-1/4 p-4 mr-4 my-2 h-36 basis-1/4 flex flex-col justify-between cursor-pointer"
                style={{
                  background: `rgba(255, 255, 255, 0.15)`,
                  // border: `2px solid #637484`,
                  backdropFilter: "blur(23px)",
                  borderRadius: "10px",
                }}
                to="financial-tip"
              >
                <p className="font-semibold text-white">Financial Tips</p>
                <div className="flex justify-between">
                  <div>
                    <span className="text-gray-200 font-semibold text-sm">
                      Current Status
                    </span>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-0.5">
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill={`${
                            profileStatus.financialTips ? "#32ed83" : "#f63232"
                          }`}
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </Link>
              <div
                onClick={() => props.props.history.push("/redeem")}
                className="w-full md:w-1/4 p-4 mr-4 my-2 h-36 basis-1/4 flex flex-col justify-between cursor-pointer"
                style={{
                  background: `rgba(255, 255, 255, 0.15)`,
                  // border: `2px solid #637484`,
                  backdropFilter: "blur(23px)",
                  borderRadius: "10px",
                }}
              >
                <p className="font-semibold text-white">My Points</p>
                <div className="flex justify-between">
                  <div>
                    <span className="text-gray-200 font-semibold text-2xl">
                      {response && response.points}
                    </span>
                  </div>
                  {/* <div className="flex flex-col justify-center items-center mt-0.5">
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill={`${
                            profileStatus.loanServices ? "#32ed83" : "#f63232"
                          }`}
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
                        />
                      </g>
                    </svg>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex mt-8 text-white">
          <h1 className="font-medium">Verification Status</h1>
          {profileStatus.verification ? (
            <div className="flex pl-3 space-x-1 pt-1">
              <svg width="24px" height="24px" viewBox="0 0 24 24">
                <g>
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="#32ed83"
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
                  />
                </g>
              </svg>
              <div>Verified</div>
            </div>
          ) : (
            <div className="flex pl-2 space-x-1 pt-1">
              <svg width="24px" height="24px" viewBox="0 0 24 24">
                <g>
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="#f63232"
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
                  />
                </g>
              </svg>
              <div>Not Verified</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default OverviewComponent1;
