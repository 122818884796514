// const BASE_URL = "https://seedja.com"
// const BASE_URL = "http://localhost:8090";
const BASE_URL = "https://test.seedja.com";
// process.env.REACT_APP_ENV === "prod"
//   ? "https://seedja.com"
//   : "http://localhost:8090";
const ACCOUNTS_BASE_ROUTE = "/api/accounts";
const CONTESTS_BASE_ROUTE = "/api/contests";
const PRIZES_BASE_ROUTE = "/api/prizes";
const INFORMATION_BASE_ROUTE = "/api/informations";
const VERIFICATION_BASE_ROUTE = "/api/verification";
const ACTIVITY_BASE_ROUTE = "/api/activity";
const USER_QUIZ_BASE_ROUTE = "/api/quiz";
const ACQUISITIONS_BASE_ROUTE = "/api/acquisitions";
const NOTIFICATIONS_BASE_ROUTE = "/api/activity/notification";
const PARTNER_BASE_ROUTE = "/api/partner";
const ADMIN_PARTNER_BASE_ROUTE = "/api/partners";
const PRODUCT_BASE_ROUTE = "/api/product";
const LOAN_BASE_ROUTE = "/api/loan";
const LOAN_PUBLIC_BASE_ROUTE = "/api/loan/public";
const LOAN_APPLICATION_BASE_ROUTE = "/api/loan-application";
const LOAN_DECISION_BASE_ROUTE = "/api/loan/user/decision";
const LOAN_APPLY_BASE_ROUTE = "/api/loan/user/apply";
const MESSAGES_BASE_ROUTE = "/api/messages";
const NOTES_BASE_ROUTE = "/api/notes";
const EXPORT_BASE_ROUTE = "/api/export";

export const _getCsrf = async () => {
  const informationsUrl = BASE_URL + "/api/form";
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _signUp = async (_data) => {
  const csrf = await _getCsrf();
  const signUpUrl = BASE_URL + ACCOUNTS_BASE_ROUTE + "/register";
  let response = await fetch(signUpUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _getLandingCopy = async () => {
  const informationsUrl = BASE_URL + INFORMATION_BASE_ROUTE + "/landingCopy";
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _editLandingCopy = async (_data) => {
  const csrf = await _getCsrf();
  const informationsUrl =
    BASE_URL + INFORMATION_BASE_ROUTE + "/editLandingCopy";
  let response = await fetch(informationsUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _requestPasswordResetLink = async (_data) => {
  const csrf = await _getCsrf();
  const passwordResetUrl =
    BASE_URL + ACCOUNTS_BASE_ROUTE + "/requestChangePasswordLink";
  let response = await fetch(passwordResetUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _resetPassword = async (_data) => {
  const csrf = await _getCsrf();
  const passwordResetUrl = BASE_URL + ACCOUNTS_BASE_ROUTE + "/changePassword";
  let response = await fetch(passwordResetUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _updatePassword = async (_data) => {
  const csrf = await _getCsrf();
  const passwordUpdateURL = BASE_URL + ACCOUNTS_BASE_ROUTE + "/updatePassword";
  let response = await fetch(passwordUpdateURL, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _submitUserQuiz = async (_data) => {
  const csrf = await _getCsrf();
  const signUpUrl = BASE_URL + USER_QUIZ_BASE_ROUTE;
  let response = await fetch(signUpUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _getUserQuiz = async (id) => {
  const quizUrl = BASE_URL + USER_QUIZ_BASE_ROUTE + "/" + id;
  let response = await fetch(quizUrl, {
    method: "GET",
    credentials: "include",
    headers: { "Content-type": "application/json; charset=UTF-8" },
  });
  return await response;
};

export const _login = async (_data) => {
  const csrf = await _getCsrf();
  const loginUrl = BASE_URL + ACCOUNTS_BASE_ROUTE + "/login";
  let response = await fetch(loginUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _getContests = async () => {
  const contestsUrl = BASE_URL + CONTESTS_BASE_ROUTE;
  let response = await fetch(contestsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getContestsUser = async () => {
  const contestsUrl = BASE_URL + CONTESTS_BASE_ROUTE + "/user";
  let response = await fetch(contestsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getAdminContests = async () => {
  const contestsUrl = BASE_URL + CONTESTS_BASE_ROUTE;
  let response = await fetch(contestsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};
export const _getOneSubmittedInformation = async (sId) => {
  const informationsUrl =
    BASE_URL + INFORMATION_BASE_ROUTE + "/submitted" + "/" + sId;
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getInformations = async () => {
  const informationsUrl = BASE_URL + INFORMATION_BASE_ROUTE;
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getDashboardStatsInfo = async () => {
  const informationsUrl = BASE_URL + INFORMATION_BASE_ROUTE + "/dashboardStats";
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getDashboardInfo = async () => {
  const informationsUrl = BASE_URL + INFORMATION_BASE_ROUTE + "/dashboard";
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getSubmittedInformation = async (id) => {
  const informationsUrl =
    BASE_URL + INFORMATION_BASE_ROUTE + "/verifications/submitted/" + id;
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getSubmittedInformationByUserId = async (userId) => {
  const informationsUrl =
    BASE_URL +
    INFORMATION_BASE_ROUTE +
    "/verifications/submitted/user/" +
    userId;
  let response = await fetch(informationsUrl, {
    method: "GET",
    credentials: "include",
  });
  return await response.json();
};

export const _getSubmissonsByUserId = async (userId) => {
  const informationsUrl =
    BASE_URL + INFORMATION_BASE_ROUTE + "/submitted/user/" + userId;
  let response = await fetch(informationsUrl, {
    method: "GET",
    credentials: "include",
  });
  return await response.json();
};

export const _getSubmittedInformations = async () => {
  const informationsUrl =
    BASE_URL + INFORMATION_BASE_ROUTE + "/verifications/submitted";
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _sendEmail = async (_data) => {
  const csrf = await _getCsrf();
  const emailUrl = BASE_URL + INFORMATION_BASE_ROUTE + "/emailUser";
  let response = await fetch(emailUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _userProfileUpdate = async (_data) => {
  const csrf = await _getCsrf();
  const informationsUrl = BASE_URL + ACCOUNTS_BASE_ROUTE + "/image/update";
  const fd = new FormData();
  fd.append("file", _data);

  let response = await fetch(informationsUrl, {
    method: "PUT",
    credentials: "include",
    headers: {
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
    body: fd,
  });
  return await response.json();
};

export const _submitInformationMultipart = async (_data, extraInfo) => {
  const csrf = await _getCsrf();
  const informationsUrl = BASE_URL + INFORMATION_BASE_ROUTE + "/submitMulti";
  const fd = new FormData();
  Object.keys(_data).forEach((data) => {
    fd.append(data, _data[data]);
  });
  fd.append("extraInfo", JSON.stringify(extraInfo));

  let response = await fetch(informationsUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
    body: fd,
  });
  return response;
};

export const _submitInformation = async (_data) => {
  const csrf = await _getCsrf();
  const informationsUrl = BASE_URL + INFORMATION_BASE_ROUTE + "/submit";
  let response = await fetch(informationsUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _editInformation = async (id, body) => {
  const csrf = await _getCsrf();
  const informationsUrl = BASE_URL + INFORMATION_BASE_ROUTE + "/" + id;
  let response = await fetch(informationsUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _editVerification = async (id, body) => {
  const csrf = await _getCsrf();
  const informationsUrl =
    BASE_URL + INFORMATION_BASE_ROUTE + "/verification/" + id;
  let response = await fetch(informationsUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _getOneInformation = async (id) => {
  const informationsUrl = BASE_URL + INFORMATION_BASE_ROUTE + "/" + id;
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _submitContest = async (_data) => {
  const csrf = await _getCsrf();
  const contestUrl = BASE_URL + CONTESTS_BASE_ROUTE;
  let response = await fetch(contestUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _submitPrize = async (_data) => {
  const csrf = await _getCsrf();
  const prizetUrl = BASE_URL + PRIZES_BASE_ROUTE;
  const fd = new FormData();
  Object.keys(_data).forEach((data) => {
    fd.append(data, _data[data]);
  });
  // fd.append("extraInfo", JSON.stringify(extraInfo));

  let response = await fetch(prizetUrl, {
    method: "POST",
    credentials: "include",
    body: fd,
    headers: {
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _getOneSubmission = async (id) => {
  const informationsUrl =
    BASE_URL + INFORMATION_BASE_ROUTE + "/submitted/" + id;
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getSubmissions = async () => {
  const informationsUrl = BASE_URL + INFORMATION_BASE_ROUTE + "/submitted";
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getUserInformations = async () => {
  const informationsUrl = BASE_URL + INFORMATION_BASE_ROUTE + "/user";
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getTree = async () => {
  let result;
  const data = await _getUserInformations();
  let count = 0;
  //const statuses = ['Submitted','SubmittedForVerfication','Verified'];

  if (data["Verified"] && data["Verified"].length == 6) {
    result = "seed10.png";
  } else {
    count = count + data["Submitted"] ? data["Submitted"].length * 0.25 : 0;
    count =
      count + data["SubmittedForVerfication"]
        ? data["SubmittedForVerfication"].length * 0.5
        : 0;
    count = count + data["Verified"] ? data["Verified"].length * 0.75 : 0;
    count = Math.floor(count);
  }

  if (count > 10) {
    result = "seed10.png";
  }
  if (count === 0) {
    result = "seed1.png";
  } else {
    result = `seed${count}.png`;
  }
  console.log(result);
  return result;
};

export const _logOut = async (_data, extraInfo) => {
  const csrf = await _getCsrf();
  const logOutUrl = BASE_URL + ACCOUNTS_BASE_ROUTE + "/logout";
  let response = await fetch(logOutUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
    body: {},
  });
  return response;
};

export const _verifyInformation = async (_data, extraInfo) => {
  const csrf = await _getCsrf();
  const informationsUrl = BASE_URL + VERIFICATION_BASE_ROUTE + "/submit";
  const fd = new FormData();
  Object.keys(_data).forEach((data) => {
    fd.append(data, _data[data]);
  });
  fd.append("extraInfo", JSON.stringify(extraInfo));

  let response = await fetch(informationsUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
    body: fd,
  });
  return response;
};

export const _approveLoan = async (id, body) => {
  const csrf = await _getCsrf();
  const loanUrl = BASE_URL + LOAN_APPLICATION_BASE_ROUTE + "/admin/" + id + '/approve';
  let response = await fetch(loanUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _rejectLoan = async (id, body) => {
  const csrf = await _getCsrf();
  const loanUrl = BASE_URL + LOAN_APPLICATION_BASE_ROUTE + "/admin/" + id + '/reject';
  let response = await fetch(loanUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _approveDocument = async (id, body) => {
  const csrf = await _getCsrf();
  const informationsUrl = BASE_URL + VERIFICATION_BASE_ROUTE + "/approve/" + id;
  let response = await fetch(informationsUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _rejectDocument = async (id, body) => {
  const csrf = await _getCsrf();
  const informationsUrl = BASE_URL + VERIFICATION_BASE_ROUTE + "/reject/" + id;
  let response = await fetch(informationsUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _getPrizes = async (type = "") => {
  let prizesUrl = BASE_URL + PRIZES_BASE_ROUTE;
  if (type) {
    prizesUrl = prizesUrl + "?type=" + type;
  }
  let response = await fetch(prizesUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getNextPrizeInformation = async () => {
  const prizesUrl = BASE_URL + PRIZES_BASE_ROUTE + "/next";
  let response = await fetch(prizesUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _postPrizes = async (_data) => {
  const csrf = await _getCsrf();
  const prizesUrl = BASE_URL + PRIZES_BASE_ROUTE;
  let response = await fetch(prizesUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return await response.json();
};

export const _deletePrize = async (id) => {
  const csrf = await _getCsrf();
  const prizesUrl = BASE_URL + PRIZES_BASE_ROUTE + "/remove/" + id;
  let response = await fetch(prizesUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return await response;
};

export const _joinContest = async (id) => {
  const csrf = await _getCsrf();
  const contestsUrl = BASE_URL + CONTESTS_BASE_ROUTE + "/join/" + id;
  let response = await fetch(contestsUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return await response;
};

export const _postContests = async (_data) => {
  const csrf = await _getCsrf();
  const contestsUrl = BASE_URL + CONTESTS_BASE_ROUTE;
  let response = await fetch(contestsUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return await response.json();
};

export const _getUsers = async () => {
  const contestsUrl = BASE_URL + ACCOUNTS_BASE_ROUTE;
  let response = await fetch(contestsUrl, {
    method: "GET",
    credentials: "include",
  });
  return await response.json();
};

export const _getModeratorsAndAdmins = async () => {
  const url = BASE_URL + ACCOUNTS_BASE_ROUTE + "/moderatorsAndAdmins";
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });
  return await response.json();
};

export const _getPartners = async () => {
  const adminpartnerUrl = BASE_URL + ADMIN_PARTNER_BASE_ROUTE;
  let response = await fetch(adminpartnerUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getPrize = async (id) => {
  const prizesUrl = BASE_URL + PRIZES_BASE_ROUTE + "/" + id;
  let response = await fetch(prizesUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getActivity = async () => {
  const activityUrl = BASE_URL + ACTIVITY_BASE_ROUTE;
  let response = await fetch(activityUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _redeemPrize = async (id) => {
  const csrf = await _getCsrf();
  const redeemUrl = BASE_URL + PRIZES_BASE_ROUTE + "/redeem/" + id;
  let response = await fetch(redeemUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return await response;
};

export const _getContest = async (id) => {
  const prizesUrl = BASE_URL + CONTESTS_BASE_ROUTE + "/" + id;
  let response = await fetch(prizesUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getUserInfo = async () => {
  const userUrl = BASE_URL + ACCOUNTS_BASE_ROUTE + "/profile/";
  let response = await fetch(userUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getUserFullInfo = async (id) => {
  const userUrl = BASE_URL + ACCOUNTS_BASE_ROUTE + "/full-profile/" + id;
  let response = await fetch(userUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getPartnersFullInfo = async (id) => {
  const PartnerUrl = BASE_URL + PARTNER_BASE_ROUTE + "/" + id;
  let response = await fetch(PartnerUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getAacquisitions = async () => {
  const acquisitionUrl = BASE_URL + ACQUISITIONS_BASE_ROUTE;
  let response = await fetch(acquisitionUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getAacquisitionDetail = async (id) => {
  const acquisitionUrl = BASE_URL + ACQUISITIONS_BASE_ROUTE + "/" + id;
  let response = await fetch(acquisitionUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getNotifications = async () => {
  const notificationUrl = BASE_URL + NOTIFICATIONS_BASE_ROUTE;
  let response = await fetch(notificationUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getPartner = async () => {
  const partnerUrl = BASE_URL + PARTNER_BASE_ROUTE;
  let response = await fetch(partnerUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _submitPartner = async (_data) => {
  const csrf = await _getCsrf();
  const partnerUrl = BASE_URL + PARTNER_BASE_ROUTE;
  const fd = new FormData();
  Object.keys(_data).forEach((data) => {
    fd.append(data, _data[data]);
  });

  let response = await fetch(partnerUrl, {
    method: "POST",
    credentials: "include",
    body: fd,
    headers: {
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _getProducts = async () => {
  const productUrl = BASE_URL + PRODUCT_BASE_ROUTE;
  let response = await fetch(productUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};
export const _getLoans = async () => {
  const loanUrl = BASE_URL + LOAN_BASE_ROUTE;
  let response = await fetch(loanUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _updateLoanStatus = async (id, _data) => {
  const csrf = await _getCsrf();
  const loanUrl =
    BASE_URL + LOAN_APPLICATION_BASE_ROUTE + "/updateStatus/" + id;
  let response = await fetch(loanUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return await response;
};

export const _submitProduct = async (_data) => {
  const csrf = await _getCsrf();
  const productUrl = BASE_URL + PRODUCT_BASE_ROUTE;
  const fd = new FormData();
  Object.keys(_data).forEach((data) => {
    fd.append(data, _data[data]);
  });

  let response = await fetch(productUrl, {
    method: "POST",
    credentials: "include",
    body: fd,
    headers: {
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _submitLoan = async (_data) => {
  const csrf = await _getCsrf();
  const loanUrl = BASE_URL + LOAN_BASE_ROUTE;
  const fd = new FormData();
  Object.keys(_data).forEach((data) => {
    fd.append(data, _data[data]);
  });

  let response = await fetch(loanUrl, {
    method: "POST",
    credentials: "include",
    body: fd,
    headers: {
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _getLoan = async (id) => {
  const loanUrl = BASE_URL + LOAN_BASE_ROUTE + "/" + id;
  let response = await fetch(loanUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getProduct = async (id) => {
  const productUrl = BASE_URL + PRODUCT_BASE_ROUTE + "/" + id;
  let response = await fetch(productUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getPublicLoans = async () => {
  const loanUrl = BASE_URL + LOAN_PUBLIC_BASE_ROUTE;
  let response = await fetch(loanUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _applyLoan = async (_data) => {
  const csrf = await _getCsrf();
  const loanApplicationURL = BASE_URL + LOAN_APPLICATION_BASE_ROUTE;
  let response = await fetch(loanApplicationURL, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(_data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "CSRF-Token": csrf.csrfToken, // <-- is the csrf token as a header
    },
  });
  return response;
};

export const _getOneLoan = async id => {
  const loanUrl = BASE_URL + LOAN_APPLICATION_BASE_ROUTE + "/admin/" + id;
  let response = await fetch(loanUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getLoanApplications = async () => {
  const loanUrl = BASE_URL + LOAN_APPLICATION_BASE_ROUTE + "/admin";
  let response = await fetch(loanUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getLoanApplicationsUser = async () => {
  const loanUrl = BASE_URL + LOAN_APPLICATION_BASE_ROUTE + "/user";
  let response = await fetch(loanUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getLoanApplicationsPartner = async () => {
  const loanUrl = BASE_URL + LOAN_APPLICATION_BASE_ROUTE + "/partner";
  let response = await fetch(loanUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getLoanApplicationsDetailsPartner = async (id) => {
  const loanUrl = BASE_URL + LOAN_APPLICATION_BASE_ROUTE + "/" + id;
  let response = await fetch(loanUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getPartnersLoan = async (id) => {
  const informationsUrl = BASE_URL + LOAN_PUBLIC_BASE_ROUTE + "/" + id;
  let response = await fetch(informationsUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _getUserLoanDecision = async loanType => {
  const loanDecisionUrl = BASE_URL + LOAN_DECISION_BASE_ROUTE + `?loanType=${loanType}`;
  let response = await fetch(loanDecisionUrl, {
    credentials: "include",
    method: "GET",
  });
  return await response.json();
};

export const _applyLoanDecision = async (loanData, loanType) => {
  const csrf = await _getCsrf();
  const loanDecisionUrl = BASE_URL + LOAN_APPLY_BASE_ROUTE + `?loanType=${loanType}`;
  let response = await fetch(loanDecisionUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(loanData),
    headers: {
      "CSRF-Token": csrf.csrfToken,
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  return response;
};

export const _assignUsers = async (payload) => {
  const csrf = await _getCsrf();
  const url = BASE_URL + ACCOUNTS_BASE_ROUTE + "/assignUsers";
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(payload),
    headers: {
      "CSRF-Token": csrf.csrfToken,
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  return response;
};

export const _getMessages = async (userId) => {
  const url = `${BASE_URL}${MESSAGES_BASE_ROUTE}/${userId}`;
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });
  return await response.json();
};

export const _getMessagedUserList = async () => {
  const url = `${BASE_URL}${MESSAGES_BASE_ROUTE}/user/list`;
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });
  return await response.json();
};

export const _sendMessage = async (payload) => {
  const csrf = await _getCsrf();
  const url = `${BASE_URL}${MESSAGES_BASE_ROUTE}/send`;
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(payload),
    headers: {
      "CSRF-Token": csrf.csrfToken,
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  return response;
};

export const _getUserNotes = async (userId) => {
  const url = `${BASE_URL}${NOTES_BASE_ROUTE}/${userId}`;
  console.log(url);
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });
  return await response.json();
};

export const _deleteAccount = async () => {
  const csrf = await _getCsrf();
  const url = `${BASE_URL}${ACCOUNTS_BASE_ROUTE}/delete`;
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "CSRF-Token": csrf.csrfToken,
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  return response;
};

export const _exportData = async (model) => {
  const url = `${BASE_URL}${EXPORT_BASE_ROUTE}/${model}`;
  window.open(url, "_blank");
};
