import React from "react";
import Notify from "../../components/Notification/Notification";

function Activity() {
    return(
        <div>
            <Notify />
        </div>
    );
}

export default Activity;