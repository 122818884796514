import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { _getAdminContests } from "../../services/apiService";
import moment from "moment";

const columns = [
  {
    name: "Title",
    selector: "name",
    sortable: true,
    wrap: true,
  },
  {
    name: "Category",
    selector: "category",
    sortable: true,
    wrap: true,
  },
  {
    name: "Start Date",
    selector: "startDate",
    sortable: true,
    wrap: true,
  },
  {
    name: "End Date",
    selector: "endDate",
    sortable: true,
    wrap: true,
  },
  {
    name: "Participants",
    selector: "participants",
    sortable: true,
    wrap: true,
  },
];

export default function CardContests(props) {
  const [contests, setContests] = useState(null);

  function contestClicked(e) {
    const history = props.props.history;
    const url = "/admin/contests/" + e._id;
    history.push(url);
  }
  useEffect(() => {
    async function fetchData() {
      // You can await here
      let result = await _getAdminContests();
      result.forEach((contest, i) => {
        contest.startDate = moment(contest.startDate).format(
          "h:mm a, MMMM Do YYYY"
        );
        contest.endDate = moment(contest.endDate).format(
          "h:mm a, MMMM Do YYYY"
        );
      });
      setContests(result);
      // setFilteredItems(result)
      // ...
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  return contests ? (
    <DataTable
      className="cursor-pointer"
      title="Contests"
      columns={columns}
      pagination={true}
      paginationPerPage={8}
      data={contests}
      onRowClicked={(e) => contestClicked(e)}
    />
  ) : (
    <>
      <div className="my-4">
        <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          {[...Array(4)].map((e, i) => (
            <div key={i} className="animate-pulse my-10 flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                <div className="space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
