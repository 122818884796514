import React, { useState } from "react";
import NavBarLanding from "../../components/NavBarLanding/index";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../components/Footer/index";
import { _signUp } from "../../services/apiService";
import { Link } from "react-router-dom";
import DynamicModal from "../../components/Modal/DynamicModal";
import ExternalPasswordStrengthBar from "react-password-strength-bar";
import TextInput from "../../components/TextInput";

const PasswordStrengthBar = () => {
  const [field] = useField({ name: "password" });
  return <ExternalPasswordStrengthBar password={field.value} />;
};

function Signup() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const notify = (isSuccess, message) => {
    if (isSuccess) {
      setIsDisabled(false);
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setIsDisabled(false);
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const modalElements = {
    modalTitle: "Congrats!",
    modalBody:
      "Congrats, you are eligible for a grant from Seed Jamaica. Please complete the full loan process/application and recieve upto 20% off on your loan offer as a grant.",
  };

  return (
    <div>
      <DynamicModal
        modalTitle={modalElements.modalTitle}
        modalBody={modalElements.modalBody}
        showModal={showModal}
        setShowModal={setShowModal}
        size="max-w-md"
      />
      <div
        className="relative overflow-hidden min-h-screen"
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <div className="relative">
          <div
            className="border-b-1 shadow-lg border-gray-600"
            style={{ backgroundColor: "white" }}
          >
            <NavBarLanding pageType="signup" />
          </div>
          <main className="mt-16 sm:mt-24">
            <div className="mx-auto max-w-7xl">
              <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                <div className="bg-white border border-gray-300 solid shadow-lg sm:max-w-3xl sm:w-full sm:mx-auto mx-8 sm:rounded-lg sm:overflow-hidden">
                  <div className="px-4 py-8 sm:px-10">
                    <div className="text-2xl font-bold">Sign Up</div>
                    <div className="mt-6">
                      <Formik
                        initialValues={{
                          firstName: "",
                          lastName: "",
                          email: "",
                          phone: "",
                          password: "",
                          repeatPassword: "",
                        }}
                        validationSchema={Yup.object({
                          firstName: Yup.string()
                            .max(15, "Must be 15 characters or less")
                            .required("First Name is Required"),
                          lastName: Yup.string()
                            .max(20, "Must be 20 characters or less")
                            .required("Last Name is Required"),
                          email: Yup.string()
                            .email("Invalid email address")
                            .required("Email is Required"),
                          // phone: Yup.string()
                          //   .phone("JM")
                          //   .required("phone is required"),
                          password: Yup.string()
                            .min(8, "minimum of 8 characters")
                            .required("Password is Required"),
                          repeatPassword: Yup.string()
                            .min(8, "minimum of 8 characters")
                            .oneOf(
                              [Yup.ref("password"), null],
                              "Password must match"
                            )
                            .required("Password is Required"),
                        })}
                        onSubmit={(values, { resetForm }) => {
                          let _data = {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            promoCode: values.promoCode
                              ? values.promoCode.trim()
                              : "",
                            phone: values.phone,
                            password: values.password,
                          };
                          setTimeout(async () => {
                            let response = await _signUp(_data);

                            let result = await response.json();
                            setIsDisabled(true);
                            notify(response.ok, result.message);
                            if (response.ok) {
                              resetForm();
                              if (result.isCouponApplied) {
                                setShowModal(true);
                              }
                            }
                          }, 400);
                        }}
                      >
                        <Form className="lg:grid grid-cols-2 gap-1">
                          <div>
                            <TextInput
                              name="firstName"
                              type="text"
                              placeholder="First name"
                            />
                          </div>
                          <div>
                            <TextInput
                              name="lastName"
                              type="text"
                              placeholder="Last name"
                            />
                          </div>
                          <div>
                            <TextInput
                              name="email"
                              type="email"
                              placeholder="Email address"
                            />
                          </div>
                          <div>
                            <TextInput
                              name="promoCode"
                              type="text"
                              placeholder="optional promocode"
                            />
                          </div>
                          <div>
                            <TextInput
                              name="password"
                              type="password"
                              placeholder="Password"
                            />
                            <PasswordStrengthBar />
                          </div>
                          <div>
                            <TextInput
                              name="repeatPassword"
                              type="password"
                              placeholder="Repeat Password"
                            />
                          </div>
                          <div className="col-start-1 col-end-3 mt-3">
                            <span className="font-bold">NOTE: </span>You must be
                            18 years or older to sign up for SEEDJA
                          </div>
                          <div className="col-start-1 col-end-3 mt-3">
                            <button
                              type="submit"
                              className="block w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme-main hover:bg-theme-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              disabled={isDisabled}
                            >
                              Sign up
                            </button>
                          </div>
                        </Form>
                      </Formik>

                      <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    </div>
                    <p className="text-center text-primary text-sm mt-2 underline font-medium">
                      <Link to="/">Login instead</Link>
                    </p>
                    <div className="py-6">
                      <p className="text-xs leading-5 text-gray-500">
                        By signing up, you agree to our
                        <Link
                          to="/termsandconditions"
                          className="font-medium text-gray-900 hover:underline"
                        >
                          {" "}
                          Terms, Data Policy and Cookies Policy{" "}
                        </Link>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div style={{ backgroundColor: "#253646" }}>
        <Footer />
      </div>
    </div>
  );
}
export default Signup;
