import React from "react";
import { Switch, Route } from "react-router-dom";

// ../../components
// import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Sidebar from "../../components/Sidebar/Sidebar.js";
// import HeaderStats from "../../components/Headers/HeaderStats.js";
// import Footer from "../../components/Footer/index";

// ../../views
import Dashboard from "../../views/admin/Dashboard.js";
import Contests from "../../views/admin/Contests.js";
import Prizes from "../../views/admin/Prizes.js";
import Users from "../../views/admin/Users.js";
import Partners from "../../views/admin/Partners.js";
import Quizes from "../../views/admin/Quizes.js";
import Verification from "../../views/admin/Verification";
import AdminLoans from "../../views/admin/AdminLoans";
import UserSubmissions from "../../views/admin/UserSubmissions";
import UserVerifications from "../../views/admin/UserVerifications";
import LoanInformation from "../../views/admin/LoanInformation";
import ConfigureInformation from "../../views/admin/ConfigureInformation";
import ConfigureVerification from "../../views/admin/ConfigureVerification";
import Submission from "../../views/admin/Submission";
import Editcopy from "../../views/admin/Editcopy";
import Redeem from "../../views/admin/Redeem.js";
import EmailUser from "../../views/admin/EmailUser";
import LogoutLoader from "../../components/Cards/LogoutLoader";
import AdminSignup from "../Signup/adminSignup.jsx";
import ChatUI from "../../components/ChatUI/index.js";
import ExportData from "../../views/admin/ExportData.jsx";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="pt-32 md:pt-32 relative md:ml-64 bg-gray-200 flex flex-col min-h-screen">
        {/* <AdminNavbar /> */}
        {/* Header */}
        {/* <HeaderStats /> */}
        <div className="px-4 md:px-10 mx-auto w-full -m-24 flex-grow">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/contests/:id" exact component={Contests} />
            <Route
              exactly
              path="/admin/contests"
              render={(props) => <Contests {...props} />}
            />
            <Route path="/admin/prizes/:id" exact component={Prizes} />
            <Route path="/admin/logout" exact component={LogoutLoader} />
            <Route
              exactly
              path="/admin/prizes"
              render={(props) => <Prizes {...props} />}
            />
            <Route path="/admin/redeem/:id" exact component={Redeem} />
            <Route
              exactly
              path="/admin/redeem"
              render={(props) => <Redeem {...props} />}
            />
            <Route path="/admin/users/:id" exact component={Users} />
            <Route
              exactly
              path="/admin/users"
              render={(props) => <Users {...props} />}
            />
            <Route path="/admin/partners/:id" exact component={Partners} />
            <Route
              exactly
              path="/admin/partners"
              render={(props) => <Partners {...props} />}
            />
            <Route path="/admin/quizes/:id" exact component={Quizes} />
            <Route
              exactly
              path="/admin/quizes"
              render={(props) => <Quizes {...props} />}
            />
            <Route
              path="/admin/verification/user/:id"
              exact
              component={UserVerifications}
            />
            <Route
              path="/admin/verification/user/model-5/:id"
              exact
              component={UserVerifications}
            />
             <Route
              path="/admin/loans/:id"
              exact
              component={LoanInformation}
            />
            <Route
              path="/admin/verification/:id"
              exact
              component={Verification}
            />
            <Route path="/admin/verification" exact component={Verification} />
            <Route
              path="/admin/verification/model-5/:id"
              exact
              component={Verification}
            />
            <Route path="/admin/verification/model-5" exact component={Verification} />
            <Route path="/admin/loans" exact component={AdminLoans} />
            <Route
              path="/admin/configure-information/:id"
              exact
              component={ConfigureInformation}
            />
            <Route
              exactly
              path="/admin/configure-information"
              render={(props) => <ConfigureInformation {...props} />}
            />
            <Route
              path="/admin/configure-verification/:id"
              exact
              component={ConfigureVerification}
            />
            <Route
              exactly
              path="/admin/configure-verification"
              render={(props) => <ConfigureVerification {...props} />}
            />
            <Route
              exactly
              path="/admin/email-user"
              render={(props) => <EmailUser {...props} />}
            />
            <Route path="/admin/submission" exact component={Submission} />
            <Route path="/admin/submission/:id" exact component={Submission} />
            <Route path="/admin/submission/model-5" exact component={Submission} />
            <Route path="/admin/submission/model-5/:id" exact component={Submission} />
            <Route
              exact
              component={UserSubmissions}
              path="/admin/submission/user/:id"
            />
            <Route path="/admin/edit-copy" exact component={Editcopy} />
            <Route component={AdminSignup} exact path="/admin/add-admin" />
            <Route path="/admin" exact component={Dashboard} />
            <Route path="/admin/messages" exact component={ChatUI} />
            <Route path="/admin/export" exact component={ExportData} />
          </Switch>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}
