import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { _getPartnersLoan } from "../../services/apiService";

function PartnersLoan() {
  let { id } = useParams();
  const [loans, setLoans] = useState(null);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      let result = await _getPartnersLoan(id);

      setLoans(result);
    }
    fetchData();
  }, [id]); // Or [] if effect doesn't need props or state

  return (
    <body className="antialiased bg-gray-200 text-gray-900 font-sans p-6">
      <div className="container mx-auto">
        <div className="flex flex-wrap -mx-4">
          {loans &&
            loans.map((loan, index) => (
              <div
                key={index}
                className="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 p-4"
              >
                <Link
                  className="c-card block bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden"
                  to={"/loans/" + loan._id}
                >
                  <div className="relative pb-48 overflow-hidden">
                    {loan.loanImage ? (
                      <>
                        <img
                          className="absolute inset-0 h-full w-full object-cover"
                          src={loan.loanImage}
                          alt=""
                        />
                      </>
                    ) : (
                      <>
                        <img
                          className="lg:h-48 lg:h-36 w-full object-cover object-center"
                          src="https://www.kinmade.com/pub/media/catalog/product/placeholder/default/no-image-available_1.jpg"
                          alt="blog"
                        />
                      </>
                    )}
                  </div>
                  <div className="p-4">
                    <span className="inline-block py-1 leading-none bg-orange-200 text-faded rounded-full font-semibold tracking-wide text-xs">
                      {loan.type}
                    </span>
                    <h2 className="mt-2 mb-2  font-bold">{loan.name}</h2>
                    <p className="text-sm h-10">
                      {loan.description.length > 140 ? (
                        <span>
                          {loan.description.slice(0, 50)}.....{" "}
                          <Link to={"/loans/" + loan._id}>
                            <p className="text-primary cursor-pointer inline-flex items-center mr-3">
                              Learn More
                            </p>
                          </Link>
                        </span>
                      ) : (
                        loan.description
                      )}
                    </p>
                    <div className="mt-3 flex items-center">
                      <span className="font-bold text-xl">
                        {loan.maximumAmount}
                      </span>
                      <span className="text-sm font-semibold">$</span>
                    </div>
                    <div className="mt-3 flex items-center">
                      <span className="italic text-faded text-sm mr-2">
                        Offered By
                      </span>
                      <span className="text-primary uppercase text-sm">
                        {loan.partner.name}
                      </span>
                    </div>
                  </div>
                  <div className="p-4 border-t border-b text-xs text-gray-700">
                    <span className="flex items-center mb-1">
                      <i className="fas fa-percentage pr-2"></i>
                      {loan.defaultInterestRate}%
                    </span>
                    <span className="flex items-center">
                      <i className="far fa-address-card fa-fw text-gray-900 mr-2"></i>{" "}
                      Repayment Option: {loan.repaymentOption}
                    </span>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </body>
  );
}
export default PartnersLoan;
