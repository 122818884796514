import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { _getSubmissonsByUserId } from "../../services/apiService";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserNotesModal from "../../components/Modal/UserNotesModal";

const TOTAL_SUBMISSION_COUNT = 9;

const submissionStatusSortingMapping = {
  Verified: 0,
  Rejected: 0,
  Submitted: 1,
  SubmittedForVerfication: 2,
};

export default function UserVerfications(props) {
  const { id } = useParams();
  const [apiData, setApiData] = React.useState(false);
  const [isUserNotesModalOpen, setIsUserNotesModalOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const result = await _getSubmissonsByUserId(id);
      console.log({ result });
      setApiData(result);
    }
    if (id) fetchData();
  }, [id]);

  if (!apiData) return "Loading...";

  return (
    <div className="w-full h-full bg-gray-200">
      <UserNotesModal
        open={isUserNotesModalOpen}
        onClose={() => setIsUserNotesModalOpen(false)}
      />
      <div className="w-full flex items-center bg-white p-6 rounded">
        <button
          onClick={() => {
            setIsUserNotesModalOpen(true);
          }}
          className="ml-auto h-10 focus:outline-none text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-500 dark:focus:ring-red-700"
        >
          Notes
        </button>
      </div>
      {apiData.map((data, idx) => {
        return <SubmissionCard key={idx} data={data} showGoBack={idx === 0} />;
      })}
    </div>
  );
}

function SubmissionCard({ data, showGoBack }) {
  const history = useHistory();
  const [submission, setSubmission] = useState(null);
  const [submissionValidateSchema, setSubmissionValidateSchema] =
    useState(null);

  function prepareVerificationForm(result) {
    const schema = "informationSchema";
    const fields = result[schema].fields;
    const data = result.data;
    fields.forEach((fieldObj) => {
      if (data[fieldObj["name"]]) {
        let value = data[fieldObj["name"]];
        if (fieldObj["validationType"] === "number") {
          value = parseInt(value);
        } else if (fieldObj["validationType"] === "date") {
          fieldObj["type"] = "text";
        } else {
          if (value.constructor === Array) {
            value = value.map((el) => el.name).join(",");
          } else if (typeof value === "object" && value !== null) {
            value = value.name;
          }
        }
        fieldObj["value"] = value;
      }
    });
    const yepSchema = fields.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);
    setSubmissionValidateSchema(validateSchema);
    setSubmission(fields);
  }

  useEffect(() => {
    prepareVerificationForm(data);
  }, []);

  if (!submission) return "Loading...";

  return (
    <div className="w-full my-4 bg-white rounded-xl">
      {showGoBack && (
        <button
          onClick={() => history.goBack()}
          className="ml-2 py-2 mt-3 text-sm flex flex-row"
        >
          <svg
            version="1.1"
            id="Capa_1"
            className="w-5 h-5 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 26.676 26.676"
          >
            <g>
              <path
                d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
C26.18,21.891,26.141,21.891,26.105,21.891z"
              />
            </g>
          </svg>
          <div className="underline px-1">Go back</div>
        </button>
      )}
      <div className="flex justify-start bg-white pt-4 pr-4">
        <div className="text-bold text-xl pl-4">Submission Information for{" "}
              {data.informationSchema.name}</div>
      </div>
      <DynamicInfoRender
        fields={submission}
        validation={submissionValidateSchema}
      />
    </div>
  );
}
