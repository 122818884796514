import React, { useEffect, useState } from "react";
import { _getPartnersFullInfo } from "../../services/apiService";
import CardPartnersList from "../../components/Cards/CardPartnersList.js";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import moment from "moment";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";

export default function Partners(props) {
  const [user, setUSer] = useState(null);
  let { id } = useParams();
  const [validateSchema, setValidateSchema] = useState(null);

  const fieldsInfo = React.useMemo(
    () => [
      {
        name: "userid",
        label: "User ID",
        placeholder: "User's ID here",
        hiddenOnForm: true,
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["User ID is required"],
          },
        ],
      },
      {
        name: "contactPhone",
        label: "contactPhone",
        placeholder: "contactPhone here",
        hiddenOnForm: true,
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["contactPhone is required"],
          },
        ],
      },
      {
        name: "contactEmail",
        label: "Email",
        placeholder: "User's email here",
        type: "text",
        validationType: "email",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Email required"],
          },
        ],
      },
      {
        name: "status",
        label: "Status",
        placeholder: "Partner's status here",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Status is required"],
          },
        ],
        options: ["Active", "Deactivated", "Revoked"],
      },
      {
        name: "name",
        label: "name",
        placeholder: "Name here",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["is required"],
          },
        ],
      },
      {
        name: "address",
        label: "address",
        placeholder: "address here",
        type: "longText",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["address is required"],
          },
        ],
      },
      {
        name: "description",
        label: "description",
        placeholder: "description here",
        type: "longText",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["description is required"],
          },
        ],
      },
      {
        name: "created",
        label: "Created date",
        placeholder: "Partner's Created Date",
        type: "date",
        validationType: "date",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Created date is required"],
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    async function fetchData() {
      function prepareUserForm(result) {
        const fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
        fieldsInfoCopy.forEach((fieldObj) => {
          if (result[fieldObj["name"]]) {
            let value = result[fieldObj["name"]];
            if (fieldObj["validationType"] === "number") {
              value = parseInt(value);
            } else {
              if (value.constructor === Array) {
                value = value.map((el) => el.name).join(",");
              } else if (typeof value === "object" && value !== null) {
                value = value.name;
              }
            }

            if (fieldObj["validationType"] === "date") {
              fieldObj["value"] = moment(value).format("YYYY-MM-DD");
            } else {
              fieldObj["value"] = value;
            }
          }
        });
        const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
        const validateSchema = yup.object().shape(yepSchema);
        setValidateSchema(validateSchema);
        setUSer(fieldsInfoCopy);
      }
      let result = await _getPartnersFullInfo(id);

      prepareUserForm(result.partner);
    }
    if (id) {
      fetchData();
    }
  }, [id, fieldsInfo]);

  return (
    <div className="">
      {id ? (
        <div>
          {user ? (
            <div className="w-full bg-white rounded-xl">
              <button
                onClick={(e) => props.history.goBack()}
                className="ml-2 mt-3 text-sm flex flex-row"
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  className="w-5 h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 26.676 26.676"
                >
                  <g>
                    <path
                      d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
      c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
      C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
      c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
      C26.18,21.891,26.141,21.891,26.105,21.891z"
                    />
                  </g>
                </svg>
                <div className="underline px-1">Go back</div>
              </button>
              <DynamicInfoRender fields={user} validation={validateSchema} />
            </div>
          ) : (
            <div className="my-4">
              <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                {[...Array(4)].map((e, i) => (
                  <div key={i} className="animate-pulse my-10 flex space-x-4">
                    <div className="flex-1 space-y-4 py-1">
                      <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                      <div className="space-y-2">
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full mb-12 px-4">
          <div className="flex justify-end bg-white pt-4 pr-4"></div>
          <CardPartnersList props={props} color="dark" />
        </div>
      )}
    </div>
  );
}
