import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { _getLoanApplicationsUser } from "../../services/apiService";
import moment from "moment";

const columns = [
  {
    name: "Loan Amount",
    selector: "loanAmount",
    sortable: true,
    wrap: true,
  },
  {
    name: "Interest Rate",
    selector: "interestRate",
    sortable: true,
    wrap: true,
  },
  {
    name: "term",
    selector: "term",
    sortable: true,
    wrap: true,
  },
  {
    name: "Created Date",
    selector: "created",
    sortable: true,
    wrap: true,
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    wrap: true,
    cell: (row, index, column, id) => {
      return (
        <button
          className={`${
            row.status === "Approved"
              ? "bg-green-500 hover:bg-green-700"
              : "bg-red-500 hover:bg-red-700"
          } text-white text-xs font-bold py-2 px-4 rounded pointer-events-none`}
        >
          {row.status}
        </button>
      );
    },
  },
];

export default function CardLoanApplicationsUser(props) {
  const [loans, setLoans] = useState(null);

  function applicationClicked(e) {
    const history = props.props.history;
    const url = "/loan-application/" + e._id;
    history.push(url);
  }
  useEffect(() => {
    async function fetchData() {
      let result = await _getLoanApplicationsUser();
      result.forEach((loan) => {
        loan.created = moment(loan.createdAt).format("h:mm a, MMMM Do YYYY");
        // loan.loanInfoImmutable = JSON.parse(loan.loanInfoImmutable);
        // loan.productInfoImmutable = JSON.parse(loan.productInfoImmutable);
      });
      setLoans(result);
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  return loans ? (
    <div className="bg-white flex flex-col">
      <div className="py-1 rounded-lg">
        <DataTable
          className="cursor-pointer"
          title="Loan Applications"
          columns={columns}
          pagination={true}
          paginationPerPage={8}
          data={loans}
          // onRowClicked={(e) => applicationClicked(e)}
        />
      </div>
      <div className="flex justify-center">
        <button
          onClick={() => {
            props.props.history.push("/loan-services");
          }}
          type="button"
          className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm w-40 pt-1.5 pb-2 mr-2 mb-2 mt-2"
        >
          Go to previous step
        </button>
      </div>
    </div>
  ) : (
    <>
      <div className="my-4">
        <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          {[...Array(4)].map((e, i) => (
            <div key={i} className="animate-pulse my-10 flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                <div className="space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
