import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  _getOneInformation,
  _editInformation,
} from "../../services/apiService";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import * as yup from "yup";
import CardConfigureInformation from "../../components/Cards/CardConfigureInformation";
import DynamicForm from "../../components/DynamicForm";
import moment from "moment";

export default function ConfigureInformation(props) {
  let { id } = useParams();
  const [informationApiData, setInformationApiData] = useState(null);

  const [information, setInformation] = useState(null);
  const [validateSchema, setValidateSchema] = useState(null);

  const [informationMode, setInformationMode] = useState("");

  const [informationFormDataEdit, setInformationFormDataEdit] = useState(null);
  const [formEditValidateSchema, setFormEditValidateSchema] = useState(null);

  const fieldsInfo = React.useMemo(
    () => [
      {
        name: "title",
        label: "Title",
        placeholder: "Enter title",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Information Name is required"],
          },
        ],
      },
      {
        name: "description",
        label: "Information Description",
        placeholder: "Enter information Description",
        type: "longText",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Information Description is required"],
          },
        ],
      },
      {
        name: "name",
        label: "Name",
        placeholder: "Enter name",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["information name is required"],
          },
        ],
      },
      {
        name: "points",
        label: "Number of seeds",
        placeholder: "Enter seeds",
        type: "text",
        validationType: "number",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Seeds is required"],
          },
          {
            type: "typeError",
            params: ["Seeds should be a number"],
          },
        ],
      },
      {
        name: "status",
        label: "Status",
        placeholder: "Enter status",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["status is required"],
          },
        ],
      },
      {
        name: "for",
        label: "Usertype",
        placeholder: "Enter usertype",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Winners is required"],
          },
        ],
      },
    ],
    []
  );

  const editFieldsInfo = React.useMemo(
    () => [
      {
        name: "title",
        label: "Title",
        placeholder: "Enter title",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["information Name is required"],
          },
        ],
      },
      {
        name: "description",
        label: "Information Description",
        placeholder: "Enter information Description",
        type: "longText",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Information Description is required"],
          },
        ],
      },
      {
        name: "name",
        label: "Name",
        placeholder: "Enter name",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Information Prize is required"],
          },
        ],
      },
      {
        name: "points",
        label: "Number of seeds",
        placeholder: "Enter seeds",
        type: "text",
        validationType: "number",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Seeds is required"],
          },
          {
            type: "typeError",
            params: ["Seeds should be a number"],
          },
        ],
      },
    ],
    []
  );

  const messagesEdit = {
    success: "Information edited successfully",
    error: "Can not edit information",
  };

  function editInformation() {
    setInformationMode("edit");
    const result = informationApiData;
    let fieldsInfoCopy = JSON.parse(JSON.stringify(editFieldsInfo));
    fieldsInfoCopy = fieldsInfoCopy.filter((el) => el.hiddenOnForm !== true);
    fieldsInfoCopy.forEach((fieldObj) => {
      if (result[fieldObj["name"]] || result[fieldObj["name"]] === 0) {
        let value = result[fieldObj["name"]];
        if (fieldObj["validationType"] === "number") {
          value = parseInt(value);
        } else {
          if (value.constructor === Array) {
            value = value.map((el) => el.name).join(",");
          } else if (typeof value === "object" && value !== null) {
            value = value._id;
          }
        }
        if (fieldObj["validationType"] === "date") {
          fieldObj["value"] = moment(value).format("YYYY-MM-DD");
        } else {
          fieldObj["value"] = value;
        }
      }
    });
    const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);
    setFormEditValidateSchema(validateSchema);
    setInformationFormDataEdit(fieldsInfoCopy);
  }

  async function editData(data = "") {
    if (data) {
      const response = await _editInformation(id, data);
      return response;
    }
  }

  async function changeUrl() {
    const url = "/admin/configure-information";
    props.history.push(url);
    setInformationMode("");
  }

  useEffect(() => {
    function prepareInformationForm(result) {
      const fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
      fieldsInfoCopy.forEach((fieldObj) => {
        if (result[fieldObj["name"]] || result[fieldObj["name"]] === 0) {
          let value = result[fieldObj["name"]];
          if (fieldObj["validationType"] === "number") {
            value = parseInt(value);
          } else if (fieldObj["validationType"] === "date") {
            fieldObj["type"] = "text";
          } else {
            if (value.constructor === Array) {
              value = value.map((el) => el.name).join(",");
            } else if (typeof value === "object" && value !== null) {
              value = value.name;
            }
          }
          if (fieldObj["validationType"] === "date") {
            fieldObj["value"] = moment(value).format("YYYY-MM-DD");
          } else {
            fieldObj["value"] = value;
          }
        }
      });
      const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
      const validateSchema = yup.object().shape(yepSchema);
      setValidateSchema(validateSchema);
      setInformation(fieldsInfoCopy);
    }
    async function fetchData() {
      // You can await here
      let result = await _getOneInformation(id);
      setInformationApiData(result);
      prepareInformationForm(result);
      // ...
    }
    if (id && id !== "add") {
      fetchData();
    }
  }, [id, fieldsInfo]); // Or [] if effect doesn't need props or state

  return (
    <>
      <div className="flex flex-wrap">
        {id ? (
          <>
            {information ? (
              <>
                {informationMode === "" ? (
                  <div className="w-full">
                    {/* add mode */}
                    <div className="flex justify-between bg-white pt-4 pr-4">
                      <button
                        onClick={(e) => props.history.goBack()}
                        className="ml-2 mt-3 text-sm flex flex-row"
                      >
                        <svg
                          version="1.1"
                          id="Capa_1"
                          className="w-5 h-5 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 26.676 26.676"
                        >
                          <g>
                            <path
                              d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                            />
                          </g>
                        </svg>
                        <div className="underline px-1">Go back</div>
                      </button>
                      <div className="flex justify-end space-x-2">
                        <button
                          className="inline-flex justify-center focus:outline-none py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                          onClick={() => editInformation()}
                        >
                          Edit Information
                        </button>
                      </div>
                    </div>
                    <DynamicInfoRender
                      fields={information}
                      validation={validateSchema}
                    />
                  </div>
                ) : (
                  <div className="w-full">
                    <div className="flex justify-between bg-white pt-4 pr-4">
                      <div className="text-bold w-2/3 text-xl pl-4">
                        Edit Information
                      </div>
                      <div className="flex justify-end">
                        <button
                          className="inline-flex focus:outline-none justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                          onClick={() => setInformationMode("")}
                        >
                          Cancel Edit
                        </button>
                      </div>
                    </div>
                    <DynamicForm
                      onSubmit={editData}
                      history={props.history}
                      messages={messagesEdit}
                      onSuccess={changeUrl}
                      redirect={false}
                      fields={informationFormDataEdit}
                      validation={formEditValidateSchema}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="my-4">
                  <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                    {[...Array(4)].map((e, i) => (
                      <div
                        key={i}
                        className="animate-pulse my-10 flex space-x-4"
                      >
                        <div className="flex-1 space-y-4 py-1">
                          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="w-full mb-12 px-4">
            <CardConfigureInformation props={props} color="dark" />
          </div>
        )}
      </div>
    </>
  );
}
