import React, { useEffect, useState } from "react";
import CardVerification from "../../components/Cards/CardVerification.js";
import { useParams } from "react-router-dom";
import {
  _getSubmittedInformation,
  _approveDocument,
  _rejectDocument,
} from "../../services/apiService";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Verification(props) {
  let { id } = useParams();
  const hasModel5 = window.location.href.includes("model-5");
  const [reject, setRejectHandle] = React.useState(false);
  const [verified, setVerifiedHandle] = React.useState(false);
  const [apiData, setApiData] = React.useState(false);

  const [currentView, setCurrentView] = useState("submission");
  const [verification, setVerification] = useState(null);
  const [validateSchema, setValidateSchema] = useState(null);

  const [submission, setSubmission] = useState(null);
  const [submissionValidateSchema, setSubmissionValidateSchema] =
    useState(null);

  const [rejectReason, setRejectReason] = useState("");

  const handleClickOpenReject = () => {
    setRejectHandle(true);
  };

  const handleClickCloseReject = () => {
    setRejectHandle(false);
  };

  const rejectDocument = async () => {
    const body = {
      rejectionReason: rejectReason,
      userId: apiData.submission.user,
      sid: apiData.submission._id,
    };
    const response = await _rejectDocument(id, body);
    // check response
    if (response.ok) {
      setRejectReason("");
      toast.success("Document Rejected", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      const url = "/admin/verification";
      setTimeout(() => props.history.push(url), 3000);
    } else {
      toast.error("Oops! an error occured", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    setRejectHandle(false);
  };

  const handleClickOpenVerify = () => {
    setVerifiedHandle(true);
  };

  const handleClickCloseVerify = () => {
    setVerifiedHandle(false);
  };

  const verifyDocument = async () => {
    const body = {
      userId: apiData.submission.user,
      sid: apiData.submission._id,
    };
    const response = await _approveDocument(id, body);
    if (response.ok) {
      toast.success("Successfully verified", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      const url = "/admin/verification";
      setTimeout(() => props.history.push(url), 3000);
    } else {
      toast.error("Oops! an error occured", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    setVerifiedHandle(false);
  };

  function prepareVerificationForm(result, type) {
    const schema =
      type === "submission" ? "informationSchema" : "verificationSchema";
    const fields = result[schema].fields;
    const data = result.data;
    console.log("fields:", fields, data);
    fields.forEach((fieldObj) => {
      if (data && data[fieldObj["name"]]) {
        let value = data[fieldObj["name"]];
        if (fieldObj["validationType"] === "number") {
          value = parseInt(value);
        } else if (fieldObj["validationType"] === "date") {
          fieldObj["type"] = "text";
        } else {
          if (value.constructor === Array) {
            value = value.map((el) => el.name).join(",");
          } else if (typeof value === "object" && value !== null) {
            value = value.name;
          }
        }
        fieldObj["value"] = value;
      }
    });
    const yepSchema = fields.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);
    setCurrentView("submission");
    if (type === "submission") {
      setSubmissionValidateSchema(validateSchema);
      setSubmission(fields);
    } else {
      setValidateSchema(validateSchema);
      console.log("veri:", fields);
      setVerification(fields);
    }
  }

  useEffect(() => {
    async function fetchData() {
      const result = await _getSubmittedInformation(id);
      console.log({ result });
      prepareVerificationForm(result["verification"], "verification");
      prepareVerificationForm(result["submission"], "submission");
      setApiData(result);
    }
    if (id && typeof id === "number") {
      fetchData();
    }
  }, [id]);

  return (
    <>
      {id && apiData ? (
        <div className="w-full">
          {apiData.verification.status === "Submitted" ? (
            <div className="flex justify-between bg-white pt-4 pr-4">
              <button
                onClick={(e) => props.history.goBack()}
                className="ml-2 mt-3 text-sm flex flex-row"
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  className="w-5 h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 26.676 26.676"
                >
                  <g>
                    <path
                      d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                    />
                  </g>
                </svg>
                <div className="underline px-1">Go back</div>
              </button>
              <div>
                <button
                  className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600 mr-4"
                  onClick={handleClickOpenVerify}
                >
                  Verify
                </button>
                <Dialog
                  fullWidth={true}
                  maxWidth="sm"
                  open={verified}
                  onClose={handleClickCloseVerify}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogContent>
                    <DialogContentText>Approve the document?</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClickCloseVerify} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={verifyDocument} color="primary">
                      Approve
                    </Button>
                  </DialogActions>
                </Dialog>
                <button
                  className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                  onClick={handleClickOpenReject}
                >
                  Reject
                </button>
                <Dialog
                  fullWidth={true}
                  maxWidth="md"
                  open={reject}
                  onClose={handleClickCloseReject}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogContent>
                    <DialogContentText>
                      Please specify a reason for the rejection.
                    </DialogContentText>
                    <textarea
                      placeholder="Please specify a reason for the rejection."
                      required
                      value={rejectReason}
                      onChange={(e) => {
                        setRejectReason(e.target.value);
                      }}
                      rows="3"
                      className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md`}
                    ></textarea>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClickCloseReject} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={rejectDocument} color="primary">
                      Reject
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          ) : (
            <div className="flex flex-row bg-white justify-between">
              <button
                onClick={(e) => props.history.goBack()}
                className="ml-2 mt-3 text-sm flex flex-row"
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  className="w-5 h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 26.676 26.676"
                >
                  <g>
                    <path
                      d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                    />
                  </g>
                </svg>
                <div className="underline px-1">Go back</div>
              </button>
              <div
                className={`flex justify-end text-sm bg-white pt-4 pr-4 ${
                  apiData.verification.status &&
                  apiData.verification.status === "Rejected"
                    ? "text-red-600"
                    : "text-primary"
                }`}
              >
                this document is {apiData.verification.status}
              </div>
            </div>
          )}
          <div className="flex justify-start bg-white pt-4 pr-4">
            <div className="text-bold text-xl pl-4">
              Verification Information
            </div>
          </div>
          <div className="flex bg-white">
            <button
              className={`flex-grow focus:outline-none border-b-2 py-2 text-lg px-1 ${
                currentView === "submission"
                  ? "text-indigo-500 border-indigo-500"
                  : ""
              }`}
              onClick={() => setCurrentView("submission")}
            >
              Submission
            </button>
            <button
              className={`flex-grow focus:outline-none border-b-2 py-2 text-lg px-1 ${
                currentView === "verification"
                  ? "text-indigo-500 border-indigo-500"
                  : ""
              }`}
              onClick={() => setCurrentView("verification")}
            >
              Verification
            </button>
          </div>
          {submission && verification ? (
            currentView === "verification" ? (
              <DynamicInfoRender
                fields={verification}
                validation={validateSchema}
              />
            ) : (
              <DynamicInfoRender
                fields={submission}
                validation={submissionValidateSchema}
              />
            )
          ) : (
            <div className="my-4">
              <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                {[...Array(4)].map((e, i) => (
                  <div key={i} className="animate-pulse my-10 flex space-x-4">
                    <div className="flex-1 space-y-4 py-1">
                      <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                      <div className="space-y-2">
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
            <CardVerification props={props} />
          </div>
        </div>
      )}
    </>
  );
}
