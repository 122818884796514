import DynamicForm from '../../components/DynamicForm';
import React, { useEffect, useState } from "react";
// import DynamicInfoRender from '../../components/DynamicInfoRender';
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import * as yup from "yup";
import { _submitInformation, _submitInformationMultipart, _getTree } from '../../services/apiService';
// import { _submitInformation, _verifyInformation } from '../../services/apiService';
import Cookies from 'universal-cookie';
// import ReactGA from "react-ga4";

// ReactGA.initialize("G-RNXMBV616Q");


function Information(props) {
  const [treeInfo, settreeInfo] = useState(null);
  const [prefilled, setprefilled] = useState(false);
  useEffect(() => {
    async function fetchData() {
      const image = await _getTree();
        settreeInfo(image)
      
    }
    async function prefill() {
      if(props.location.state.allData.name =="Basic Information") {
        const cookies = new Cookies();
        const cookieInfo = cookies.get('kidUser');
        props.location.state.data.forEach(input => {
          console.log("input:",input)
          if(input.name == 'first_name') {
            input.value = cookieInfo.firstName;
          } else if(input.name == 'last_name') {
            input.value = cookieInfo.lastName;
          }
        })
        
      }
      setprefilled(true);
    }
    fetchData();
    prefill();
  }, []);
   // add code that nobody reaches here directly
   // handle submit 
   const {data, id, allData,allInfo,index} = props.location.state
   console.log("index:",allData,allInfo,index);
   console.log("data:",data)
  const yepSchema = data.reduce(createYupSchema, {});
      const validateSchema = yup.object().shape(yepSchema);
 
   async function submitData(data='') {
     if (data) {
//        ReactGA.event({
//   category: allData.title,
//   action: "clicked",
// });
       if(allData.name==='Credit Info') {
        const extraInfo = {
          informationId: id
          }
         const response = await _submitInformationMultipart(data,extraInfo);
        return response;
       } else {
        const body = {
          data: data,
          informationId: id
        }
       const response = await _submitInformation(body);
      return response;
       }
     }
  }

  // useEffect(() => {
  //   submitData()
  // })

      
return (
  <div>
    <div className="flex items-center w-full">
      {/* sidebar */}
      {/* <div className="flex flex-col-reverse md:col-span-1" 
        style={{
          background: `rgba(255, 255, 255, 0.09)`,
  border: `1px solid #B8FFE4`,
  boxShadow: `0px 0px 30px rgba(0, 0, 0, 0.04)`,
  backdropFilter: 'blur(28px)',
  borderRadius: '20px'
  }}>
          <div className="flex justify-center  px-4 sm:px-0">
          {treeInfo ?  <img
                  alt="..."
                  src={require(`../../assets/img/${treeInfo}`).default}
                />: ''}
            
          </div>
        </div> */}
      {/* The form */}
      {
        // <DynamicInfoRender onSubmit={submitData} history={props.history} fields={data} validation={validateSchema}  />
        data && prefilled? 
        <DynamicForm onSubmit={submitData} index={index}  allInfo={allInfo} allData={allData} history={props.history} fields={data} validation={validateSchema}  />
        : ''
      }
      
    </div>
  </div>
);
}

export default Information;