import React, { useState, useEffect } from "react";
import {
  _getContestsUser,
  _joinContest,
  _getPrizes,
  _getPrize,
  _redeemPrize,
} from "../../services/apiService";
import NotFound from "../../components/NotFound/index";
// _getNextPrizeInformation
import { useParams, Link } from "react-router-dom";
import Confetti from "react-dom-confetti";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

function Redeem(props) {
  let { id } = useParams();
  const [isActive, setisActive] = useState(false);
  const [response, setResponse] = useState(null);
  const [contestData, setContestData] = useState(null);
  const [currentView, setCurrentView] = useState("redeem");
  // const [prizeInformation, setPrizeInformation] = useState(null);

  if (id) {
    // use different view
  }

  // useEffect(() => {
  //   async function fetchNextPrizeInformation() {
  //   const result = await _getNextPrizeInformation();
  //   setPrizeInformation(result)
  //   }
  //   fetchNextPrizeInformation();
  // }, []);

  // && userPoints>requiredPoints

  async function redeemPrize(prize) {
    // const userPoints = prizeInformation.points
    // const requiredPoints = prize.pointRequired
    const response = await _redeemPrize(prize._id);
    let result = await response.json();
    if (response.ok) {
      // show success and error
      prize.isRedeemed = true;
      setisActive(true);
      toast.success(result.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error(result.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }

  const config = {
    angle: 90,
    spread: 180,
    startVelocity: 40,
    elementCount: 360,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "700px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  async function joinContest(contest) {
    const response = await _joinContest(contest._id);
    if (response.ok) {
      // show success and error
      contest.isJoined = true;
      setisActive(true);
      toast.success("Contest has been joined", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }
  useEffect(() => {
    async function fetchData() {
      // You can await here
      let result = await _getPrizes("Redeem");
      setResponse(result);
      // ...
    }

    async function fetchContests() {
      let result = await _getContestsUser();
      setContestData(result);
    }

    async function fetchPrize() {
      let result = await _getPrize(id);
      setContestData(result);
    }
    if (id) {
      fetchPrize();
    } else {
      fetchContests();
      fetchData();
    }
  }, [id]); // Or [] if effect doesn't need props or state

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {id ? (
        <div>
          <section className="bg-white min-h-screen text-gray-600 body-font overflow-hidden">
            <button
              onClick={(e) => props.history.goBack()}
              className="my-5 ml-2 text-sm flex flex-row"
            >
              <svg
                version="1.1"
                id="Capa_1"
                className="w-5 h-5 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 26.676 26.676"
              >
                <g>
                  <path
                    d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                  />
                </g>
              </svg>
              <div className="underline px-1">Go back</div>
            </button>
            {contestData ? (
              <div className="container px-5 md:py-14 mx-auto">
                <div className="lg:w-4/5 mx-auto flex flex-wrap">
                  <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
                    <h1 className="text-gray-900 text-3xl title-font font-medium mb-4">
                      {contestData.name}
                    </h1>
                    <div className="flex mb-4">
                      <button
                        className={`flex-grow border-b-2 py-2 text-lg px-1 ${
                          currentView === "redeem"
                            ? "text-indigo-500 border-indigo-500"
                            : "border-gray-300"
                        }`}
                        onClick={() => setCurrentView("redeem")}
                      >
                        How to Redeem
                      </button>
                      <button
                        className={`flex-grow border-b-2 py-2 text-lg px-1 ${
                          currentView === "description"
                            ? "text-indigo-500 border-indigo-500"
                            : "border-gray-300"
                        }`}
                        onClick={() => setCurrentView("description")}
                      >
                        Description
                      </button>
                    </div>
                    {currentView === "redeem" ? (
                      <div>
                        {/* DESCRIPTION SECTION */}
                        <div>
                          {contestData.description &&
                            contestData.description
                              .split(",")
                              .map((el, i) => <li key={i}>{el}</li>)}
                        </div>
                        <div className="flex">
                          <span className="title-font font-medium text-2xl text-gray-900">
                            ${contestData.value}
                          </span>
                          <button
                            onClick={() => redeemPrize(contestData)}
                            className="flex ml-auto text-white bg-theme-main border-0 py-2 px-6 focus:outline-none hover:bg-theme-main-hover rounded"
                            disabled={contestData.isRedeemed}
                          >
                            <small>
                              {contestData.isRedeemed ? "Redeemed" : "Redeem"}
                            </small>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        {contestData ? (
                          <div>
                            {/* DESCRIPTION SECTION */}
                            <p className="leading-relaxed mb-4">
                              {contestData.description}
                            </p>
                            <div className="flex border-t border-gray-200 py-2">
                              <span className="text-gray-500">
                                Seeds Required
                              </span>
                              <span className="ml-auto title-font font-medium text-2xl text-gray-900">
                                {contestData.pointRequired}
                              </span>
                            </div>
                            <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                              <span className="text-gray-500">Value</span>
                              <span className="ml-auto title-font font-medium text-2xl text-gray-900">
                                {contestData.value}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                  <img
                    alt="ecommerce"
                    className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
                    src={
                      contestData
                        ? contestData.imageUrl
                        : "https://dummyimage.com/400x400"
                    }
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </section>
        </div>
      ) : (
        <div>
          {contestData && contestData[0] ? (
            <div>
              <div className="w-full max-w-7xl rounded-xl bg-white shadow-xl p-6 mx-auto relative md:text-left">
                <div className="md:flex items-center">
                  <div className="w-full md:w-1/2 px-8 md:pr-4 mb-2 md:mb-4 md:mb-0">
                    <div className="relative">
                      <img
                        src={contestData && contestData[0].prize.imageUrl}
                        className="w-full relative z-10"
                        alt=""
                        height="125px"
                      />
                      <div className="border-4 border-primary absolute rounded-xl top-16 bottom-16 left-16 right-16 z-0"></div>
                    </div>
                  </div>
                  <div className="px-2 md:px-0 md:mb-4">
                    <div className="text-5xl font-bold text-primary">
                      {moment(contestData && contestData[0].endDate).diff(
                        moment(),
                        "days"
                      )}
                    </div>
                  </div>
                  <div className="text-primary px-2 md:px-0 md:leading-none md:ml-1 mb-1">
                    days remaining
                  </div>
                  <div className="w-full md:w-1/2 px-2 py-4 md:px-9 md:py-6">
                    <div className="text-2xl md:text-4xl font-bold text-primary capitalize leading-none">
                      {contestData && contestData[0].name}
                    </div>
                    <div className="text-sm font-semibold text-secondary">
                      ends{" "}
                      {moment(contestData && contestData[0].endDate).format(
                        "h:mm a, MMMM Do YYYY"
                      )}
                    </div>
                    <p className="mt-2 text-faded text-sm">About Contest</p>
                    <div className="text-sm">
                      {contestData &&
                        contestData[0].description
                          .split(",")
                          .map((el, index) => <p key={index}>{el}</p>)}
                    </div>
                    <div>
                      <div className="mt-1">
                        <button
                          onClick={() =>
                            joinContest(contestData && contestData[0])
                          }
                          className="text-button bg-theme-main hover:bg-theme-main-hover rounded-md px-2 py-1 mt-2 text-sm"
                          disabled={contestData && contestData[0].isJoined}
                        >
                          {contestData && contestData[0].isJoined
                            ? "Contest Joined"
                            : "Join the Contest"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Banner ends here */}
          {/* <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover/> */}
          {response ? (
            response.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 p-8 bg-white rounded-xl">
                {response &&
                  response.length > 0 &&
                  response.map((prize, i) => (
                    <div key={i} className="antialiased">
                      <Confetti active={isActive} config={config} />
                      <div>
                        <img
                          src={prize.imageUrl}
                          alt="prize"
                          className="w-full bg-white object-cover object-center rounded-xl shadow-md"
                        />
                        <div className="relative px-4 -mt-20">
                          <div className="bg-white px-6 py-6 rounded-lg shadow-lg">
                            <h4 className="mt-1 text-lg font-semibold uppercase leading-none truncate">
                              {prize.name}
                            </h4>
                            <div className="text-primary font-medium">
                              {prize.pointRequired} seeds
                            </div>
                            <div className="text-xs text-gray-600 mt-2">
                              {prize.description}
                            </div>
                            <Link to={"/redeem/" + prize._id}>
                              {/* <button className="px-2 py-1 mt-4 bg-indigo-300 rounded-full text-sm font-semibold hover:bg-opacity-75">Redeem</button> */}
                              <button className="text-button bg-theme-main hover:bg-theme-main-hover rounded-md px-2 py-1 mt-2 text-sm">
                                Get Now!
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <>
                <div className="flex justify-center" >
                  <NotFound
                    title="There is nothing to redeem right now."
                    description="We are working on adding new prizes for you"
                  />
                </div>
              </>
            )
          ) : (
            <div className="my-4">
              <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                {[...Array(4)].map((e, i) => (
                  <div className="animate-pulse my-10 flex space-x-4" key={i}>
                    <div className="flex-1 space-y-4 py-1">
                      <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                      <div className="space-y-2">
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Redeem;
