import React, { useEffect } from "react";
import {
  _getUserFullInfo,
  _userProfileUpdate,
} from "../../services/apiService";
import CProfile from "../../components/Profile";
// import Accordion from '@material-ui/core/Accordion';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import Typography from '@material-ui/core/Typography';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Profile(props) {
  //     const [expanded, setExpanded] = React.useState(false);
  //   const handleChange = (panel) => (event, isExpanded) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };
  // const activity =
  const initialView =
    props.location && props.location.state && props.location.state.view
      ? props.location.state.view
      : "info";
  const [optionActive, setOptionActive] = React.useState(initialView);
  const [userInfo, setUserInfo] = React.useState(null);
  useEffect(() => {
    async function fetchData() {
      let result = await _getUserFullInfo("");
      setUserInfo(result);
    }
    fetchData();
  }, []);

  const changeImage = async (files) => {
    console.log("files: ", files[0]);
    const response = await _userProfileUpdate(files[0]);
    const newProfile = JSON.parse(JSON.stringify(userInfo));
    newProfile["user"] = response;
    setUserInfo(newProfile);
    console.log("response: ", response);
  };
  return (
    <div className="bg-white">
      <section className="text-gray-600 body-font">
        <div className="container px-5  mx-auto flex flex-col">
          <div className="lg:w-full sm:mx-16" style={{ marginLeft: "0px" }}>
            {/* <div className="rounded-lg h-64 overflow-hidden">
        <img alt="content" className="object-cover object-center h-full w-full" src="https://dummyimage.com/1200x500" />
      </div> */}
            <div className="flex flex-col sm:flex-row mt-10">
              <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                <div>
                  <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                    <label htmlFor="fileUpload">
                      {userInfo && userInfo.user ? (
                        <img
                          src={userInfo.user.image}
                          className="w-20 h-20 rounded-full inline-flex"
                        />
                      ) : (
                        <svg
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          className="w-10 h-10"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                      )}
                    </label>
                    <input
                      hidden
                      id="fileUpload"
                      type="file"
                      className="h-4 w-4 absolute  sm:left-80 md:left-40 lg:left-80 lg:top-48"
                      style={{ opacity: "0", width: "25px", height: "25px" }}
                      onChange={(e) => changeImage(e.target.files)}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center text-center justify-center">
                  <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">
                    {userInfo && userInfo.user ? userInfo.user.fullName : ""}
                  </h2>
                  <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
                  <div>
                    <div className=" flex flex-col bg-blue-darker w-full py-4 hidden sm:block cursor-pointer">
                      <div
                        className={`hover:bg-gray-100 p-2 m-2 ${
                          optionActive === "info" ? "bg-gray-100" : ""
                        }`}
                        onClick={() => setOptionActive("info")}
                      >
                        <p className="font-bold text-sm text-black text-left">
                          My Info
                        </p>
                      </div>
                      {/* <div className={`hover:bg-gray-100 p-2 m-2 ${optionActive === 'data' ? 'bg-gray-100': ''}`} onClick={()=>setOptionActive("data")}>
                        <p 
                        className='font-bold text-sm text-black text-left'>My Data</p>
                    </div> */}
                      <div
                        className={`hover:bg-gray-100 p-2 m-2 ${
                          optionActive === "activity" ? "bg-gray-100" : ""
                        }`}
                        onClick={() => setOptionActive("activity")}
                      >
                        <p className="font-bold text-sm text-black text-left">
                          My Activities{" "}
                        </p>
                      </div>
                      <div
                        className={`hover:bg-gray-100 p-2 m-2 ${
                          optionActive === "points" ? "bg-gray-100" : ""
                        }`}
                        onClick={() => setOptionActive("points")}
                      >
                        <p className="font-bold text-sm text-black text-left">
                          My Seeds{" "}
                        </p>
                      </div>
                      <div
                        className={`hover:bg-gray-100 p-2 m-2 ${
                          optionActive === "acquisitions" ? "bg-gray-100" : ""
                        }`}
                        onClick={() => setOptionActive("acquisitions")}
                      >
                        <p className="font-bold text-sm text-black text-left">
                          My Acquisitions{" "}
                        </p>
                      </div>
                      <div
                        className={`hover:bg-gray-100 p-2 m-2 ${
                          optionActive === "contests" ? "bg-gray-100" : ""
                        }`}
                        onClick={() => setOptionActive("contests")}
                      >
                        <p className="font-bold text-sm text-black text-left">
                          My Contests{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* content pane */}
              <div className="sm:w-full sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                {/* {optionActive === 'data' ?
            <CProfile view='data' />
            : optionActive === 'points' ?
            'No points Won'
            : optionActive === 'acquisitions' ?
            'No acquisitions Yet'
            : optionActive === 'contests' ?
            'No contests won yet' : ''
        } */}
                {userInfo ? (
                  <div>
                    <CProfile
                      view={optionActive}
                      userInfo={userInfo}
                      notifySuccess={(msg) => {
                        toast.success(msg, {
                          position: toast.POSITION.TOP_CENTER,
                        });
                      }}
                      notifyError={(msg) => {
                        toast.error(msg, {
                          position: toast.POSITION.TOP_CENTER,
                        });
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}

                {/*   <p className="leading-relaxed text-lg mb-4 lg:pr-20">Meggings portland fingerstache lyft, post-ironic fixie man bun banh mi umami everyday carry hexagon locavore direct trade art party. Locavore small batch listicle gastropub farm-to-table lumbersexual salvia messenger bag. Coloring book flannel truffaut craft beer drinking vinegar sartorial, disrupt fashion axe normcore meh butcher. Portland 90's scenester vexillologist forage post-ironic asymmetrical, chartreuse disrupt butcher paleo intelligentsia pabst before they sold out four loko. 3 wolf moon brooklyn.</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Profile;
