import React, { useEffect, useState } from "react";
import DynamicForm from "../../components/DynamicForm";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import * as yup from "yup";
import { _sendEmail } from "../../services/apiService";
export default function EmailUser(props) {
  const [formValidateSchema, setFormValidateSchema] = useState(null);
  const [formData, setformData] = useState(null);

  const messagesEmail = {
    success: "Email sent successfully",
    error: "Email sent Failed",
  };

  const fieldsInfo = React.useMemo(
    () => [
      {
        name: "to",
        label: "To",
        placeholder: "TO:",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Email's to is required"],
          },
        ],
      },
      {
        name: "subject",
        label: "Subject",
        placeholder: "Subject of email",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Email subject is required"],
          },
        ],
      },
      {
        name: "description",
        label: "Email Description",
        placeholder: "Enter Email Description",
        type: "longText",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Email Description is required"],
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    async function fetchData() {
      let fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
      fieldsInfoCopy = fieldsInfoCopy.filter((el) => el.hiddenOnForm !== true);

      const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
      const validateSchema = yup.object().shape(yepSchema);

      setFormValidateSchema(validateSchema);
      setformData(fieldsInfoCopy);
    }
    fetchData();
  }, [fieldsInfo]);

  async function submitData(data = "") {
    if (data) {
      const response = await _sendEmail(data);
      return response;
    }
  }

  return (
    <>
      <div>
        {formData ? (
          <DynamicForm
            onSubmit={submitData}
            history={props.history}
            messages={messagesEmail}
            redirect={false}
            fields={formData}
            dialogMessage="Are you sure you want to send the email?"
            validation={formValidateSchema}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
