import React, { useState } from "react";
import NavBarLanding from "../../components/NavBarLanding/index";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../components/Footer/index";
import { _resetPassword } from "../../services/apiService";
import { Link } from "react-router-dom";
import ExternalPasswordStrengthBar from "react-password-strength-bar";
import TextInput from "../../components/TextInput";

const PasswordStrengthBar = () => {
  const [field] = useField({ name: "password" });
  return <ExternalPasswordStrengthBar password={field.value} />;
};

function ResetPassword(props) {
  const [isDisabled, setIsDisabled] = useState(false);
  let hash = new URLSearchParams(props.history.location.search).get("token");
  let email = new URLSearchParams(props.history.location.search).get("email");

  const notify = (isSuccess, message) => {
    if (isSuccess) {
      setIsDisabled(false);
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setIsDisabled(false);
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  //   var query = window.location.search.substring(1);

  return (
    <div>
      <div
        className="relative overflow-hidden min-h-screen"
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <div className="relative">
          <div
            className="border-b-1 shadow-lg border-gray-600"
            style={{ backgroundColor: "white" }}
          >
            <NavBarLanding pageType="signup" />
          </div>
          <main className="mt-16 sm:mt-24">
            <div className="mx-auto max-w-7xl">
              <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                <div className="bg-white border border-gray-300 solid shadow-lg sm:max-w-3xl sm:w-full sm:mx-auto mx-8 sm:rounded-lg sm:overflow-hidden">
                  <div className="px-4 py-8 sm:px-10">
                    <div className="text-2xl font-bold">
                      Reset Your Password
                    </div>
                    <div className="mt-6">
                      <Formik
                        initialValues={{
                          password: "",
                          repeatPassword: "",
                        }}
                        validationSchema={Yup.object({
                          password: Yup.string()
                            .min(8, "minimum of 8 characters")
                            .required("Password is Required"),
                          repeatPassword: Yup.string()
                            .min(8, "minimum of 8 characters")
                            .oneOf(
                              [Yup.ref("password"), null],
                              "Password must match"
                            )
                            .required("Password is Required"),
                        })}
                        onSubmit={(values, { resetForm }) => {
                          let _data = {
                            user: {
                              password: values.password,
                              confirm: values.repeatPassword,
                              email: email,
                            },
                            hash: hash,
                          };
                          setTimeout(async () => {
                            setIsDisabled(true);
                            let response = await _resetPassword(_data);
                            setIsDisabled(false);
                            const message = await response.text();
                            if (response.ok) {
                              resetForm();
                              notify(true, message);
                            } else {
                              notify(false, message);
                            }
                          }, 400);
                        }}
                      >
                        <Form className="lg:grid grid-cols-2 gap-1">
                          <div>
                            <TextInput
                              name="password"
                              type="password"
                              placeholder="Password"
                            />
                            <PasswordStrengthBar />
                          </div>
                          <div>
                            <TextInput
                              name="repeatPassword"
                              type="password"
                              placeholder="Repeat password"
                            />
                          </div>
                          <div className="col-start-1 col-end-3 mt-3">
                            <button
                              type="submit"
                              className="block w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme-main hover:bg-theme-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              disabled={isDisabled}
                            >
                              Reset Password
                            </button>
                          </div>
                        </Form>
                      </Formik>
                      <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    </div>
                    <p className="text-center text-primary text-sm mt-2 underline font-medium">
                      <Link to="/">Login instead</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default ResetPassword;
