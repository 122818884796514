import * as Yup from "yup";
import Cookies from "universal-cookie";
import React, { useState } from "react";
import { Formik, Form, useField } from "formik";
import { ToastContainer, toast } from "react-toastify";
import ExternalPasswordStrengthBar from "react-password-strength-bar";
import TextInput from "../../components/TextInput";
import { _updatePassword } from "../../services/apiService";
import "react-toastify/dist/ReactToastify.css";

const PasswordStrengthBar = () => {
  const [field] = useField({ name: "password" });
  return <ExternalPasswordStrengthBar password={field.value} />;
};

const logout = () => {
  const cookies = new Cookies();
  cookies.remove("kidUser");
  window.location.href = "/";
};

function UpdatePassword(props) {
  const [isDisabled, setIsDisabled] = useState(false);

  const notify = (isSuccess, message) => {
    if (isSuccess) {
      setIsDisabled(false);
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setIsDisabled(false);
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <main className="mt-16 sm:mt-24">
      <div className="mx-auto max-w-7xl">
        <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
          <div className="bg-white border border-gray-300 solid shadow-lg sm:max-w-3xl sm:w-full sm:mx-auto mx-8 sm:rounded-lg sm:overflow-hidden">
            <div className="px-4 py-8 sm:px-10">
              <div className="text-2xl font-bold">Change Password</div>
              <div className="mt-6">
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={Yup.object({
                    password: Yup.string()
                      .min(8, "minimum of 8 characters")
                      .required("Password is Required"),
                    confirmPassword: Yup.string()
                      .min(8, "minimum of 8 characters")
                      .oneOf([Yup.ref("password"), null], "Password must match")
                      .required("Password is Required"),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    let _data = {
                      user: {
                        password: values.password,
                        confirm: values.confirmPassword,
                      },
                    };
                    setTimeout(async () => {
                      setIsDisabled(true);
                      let response = await _updatePassword(_data);
                      setIsDisabled(false);
                      const message = await response.text();
                      if (response.ok) {
                        resetForm();
                        notify(true, message);
                        logout();
                      } else {
                        notify(false, message);
                      }
                    }, 400);
                  }}
                >
                  <Form className="lg:grid grid-cols-2 gap-1">
                    <div>
                      <TextInput
                        name="password"
                        type="password"
                        placeholder="Password"
                      />
                      <PasswordStrengthBar />
                    </div>
                    <div>
                      <TextInput
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                      />
                    </div>
                    <div className="col-start-1 col-end-3 mt-3">
                      <button
                        type="submit"
                        className="block w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme-main hover:bg-theme-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled={isDisabled}
                      >
                        Change Password
                      </button>
                    </div>
                  </Form>
                </Formik>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default UpdatePassword;
