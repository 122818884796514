import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  _getPrize,
  _submitPrize,
  _deletePrize,
} from "../../services/apiService";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import * as yup from "yup";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CardPrizes from "../../components/Cards/CardPrizes.js";
import DynamicForm from "../../components/DynamicForm";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

export default function Prizes(props) {
  let { id } = useParams();
  const [prize, setPrize] = useState(null);
  const [validateSchema, setValidateSchema] = useState(null);
  const [prizeFormData, setPrizeFormData] = useState(null);
  const [formValidateSchema, setFormValidateSchema] = useState(null);
  const [prizeApiData, setprizeApiData] = useState(null);
  const [prizeMode, setPrizeMode] = useState("add");
  const [prizeFormDataEdit, setPrizeFormDataEdit] = useState(null);
  const [formEditValidateSchema, setFormEditValidateSchema] = useState(null);
  const [denyDelete, setDenyDelete] = useState(false);

  const handleClickOpenDenyDelete = () => {
    setDenyDelete(true);
  };

  const handleClickCloseDenyDelete = () => {
    setDenyDelete(false);
  };

  function preparePrizetEditForm() {
    const result = prizeApiData;
    let fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
    fieldsInfoCopy = fieldsInfoCopy.filter((el) => el.hiddenOnForm !== true);
    fieldsInfoCopy.forEach((fieldObj) => {
      if (result[fieldObj["name"]] || result[fieldObj["name"]] === 0) {
        let value = result[fieldObj["name"]];
        if (fieldObj["validationType"] === "number") {
          value = parseInt(value);
        } else {
          if (value.constructor === Array) {
            value = value.map((el) => el.name).join(",");
          } else if (typeof value === "object" && value !== null) {
            value = value.name;
          }
        }
        if (fieldObj["validationType"] === "date") {
          fieldObj["value"] = moment(value).format("YYYY-MM-DD");
        } else {
          fieldObj["value"] = value;
        }
      }
    });
    const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);
    setFormEditValidateSchema(validateSchema);
    setPrizeFormDataEdit(fieldsInfoCopy);
  }

  function editPrize() {
    preparePrizetEditForm();
    setPrizeMode("edit");
    //id='add';
  }

  async function deletePrize() {
    if (id) {
      const result = await _deletePrize(id);
      if (result.ok) {
        toast.success("Prize deleted successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        const url = "/admin/prizes/";
        setTimeout(() => props.history.push(url), 2000);
      } else {
        const message = await result.text();
        toast.error(message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    }
  }

  const messagesAdd = {
    success: "Prize added successfully",
    error: "Can not add contest",
  };

  const messagesEdit = {
    success: "Prize edited successfully",
    error: "Can not edit contest",
  };

  function addPrize() {
    preparePrizeAddForm();
    const url = "/admin/prizes/add";
    props.history.push(url);
    //id='add';
  }

  const fieldsInfo = React.useMemo(
    () => [
      {
        name: "created",
        label: "Prize Created On",
        placeholder: "Enter Prize Created On",
        hiddenOnForm: true,
        type: "date",
        validationType: "date",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Contest Created Date is required"],
          },
        ],
      },
      {
        name: "pointRequired",
        label: "Seeds required",
        placeholder: "Enter seeds required",
        type: "text",
        validationType: "number",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Seeds required is required"],
          },
          {
            type: "typeError",
            params: ["seeds required should be a number"],
          },
        ],
      },
      {
        name: "name",
        label: "Prize Name",
        placeholder: "Prize Name",
        type: "text",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Prize Name is required"],
          },
        ],
      },
      {
        name: "description",
        label: "Prize Description",
        placeholder: "Enter Prize Description",
        type: "longText",
        validationType: "string",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Prize Description is required"],
          },
        ],
      },
      {
        name: "type",
        label: "Type",
        placeholder: "Type",
        type: "select",
        validationType: "string",
        value: "",
        defaultOption: "Select Prize Type",
        validations: [
          {
            type: "required",
            params: ["category is required"],
          },
        ],
        options: ["Redeem", "Prize"],
      },
      {
        name: "value",
        label: "Prize Value ($)",
        placeholder: "Enter value",
        type: "text",
        validationType: "number",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Prize value is required"],
          },
          {
            type: "typeError",
            params: ["value should be a number"],
          },
        ],
      },
      {
        name: "imageUrl",
        label: "Prize Image",
        placeholder: "Upload Prize Image",
        type: "file",
        validationType: "mixed",
        value: "",
        validations: [
          {
            type: "required",
            params: ["Prize Image is required"],
          },
          {
            maxFileSize: 5,
            fileTypes: ["jpg", "jpeg", "pdf", "png", "gif"],
          },
        ],
      },
      // {
      //   name: "imageUrl",
      //   label: "Image Url",
      //   placeholder: "Image Url here",
      //   type: "urltext",
      //   validationType: "string",
      //   value: "",
      //   validations: [
      //     {
      //       type: "required",
      //       params: ["Image Url is required"],
      //     },
      //   ],
      // },
    ],
    []
  );

  function preparePrizeAddForm() {
    let fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
    fieldsInfoCopy = fieldsInfoCopy.filter((el) => el.hiddenOnForm !== true);

    const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);

    setFormValidateSchema(validateSchema);
    setPrizeFormData(fieldsInfoCopy);
  }

  async function submitData(data = "") {
    if (data) {
      const cookies = new Cookies();
      const cookieInfo = cookies.get("kidUser");
      data["addedBy"] = cookieInfo.id;
      const response = await _submitPrize(data);
      return response;
    }
  }

  async function editData(data = "") {
    if (data) {
      data._id = id;
      const response = await _submitPrize(data);
      return response;
    }
  }

  async function changeUrl() {
    const url = "/admin/prizes";
    props.history.push(url);
  }

  useEffect(() => {
    function preparePrizeForm(result) {
      const fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
      fieldsInfoCopy.forEach((fieldObj) => {
        if (result[fieldObj["name"]] || result[fieldObj["name"]] === 0) {
          let value = result[fieldObj["name"]];
          if (fieldObj["validationType"] === "number") {
            value = parseInt(value);
          } else if (fieldObj["validationType"] === "date") {
            fieldObj["type"] = "text";
          } else {
            if (value.constructor === Array) {
              value = value.map((el) => el.name).join(",");
            } else if (typeof value === "object" && value !== null) {
              value = value.name;
            }
          }
          if (fieldObj["validationType"] === "date") {
            fieldObj["value"] = moment(value).format("YYYY-MM-DD");
          } else {
            fieldObj["value"] = value;
          }
        }
      });
      const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
      const validateSchema = yup.object().shape(yepSchema);
      setValidateSchema(validateSchema);
      setPrize(fieldsInfoCopy);
    }
    async function fetchData() {
      // You can await here
      let result = await _getPrize(id);
      setprizeApiData(result);
      preparePrizeForm(result);
      // ...
    }
    if (id && id !== "add") {
      fetchData();
    }
    setPrizeMode("add");
  }, [id, fieldsInfo]); // Or [] if effect doesn't need props or state

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex flex-wrap mt-4">
        {id && id !== "add" ? (
          <>
            {prize ? (
              <>
                {prizeMode === "add" ? (
                  <div className="w-full">
                    <div className="flex justify-between bg-white pt-4 pr-4">
                      <button
                        onClick={(e) => props.history.goBack()}
                        className="ml-2 mt-3 text-sm flex flex-row"
                      >
                        <svg
                          version="1.1"
                          id="Capa_1"
                          className="w-5 h-5 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 26.676 26.676"
                        >
                          <g>
                            <path
                              d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                            />
                          </g>
                        </svg>
                        <div className="underline px-1">Go back</div>
                      </button>

                      <div className="flex justify-end space-x-2">
                        <button
                          className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                          onClick={() => editPrize()}
                        >
                          Edit Prize
                        </button>
                        <button
                          className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                          onClick={() => handleClickOpenDenyDelete()}
                        >
                          Delete Prize
                        </button>
                        <Dialog
                          fullWidth={true}
                          maxWidth="sm"
                          open={denyDelete}
                          onClose={handleClickCloseDenyDelete}
                          aria-labelledby="form-dialog-title"
                        >
                          <DialogContent>
                            <DialogContentText>
                              Are you sure you want to delete this Prize?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={handleClickCloseDenyDelete}
                              color="primary"
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => deletePrize(id)}
                              color="primary"
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </div>
                    <DynamicInfoRender
                      fields={prize}
                      validation={validateSchema}
                    />
                  </div>
                ) : (
                  // edit mode
                  <div className="w-full">
                    <div className="flex justify-between bg-white pt-4 pr-4">
                      <div className="text-bold w-2/3 text-xl pl-4">
                        Edit Contest Information
                      </div>
                      <div className="flex justify-end">
                        <button
                          className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                          onClick={() => setPrizeMode("add")}
                        >
                          Cancel Edit
                        </button>
                      </div>
                    </div>
                    <DynamicForm
                      onSubmit={editData}
                      history={props.history}
                      messages={messagesEdit}
                      onSuccess={changeUrl}
                      redirect={false}
                      fields={prizeFormDataEdit}
                      dialogMessage="Are you sure you want to edit this Prize?"
                      validation={formEditValidateSchema}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="my-4">
                  <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                    {[...Array(4)].map((e, i) => (
                      <div
                        key={i}
                        className="animate-pulse my-10 flex space-x-4"
                      >
                        <div className="flex-1 space-y-4 py-1">
                          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </>
        ) : id && id === "add" ? (
          <div className="w-full bg-white rounded-lg">
            <button
              onClick={(e) => props.history.goBack()}
              className="ml-2 mt-3 text-sm flex flex-row"
            >
              <svg
                version="1.1"
                id="Capa_1"
                className="w-5 h-5 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 26.676 26.676"
              >
                <g>
                  <path
                    d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                  />
                </g>
              </svg>
              <div className="underline px-1">Go back</div>
            </button>
            <DynamicForm
              onSubmit={submitData}
              history={props.history}
              messages={messagesAdd}
              onSuccess={changeUrl}
              redirect={false}
              fields={prizeFormData}
              dialogMessage="Are you sure you want to add this new Prize?"
              validation={formValidateSchema}
            />
          </div>
        ) : (
          <div className="w-full mb-12 px-4">
            <div className="flex justify-end bg-white pt-4 pr-4">
              <div>
                <button
                  className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                  onClick={() => addPrize()}
                >
                  Add Prize
                </button>
              </div>
            </div>
            <CardPrizes props={props} color="dark" />
          </div>
        )}
      </div>
    </>
  );
}
