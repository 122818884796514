import React, { useState, useEffect } from "react";
import {
  _getLoanApplicationsPartner,
  _getLoanApplicationsDetailsPartner,
  _updateLoanStatus,
} from "../../services/apiService";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import * as yup from "yup";

function CardLoanApplicationDetailedPartner() {
  let { id } = useParams();
  const [loans, setLoans] = useState(null);
  const [dynamicUserData, setDynamicUserData] = useState([]);
  const [hideShowCollapse, setHideShowCollapse] = useState([]);
  const [reject, setRejectHandle] = React.useState(false);
  const [verified, setVerifiedHandle] = React.useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const handleClickOpenReject = () => {
    setRejectHandle(true);
  };

  const handleClickCloseReject = () => {
    setRejectHandle(false);
  };

  const rejectLoan = async () => {
    const body = {
      rejectionReason: rejectReason,
      status: "Rejected",
    };
    await _updateLoanStatus(loans._id, body);
    loans.status = "Rejected";
    setLoans(loans);
    // check response
    // if (response.ok) {
    //   setRejectReason("");
    //   toast.success("Document Rejected", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    //   const url = "/admin/verification";
    //   setTimeout(() => props.history.push(url), 3000);
    // } else {
    //   toast.error("Oops! an error occured", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    // }
    setRejectHandle(false);
  };

  const handleClickOpenVerify = () => {
    setVerifiedHandle(true);
  };

  const handleClickCloseVerify = () => {
    setVerifiedHandle(false);
  };

  const verifyLoan = async () => {
    const body = {
      status: "Approved",
    };
    await _updateLoanStatus(loans._id, body);
    loans.status = "Approved";
    setLoans(loans);
    // const response = await _approveDocument(id, body);
    // if (response.ok) {
    //   toast.success("Successfully verified", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    //   const url = "/admin/verification";
    //   setTimeout(() => props.history.push(url), 3000);
    // } else {
    //   toast.error("Oops! an error occured", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    // }
    setVerifiedHandle(false);
  };

  useEffect(() => {
    function prepareForms(userData) {
      setHideShowCollapse(new Array(userData.length).fill(false));
      const result = [];
      userData.forEach((information) => {
        const obj = {};
        obj["info"] = information;
        const schema = "informationSchema";
        const fields = information[schema].fields;
        const data = information["data"];
        fields.forEach((fieldObj) => {
          if (data[fieldObj["name"]]) {
            let value = data[fieldObj["name"]];
            if (fieldObj["validationType"] === "number") {
              value = parseInt(value);
            } else if (fieldObj["validationType"] === "date") {
              fieldObj["type"] = "text";
            } else {
              if (value.constructor === Array) {
                value = value.map((el) => el.name).join(",");
              } else if (typeof value === "object" && value !== null) {
                value = value.name;
              }
            }
            fieldObj["value"] = value;
          }
        });
        const yepSchema = fields.reduce(createYupSchema, {});
        const validateSchema = yup.object().shape(yepSchema);
        obj["fields"] = fields;
        obj["validateSchema"] = validateSchema;
        result.push(obj);
      });
      setDynamicUserData(result);
    }
    async function fetchData() {
      let result = await _getLoanApplicationsPartner();
      let detailedData = await _getLoanApplicationsDetailsPartner(id);
      result.forEach((loan) => {
        if (loan._id === id) {
          loan.created = moment(loan.created).format("h:mm a, MMMM Do YYYY");
          loan.loanInfoImmutable = JSON.parse(loan.loanInfoImmutable);
          loan.productInfoImmutable = JSON.parse(loan.productInfoImmutable);
          setLoans(loan);
        }
        prepareForms(detailedData.userInfo.Verified);
      });
    }
    fetchData();
  }, [id]); // Or [] if effect doesn't need props or state

  return loans ? (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-row justify-between pb-5 sm:px-6">
          <div>
            <Link
              to="/partner/loan-applications"
              className="text-sm flex flex-row"
            >
              <svg
                version="1.1"
                id="Capa_1"
                className="w-5 h-5 my-3 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 26.676 26.676"
              >
                <g>
                  <path
                    d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"
                  />
                </g>
              </svg>
              <p className="cursor-pointer p-1 pt-2 underline inline-flex items-center mr-3">
                Go back
              </p>
            </Link>
          </div>
          <div className="flex bg-white pt-3">
            {loans.status === "Waiting" ? (
              <div>
                <button
                  className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600 mr-4"
                  onClick={handleClickOpenVerify}
                >
                  Approve Loan
                </button>
                <Dialog
                  fullWidth={true}
                  maxWidth="sm"
                  open={verified}
                  onClose={handleClickCloseVerify}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogContent>
                    <DialogContentText>Approve the loan?</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClickCloseVerify} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={verifyLoan} color="primary">
                      Approve
                    </Button>
                  </DialogActions>
                </Dialog>

                <button
                  className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600"
                  onClick={handleClickOpenReject}
                >
                  Reject Loan
                </button>

                <Dialog
                  fullWidth={true}
                  maxWidth="md"
                  open={reject}
                  onClose={handleClickCloseReject}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogContent>
                    <DialogContentText>
                      Please specify a reason for the rejection.
                    </DialogContentText>
                    <textarea
                      placeholder="Please specify a reason for the rejection."
                      required
                      value={rejectReason}
                      onChange={(e) => {
                        setRejectReason(e.target.value);
                      }}
                      rows="3"
                      className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md`}
                    ></textarea>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClickCloseReject} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={rejectLoan} color="primary">
                      Reject
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Full name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.name}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Application for
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.type}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.status}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Loan Maximum Amount
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.maximumAmount}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.description}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Loan default Interest Rate
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.defaultInterestRate}%
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Maximum Term
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.maximumTerm}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Minimum Term
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {loans.loanInfoImmutable.minimumTerm}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <main className="p-8 bg-white mx-auto">
        <h1 className="mb-4">Applicant's Details</h1>
        <section className="shadow">
          {dynamicUserData.map((user, index) => (
            <article key={index} className="border-b">
              <div className="border-l-2 border-transparent">
                <header
                  className="flex justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none"
                  onClick={() => {
                    hideShowCollapse[index] = !hideShowCollapse[index];
                    setHideShowCollapse([...hideShowCollapse]);
                  }}
                >
                  <span className="text-grey-darkest font-thin text-xl">
                    {user["info"].tags}
                  </span>
                  <div className="rounded-full border border-grey w-7 h-7 flex items-center justify-center">
                    {hideShowCollapse[index] ? (
                      <svg
                        aria-hidden="true"
                        className=""
                        data-reactid="266"
                        fill="none"
                        height="24"
                        stroke="#606F7B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                    ) : (
                      <svg
                        aria-hidden="true"
                        className=""
                        data-reactid="266"
                        fill="none"
                        height="24"
                        stroke="#606F7B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ transform: "rotate(180deg)" }}
                      >
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                    )}
                  </div>
                </header>
                <div className={hideShowCollapse[index] ? "" : "hidden"}>
                  <DynamicInfoRender
                    fields={user["fields"]}
                    validation={user["validateSchema"]}
                  />
                </div>
              </div>
            </article>
          ))}
        </section>
      </main>
    </div>
  ) : (
    <>
      <div className="my-4">
        <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          {[...Array(4)].map((e, i) => (
            <div key={i} className="animate-pulse my-10 flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                <div className="space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CardLoanApplicationDetailedPartner;
