import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { _getLoanApplications } from "../../services/apiService";
import moment from "moment";

const columns = [
  {
    name: "User",
    selector: "user.fullName",
    sortable: true,
    wrap: true,
  },
  {
    name: "Created Date",
    selector: "created",
    sortable: true,
    wrap: true,
  },
  {
    name: "Loan Amount",
    selector: "loanAmount",
    sortable: true,
    wrap: true,
  },
  {
    name: "Interest Rate",
    selector: "interestRate",
    sortable: true,
    wrap: true,
  },
  {
    name: "term",
    selector: "term",
    sortable: true,
    wrap: true,
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    wrap: true,
    cell: (row, index, column, id) => {
      return (
        <button
          className={`${
            row.status === "Approved"
              ? "bg-green-500 hover:bg-green-700"
              : "bg-red-500 hover:bg-red-700"
          } text-white text-xs font-bold py-2 px-4 rounded pointer-events-none`}
        >
          {row.status}
        </button>
      );
    },
  },
];

export default function AdminLoans(props) {
  const history = useHistory();
  const [loans, setLoans] = useState(null);
  const [floans, setFLoans] = useState(null);
  function applicationClicked(e) {
    const history = props.props.history;
    const url = "/loan-application/" + e._id;
    history.push(url);
  }
  useEffect(() => {
    async function fetchData() {
      let result = await _getLoanApplications();
      result.forEach((loan) => {
        loan.created = moment(loan.createdAt).format("h:mm a, MMMM Do YYYY");
        // loan.loanInfoImmutable = JSON.parse(loan.loanInfoImmutable);
        // loan.productInfoImmutable = JSON.parse(loan.productInfoImmutable);
      });
      setLoans(result);
      setFLoans(result);
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  const handleChange = (val) => {
    if (val) {
      const filter = loans.filter(
        (el) => el.user.fullName.toLowerCase().indexOf(val.toLowerCase()) != -1
      );
      console.log(filter);
      setFLoans(filter);
    } else {
      setFLoans(loans);
    }
  };
  function loanClicked(e) {
    const loanId = e._id;
    const url = `/admin/loans/${loanId}`;
    history.push(url);
  }

  return floans ? (
    <>
    <div className="my-4">
        <div className="flex bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          <div className="w-1/2">Filter by user</div>
          <div className="w-1/2">
            <input
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              type="text"
              className="w-full sm:w-1/2 px-4 loan"
              placeholder="Enter user name"
              style={{ color: "black" }}
            ></input>
          </div>
        </div>
      </div>
    <div className="bg-white flex flex-col">
      <div className="py-1 rounded-lg">
        <DataTable
          className="cursor-pointer"
          title="Loan Applications"
          columns={columns}
          pagination={true}
          paginationPerPage={8}
          data={floans}
          onRowClicked={(e) => loanClicked(e)}
        />
      </div>
    </div>
    </>
  ) : (
    <>
      <div className="my-4">
        <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          {[...Array(4)].map((e, i) => (
            <div key={i} className="animate-pulse my-10 flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                <div className="space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
