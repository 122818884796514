import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import { _login } from "../../services/apiService";
import ReactPixel from "react-facebook-pixel";

import LogoutLoader from "../../components/Cards/LogoutLoader";
import Footer from "../../components/Footer/index";
import NavBarLanding from "../../components/NavBarLanding/index";
import landing from "../../assets/img/landing-hero2.png";
import landingBallon from "../../assets/img/pexels-greta-hoffman-9705778 2-2.png";
import foot1 from "../../assets/img/pexels-greta-hoffman-9705778 1.png";
import foot2 from "../../assets/img/pexels-simplecreation-7137927 1-2.png";
import foot3 from "../../assets/img/pexels-greta-hoffman-9705778 1-2.png";
import foot4 from "../../assets/img/pexels-simplecreation-7137927 1.png";
import foot5 from "../../assets/img/pexels-simplecreation-7137927 1-3.png";

import TextInput from "../../components/TextInput";

ReactPixel.init("136397484080117");
ReactPixel.pageView(); // For tracking page view

function Landing(props) {
  // Depricated
  const [LandingData, setLandingData] = useState(true);
  // useEffect(() => {
  //   async function fetchData() {
  //     let result = await _getLandingCopy();
  //     setTimeout(() => {
  //       setLandingData(JSON.parse(result["jsonBlob"]));
  //     }, 1000);
  //   }
  //   fetchData();
  // }, []);

  let isVerified = new URLSearchParams(props.history.location.search).get(
    "isVerified"
  );

  if (isVerified) {
    toast.success("Yay! Account verified. Login to continue", {
      position: toast.POSITION.TOP_CENTER,
    });
  }

  const [isDisabled, setIsDisabled] = useState(false);
  const notify = (isSuccess, message = "") => {
    if (isSuccess) {
      toast.success("Welcome! Login successful", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setIsDisabled(false);
      toast.error("Login failed! " + message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  // style={{backgroundColor: "#3F3BA7"}}

  return (
    <>
      {LandingData ? (
        <>
          <div className="border-b-1 absolute top-0 w-full z-10 border-gray-600 bg-gray-00">
            <NavBarLanding />
          </div>
          <div className="hero-image px-3 md:px-32 md:flex justify-between items-center">
            <div className="md:pl-4 pt-28 md:pt-0 overlay-bg">
              <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:text-left lg:flex lg:items-center text-white">
                <div className="text-center md:text-left sm:text-base pb-8 md:pb-0">
                  <h1 className="md:mt-4 lg:mt-6 sm:leading-none text-xl lg:text-xl xl:text-xl tracking-tight font-bold">
                    <span className="md:inline text-white mr-2">
                      Hi, We are
                    </span>
                    <span className="text-4xl" style={{ color: "#1CE783" }}>
                      Seed
                    </span>
                  </h1>
                  <h1 className="mt-4 sm:mt-5 lg:mt-6 text-3xl tracking-tight sm:leading-none lg:text-5xl xl:text-6xl font-semibold">
                    You can get a loan… yes, you!
                  </h1>
                  <p className="mt-3 text-base text-white sm:mt-6 sm:text-xl">
                    You’ll be surprised just how quickly <br /> and easily it
                    can happen.
                    <span
                      className="text-primary italic"
                      style={{ color: "#1CE783" }}
                    ></span>
                  </p>
                  <Link to="/about-us">
                    <span
                      className="pt-5 underline"
                      style={{ color: "#1CE783" }}
                    >
                      Find Out More
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="solid shadow-lg md:max-w-lg	sm:w-full sm:rounded-lg sm:overflow-hidden px-6 py-10 sm:px-10 form-bg">
              <div className="text-xl font-bold	mb-4 text-white">Log in</div>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email address")
                    .required("Email is Required"),
                  password: Yup.string().required("Password is Required"),
                })}
                onSubmit={(values, { resetForm }) => {
                  let _data = {
                    email: values.email,
                    password: values.password,
                  };
                  setIsDisabled(true);
                  setTimeout(async () => {
                    let response = await _login(_data);

                    if (response.ok) {
                      let result = await response.json();
                      resetForm();
                      notify(response.ok);
                      const cookies = new Cookies();
                      cookies.set("kidUser", JSON.stringify(result), {
                        path: "/",
                      });
                      window.location.href = "/";
                    } else {
                      const message = await response.text();
                      notify(response.ok, message);
                    }

                    // setSubmitting(false);
                  }, 400);
                }}
              >
                <Form className="md:max-w-md">
                  <div>
                    <TextInput
                      name="email"
                      type="email"
                      placeholder="Email address"
                    />
                  </div>
                  <div>
                    <TextInput
                      name="password"
                      type="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="py-2">
                    <button
                      style={{ backgroundColor: "#1CE783" }}
                      type="submit"
                      className="block w-full py-3 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-black  hover:bg-theme-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      disabled={isDisabled}
                    >
                      Log in
                    </button>
                  </div>
                  <div className="py-1">
                    <p className="text-center text-white text-sm font-medium hover:underline">
                      <Link to="/request-link">Forgot Password?</Link>
                    </p>
                    <p className="text-center text-white text-sm mt-2 underline font-medium">
                      <Link to="/signup">Sign up instead</Link>
                    </p>
                  </div>
                </Form>
              </Formik>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </div>
          <div className="bg-landing-2">
            <div className="flex flex-col md:flex-row justify-center items-center px-3 md:px-32 pb-8 md:pb-0">
              <div className="md:w-full w-32">
                <img src={landing} alt="" />
              </div>
              <div>
                <div className="sm:mt-5 lg:mt-6 text-3xl tracking-tight sm:leading-none lg:text-5xl xl:text-6xl font-semibold">
                  When opportunity knocks, we’ll be there to open the door for
                  you.
                </div>
                <p className="py-3 md:py-10 lg:w-2/3">
                  Get access to loans and other financial services, simply,
                  easily, and hassle free.
                </p>
                <Link to="/about-us">
                  <span className="underline" style={{ color: "#1CE783" }}>
                    Find Out More
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#253646" }}
            className="flex flex-col md:flex-row items-center pb-8 md:pb-0"
          >
            <img
              className="object-cover"
              src={landingBallon}
              alt="seed-customer"
            />
            <div className="text-gray-200 px-3 md:px-32 py-10 md:py-0">
              <div className="sm:mt-5 lg:mt-6 text-3xl tracking-tight sm:leading-none lg:text-5xl xl:text-6xl font-semibold">
                Father. Comedian. Official fixer of things. Balloon buyer. Human
                taxi service. Seed customer.
              </div>
              <p className="py-3 md:py-10 lg:w-2/3">
                Get access to loans and other financial services, simply,
                easily, and hassle free.
              </p>
              <Link to="/about-us">
                <span className="underline" style={{ color: "#1CE783" }}>
                  Find Out More
                </span>
              </Link>
            </div>
          </div>
          <div className="hero-image-2 px-3 md:px-32 flex flex-col justify-center items-center space-y-3">
            <p className="w-2/3 text-gray-200 text-center text-3xl tracking-tight sm:leading-none lg:text-5xl xl:text-6xl font-semibold">
              Created to give everyday people extraordinary opportunities.
            </p>
            <p className="text-gray-200 text-center text-lg">
              Discover loans designed for you.
            </p>
          </div>
          <div className="hidden lg:flex no-scrollbar min-w-full overflow-scroll flex-col lg:flex-row">
            <img className="flex-grow" src={foot1} alt="" />
            <img className="flex-grow" src={foot2} alt="" />
            <img className="flex-grow" src={foot3} alt="" />
            <img className="flex-grow" src={foot4} alt="" />
            <img className="flex-grow" src={foot5} alt="" />
          </div>
          <div id="slideset1" className="flex lg:hidden flex-col items-center">
            <img className="w-96" src={foot1} alt="" />
            <img className="w-96" src={foot2} alt="" />
            <img className="w-96" src={foot3} alt="" />
            <img className="w-96" src={foot4} alt="" />
            <img className="w-96" src={foot5} alt="" />
          </div>
          <span
            className="flex justify-center font-semibold sm:p-10 p-5 text-center text-3xl "
            style={{ color: "#253646" }}
          >
            You Are So Much More Than a Number.
          </span>
          <div style={{ backgroundColor: "#253646" }}>
            <Footer />
          </div>
        </>
      ) : (
        LogoutLoader(false)
      )}
    </>
  );
}
export default Landing;
