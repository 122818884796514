import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { _getLoanApplicationsPartner } from "../../services/apiService";
import moment from "moment";

const columns = [
  {
    name: "Title",
    selector: "loanInfoImmutable.name",
    sortable: true,
    wrap: true,
  },
  {
    name: "Product",
    selector: "productInfoImmutable.name",
    sortable: true,
    wrap: true,
  },
  {
    name: "User",
    selector: "user.fullName",
    sortable: true,
    wrap: true,
  },
  {
    name: "Maximum Amount",
    selector: "loanInfoImmutable.maximumAmount",
    sortable: true,
    wrap: true,
  },
  {
    name: "Maximum Interest Rate",
    selector: "loanInfoImmutable.maximumInterestRate",
    sortable: true,
    wrap: true,
  },
  {
    name: "Created Date",
    selector: "created",
    sortable: true,
    wrap: true,
  },
  {
    name: "Type",
    selector: "loanInfoImmutable.type",
    sortable: true,
    wrap: true,
  },
];

export default function CardLoanApplications(props) {
  const [loans, setLoans] = useState(null);

  function applicationClicked(e) {
    const history = props.props.history;
    const url = "/partner/loan-applications/" + e._id;
    history.push(url);
  }
  useEffect(() => {
    async function fetchData() {
      let result = await _getLoanApplicationsPartner();
      result.forEach((loan) => {
        loan.created = moment(loan.created).format("h:mm a, MMMM Do YYYY");
        loan.loanInfoImmutable = JSON.parse(loan.loanInfoImmutable);
        loan.productInfoImmutable = JSON.parse(loan.productInfoImmutable);
      });
      setLoans(result);
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  return loans ? (
    <DataTable
      className="cursor-pointer sm:rounded-lg"
      title="Loan Applications"
      columns={columns}
      pagination={true}
      paginationPerPage={8}
      data={loans}
      onRowClicked={(e) => applicationClicked(e)}
    />
  ) : (
    <>
      <div className="my-4">
        <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
          {[...Array(4)].map((e, i) => (
            <div key={i} className="animate-pulse my-10 flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                <div className="space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
