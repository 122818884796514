import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import SelectField from "../SelectInput";
import {
  _assignUsers,
  _getModeratorsAndAdmins,
} from "../../services/apiService";

export default function AssignUserModal({
  closeModal,
  notifyError,
  selectedRows,
  notifySuccess,
}) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [admins, setAdmins] = useState([{ id: "default", name: "Loading..." }]);

  async function handleAssignUsers(values) {
    const adminUserId = values.userId;
    const selectUserIds = selectedRows.map((row) => row._id);
    const payload = {
      userIds: selectUserIds,
      adminOrModeratorId: adminUserId,
    };
    try {
      const response = await _assignUsers(payload);
      if (response.ok) {
        notifySuccess();
        closeModal();
      } else {
        notifyError();
      }
    } catch (error) {
      notifyError();
      console.log(error);
    }
  }

  useEffect(() => {
    _getModeratorsAndAdmins().then(setAdmins).catch(console.error);
  }, []);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-1/2">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between pt-5 pl-5  rounded-t">
              <h3 className="text-2xl font-semibold">Assign Users</h3>
            </div>
            {/*body*/}
            <div className="relative px-5 flex-auto">
              <Formik
                initialValues={{
                  userId: "",
                }}
                validationSchema={Yup.object({
                  userId: Yup.string()
                    .required("You must select option to assign users")
                    .notOneOf(
                      ["default"],
                      "You must select option to assign users"
                    ),
                })}
                onSubmit={(values, { resetForm }) => {
                  setIsDisabled(true);
                  handleAssignUsers(values);
                  resetForm();
                }}
              >
                <Form className="md:max-w-md">
                  <SelectField
                    name="userId"
                    options={admins}
                    getOptionValue={(option) => option._id}
                    getOptionLabel={(option) =>
                      `${option.email} (${option.userType})`
                    }
                    label="Select manager to assign users to"
                  />
                  {/*footer*/}
                  <div className="flex items-center justify-end">
                    <button
                      type="button"
                      className="text-white bg-red-500 rounded-md px-4 py-1 outline-none focus:outline-none mr-7 mb-5 mt-4 ease-linear transition-all duration-150"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      disabled={isDisabled}
                      className="text-white bg-green-500 rounded-md px-4 py-1 outline-none focus:outline-none mr-7 mb-5 mt-4 ease-linear transition-all duration-150"
                    >
                      Assign Users
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
