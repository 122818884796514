import React, { useEffect } from "react";
import DynamicInfoRender from "../../components/DynamicInfoRender";
import { createYupSchema } from "../../components/DynamicForm/yupSchemaCreator";
import * as yup from "yup";
import NotFound from "../../components/NotFound/index";
import moment from "moment";
import ProfileComponent from "./profileComponent";
import { _deleteAccount } from "../../services/apiService";
import Cookies from "universal-cookie";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const logout = () => {
  const cookies = new Cookies();
  cookies.remove("kidUser");
  window.location.href = "/";
};

export default function CProfile(props) {
  const [userInfo, setUserInfo] = React.useState(null);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [userInfoSchema, setUserInfoSchema] = React.useState("info");
  const [
    isDeleteAccountConfirmationModalOpen,
    setIsDeleteAccountConfirmationModalOpen,
  ] = React.useState(false);

  async function handleDeleteAccountClick() {
    setIsDisabled(true);
    const response = await _deleteAccount();
    const message = await response.text();
    if (response.ok) {
      props.notifySuccess(message);
      logout();
    } else {
      props.notifyError(message);
    }
    setIsDisabled(false);
  }

  useEffect(() => {
    function setUserAccountInfo() {
      const fieldsInfo = [
        {
          name: "userid",
          label: "User ID",
          placeholder: "User's ID here",
          hiddenOnForm: true,
          type: "text",
          validationType: "string",
          value: "",
          validations: [
            {
              type: "required",
              params: ["User ID is required"],
            },
          ],
        },
        {
          name: "email",
          label: "Email",
          placeholder: "User's email here",
          type: "text",
          validationType: "email",
          value: "",
          validations: [
            {
              type: "required",
              params: ["Email required"],
            },
          ],
        },
        {
          name: "status",
          label: "Status",
          placeholder: "User's status here",
          type: "text",
          validationType: "string",
          value: "",
          validations: [
            {
              type: "required",
              params: ["Status is required"],
            },
          ],
          options: [
            "Created",
            "Pending",
            "Suspended",
            "Completed",
            "Active",
            "Cancelled",
            "Reactivated",
          ],
        },
        {
          name: "fullName",
          label: "Full Name",
          placeholder: "User's Full Name here",
          type: "text",
          validationType: "string",
          value: "",
          validations: [
            {
              type: "required",
              params: ["Full Name is required"],
            },
          ],
        },
        {
          name: "country",
          label: "Country",
          placeholder: "User's country here",
          type: "longText",
          validationType: "string",
          value: "",
          validations: [
            {
              type: "required",
              params: ["country is required"],
            },
          ],
        },
        {
          name: "image",
          label: "Image",
          placeholder: "User's Image here",
          type: "text",
          validationType: "string",
          value: "",
          validations: [
            {
              type: "required",
              params: ["category is required"],
            },
          ],
        },
        {
          name: "created",
          label: "Created date",
          placeholder: "User's Created Date",
          type: "date",
          validationType: "date",
          value: "",
          validations: [
            {
              type: "required",
              params: ["participants is required"],
            },
          ],
        },
        {
          name: "lastLoginDate",
          label: "User's Last login date",
          placeholder: "User's Last login date here",
          type: "date",
          validationType: "date",
          value: "",
          validations: [
            {
              type: "required",
              params: ["Winners is required"],
            },
          ],
        },
      ];
      const fieldsInfoCopy = JSON.parse(JSON.stringify(fieldsInfo));
      const result = props.userInfo.user;
      fieldsInfoCopy.forEach((fieldObj) => {
        if (result[fieldObj["name"]]) {
          let value = result[fieldObj["name"]];
          if (fieldObj["validationType"] === "number") {
            value = parseInt(value);
          } else {
            if (value.constructor === Array) {
              value = value.map((el) => el.name).join(",");
            } else if (typeof value === "object" && value !== null) {
              value = value.name;
            }
          }
          if (fieldObj["validationType"] === "date") {
            fieldObj["value"] = moment(value).format("YYYY-MM-DD");
          } else {
            fieldObj["value"] = value;
          }
        }
      });
      const yepSchema = fieldsInfoCopy.reduce(createYupSchema, {});
      const validateSchema = yup.object().shape(yepSchema);
      setUserInfoSchema(validateSchema);
      setUserInfo(fieldsInfoCopy);
    }
    setUserAccountInfo();
  }, [props.userInfo]);
  return (
    <>
      <Dialog
        fullWidth={true}
        open={isDeleteAccountConfirmationModalOpen}
        onClose={() => setIsDeleteAccountConfirmationModalOpen(false)}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account? On confirming your
            account will be deleted and you will be logged out.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setIsDeleteAccountConfirmationModalOpen(false)}
          >
            Cancel
          </Button>
          <Button onClick={handleDeleteAccountClick} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {props.view === "data" ? (
        "data"
      ) : props.view === "points" ? (
        <>
          {props.userInfo.points && props.userInfo.points.length > 0 ? (
            props.userInfo.points.map((point, index) => {
              const pointsEarned = point.point;
              const earningDate = moment(point.created).format(
                "dddd, MMMM Do, YYYY h:mm:ss A"
              );
              let message = "You earned " + pointsEarned + "on " + earningDate;
              return (
                <div key={index}>
                  {point.submittedInformation ? (
                    <ProfileComponent message={message} />
                  ) : (
                    ""
                  )}
                </div>
              );
            })
          ) : (
            <NotFound
              title="You have not earned a point yet."
              description=" Please submit informations from Home to earn seed points"
            />
          )}
        </>
      ) : props.view === "acquisitions" ? (
        <>
          {props.userInfo.acquisition &&
          props.userInfo.acquisition.length > 0 ? (
            props.userInfo.acquisition.map((acquisition, index) => {
              let message =
                "You acquired Prize " +
                acquisition.prize.name +
                " on " +
                moment(acquisition.created).format(
                  "dddd, MMMM Do, YYYY h:mm:ss A"
                );
              return (
                <div key={index}>
                  <ProfileComponent message={message} />
                </div>
              );
            })
          ) : (
            <NotFound
              title="You have not redeemed anything yet."
              description=" Please submit informations from Home to earn seed points that can be redeemed for prizes"
            />
          )}
        </>
      ) : props.view === "contests" ? (
        <>
          {props.userInfo.contestsJoined &&
          props.userInfo.contestsJoined.length > 0 ? (
            props.userInfo.contestsJoined.map((contest, index) => {
              let message =
                "You joined contest " +
                contest.contest.name +
                " on " +
                moment(contest.contest.created).format("MMMM Do, YYYY h:mm A") +
                ". Contest ends on " +
                moment(contest.contest.endDate).format("MMMM Do, YYYY h:mm A");
              return (
                <div key={index}>
                  <ProfileComponent message={message} />
                </div>
              );
            })
          ) : (
            <NotFound
              title="You have not joined any contests yet."
              description="Please join a contest to stand a chance to win prizes"
            />
          )}
        </>
      ) : props.view === "info" ? (
        <>
          {userInfo ? (
            <div className="w-full">
              <DynamicInfoRender
                fields={userInfo}
                validation={userInfoSchema}
              />
              <button
                type="button"
                isDisabled={isDisabled}
                onClick={() => {
                  setIsDeleteAccountConfirmationModalOpen(true);
                }}
                className="w-full my-4 focus:outline-none text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-500 dark:focus:ring-red-700"
              >
                Delete Account
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      ) : props.view === "activity" ? (
        <>
          {props.userInfo.activity && props.userInfo.activity.length > 0 ? (
            props.userInfo.activity.map((activity, index) => {
              let message = "";
              if (
                activity.action === "submittedInfo" &&
                activity.submittedInformation
              ) {
                message =
                  "You submitted your " +
                  activity.submittedInformation.informationSchema.name +
                  " on " +
                  moment(activity.created).format("MMMM Do, YYYY h:mm A") +
                  ". The submitted info's status is " +
                  activity.submittedInformation.status;
              }
              if (
                activity.action === "submittedForVerification" &&
                activity.submittedInformation
              ) {
                message =
                  "You have submitted " +
                  activity.submittedInformation.informationSchema.name +
                  " on " +
                  moment(activity.created).format("MMMM Do, YYYY h:mm A") +
                  " for verification. The submitted info's status is " +
                  activity.submittedInformation.status;
              }
              if (
                activity.action === "infoVerified" &&
                activity.submittedInformation
              ) {
                message =
                  "You got verified for " +
                  activity.submittedInformation.informationSchema.name +
                  " on " +
                  moment(activity.created).format(
                    "dddd, MMMM Do, YYYY h:mm:ss A"
                  ) +
                  ". The submitted info's status is " +
                  activity.submittedInformation.status;
              }
              if (
                activity.action === "infoRejected" &&
                activity.submittedInformation
              ) {
                message =
                  "Got rejected for " +
                  activity.submittedInformation.informationSchema.name +
                  " on " +
                  moment(activity.created).format(
                    "dddd, MMMM Do, YYYY h:mm:ss A"
                  ) +
                  ". The submitted info's status is " +
                  activity.submittedInformation.status;
              }
              if (
                activity.action === "pointsReceived" &&
                activity.submittedInformation
              ) {
                message =
                  "You recieved" +
                  activity.pointsInformation.point +
                  "seeds for " +
                  activity.submittedInformation.informationSchema.name +
                  " on " +
                  moment(activity.created).format(
                    "dddd, MMMM Do, YYYY h:mm:ss A"
                  );
              }
              if (
                activity.action === "loanRejected"
              ) {
                  message = `Your loan for ${activity.loanInformation.loanAmount} at rate ${activity.
                    loanInformation.interestRate} was rejected`;
              }
              if (
                activity.action === "loanApproved"
              ) {
                message = `Your loan for ${activity.loanInformation.loanAmount} at rate ${activity.
                  loanInformation.interestRate} was approved. Someone from our team will contact you soon`;
              }
              if (
                activity.action === "pointsReceived" &&
                activity.submittedInformation
              ) {
                message =
                  "You recieved seeds for " +
                  activity.submittedInformation.informationSchema.name +
                  " on " +
                  moment(activity.created).format(
                    "dddd, MMMM Do, YYYY h:mm:ss A"
                  );
              }
              if (
                activity.action === "prizeRedeemed" &&
                activity.acquisitionInformation
              ) {
                message =
                  "You redeemed " +
                  activity.acquisitionInformation.prize.name +
                  " as a prize for " +
                  activity.acquisitionInformation.pointSpent +
                  " seeds on on " +
                  moment(activity.created).format("dddd, MMMM Do, YYYY");
              }
              if (
                activity.action === "contestJoined" &&
                activity.contestInformation
              ) {
                message =
                  "You Joined Contest " +
                  activity.contestInformation.name +
                  " on " +
                  moment(activity.contestInformation.created).format(
                    "dddd, MMMM Do, YYYY h:mm:ss A"
                  );
              }

              return (
                <div key={index}>
                  {message ? (
                    <div>
                      <ProfileComponent message={message} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })
          ) : (
            <NotFound
              title="We haven't noticed any activity for you."
              description="Go to home page and get the activity rolling"
            />
          )}
        </>
      ) : (
        <section className="text-gray-600 body-font">
          <div className="container py-4 mx-auto">
            <div className="flex flex-wrap -my-4">
              <div className="p-6 rounded-lg">
                <img
                  className="h-40 sm:h-80 rounded w-full object-cover object-center mb-6"
                  src="https://ns-prod-kid.s3.amazonaws.com/assets/not-found.png"
                  alt="content"
                />
                <div className="text-center">
                  <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                    {props.title}
                  </h2>
                  <p className="leading-relaxed text-base">
                    {props.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
