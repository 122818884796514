import React from "react";

const FinTips = () => {
  return (
    <>
      <div>
        <div className="py-8 xl:py-36 px-4 text-white sm:px-6 lg:px-8  overflow-hidden">
          <div className="max-w-max lg:max-w-7xl mx-auto">
            <div className="relative z-10 mb-8 md:mb-2 md:px-6">
              <div className="text-base max-w-prose lg:max-w-none">
                <p
                  style={{ color: "#1CE783" }}
                  className="mt-2 text-3xl leading-8 font-extrabold tracking-tight  sm:text-4xl"
                >
                  Financial Tips
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="relative  md:p-6">
                <div className="lg:grid lg:gap-6">
                  <div className="prose prose-indigo prose-lg lg:max-w-none">
                    <h1 className="mt-2  text-xl leading-8 font-extrabold tracking-tight text-white sm:text-2xl">
                      Tips
                    </h1>
                    <ol className="pl-6 list-decimal">
                      <li>Pay off your credit card with the highest interest rate first</li>
                      <li>Plan to pay your Loan Payments at least 3 days in advance just in case life happes</li>
                      <li>Create a Monthly Budget to track all your expenses</li>
                      <li>Save Early</li>
                      <li>Invest what you are willing to loose</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinTips;
