import { useState } from "react";
import { useField } from "formik";

import hidePwdImg from "./hide.svg";
import showPwdImg from "./show.svg";

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const toggleRevealPwd = () => {
    setIsRevealPwd(!isRevealPwd);
  };

  const isFieldPasswordType = props.type === "password";

  return (
    <div className="relative">
      <label htmlFor={props.id || props.name}>{label}</label>
      <input
        className={`block box w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mb-2 text-sm border-gray-300 rounded-md ${
          meta.touched && meta.error ? "border-red-700" : ""
        }`}
        {...field}
        {...props}
        type={
          isFieldPasswordType
            ? !isRevealPwd
              ? "password"
              : "text"
            : props.type
        }
      />
      {isFieldPasswordType && (
        <img
          className="absolute w-6 right-2 top-2 cursor-pointer"
          onClick={toggleRevealPwd}
          src={isRevealPwd ? hidePwdImg : showPwdImg}
          alt={isRevealPwd ? "Hide password" : "Show password"}
        />
      )}
      {meta.touched && meta.error ? (
        <div className="error text-xs font-semibold text-red-700">
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default TextInput;
