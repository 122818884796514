import React, { useState, useEffect } from "react";
import { _getPartner } from "../../services/apiService";
import { Link } from "react-router-dom";

export default function PartnerDashboard() {
  const [isCompanyCreated, setIsCompanyCreated] = useState(false);
  const [isApiCallDone, setIsApiCallDone] = useState(false);

  useEffect(() => {
    async function fetchApiData() {
      let result = await _getPartner();
      const companyCreated = result.partner ? true : false;
      setIsCompanyCreated(companyCreated);
      setIsApiCallDone(true);
    }
    fetchApiData();
  }, []);

  return (
    <>
<div>
    {isApiCallDone ?
    isCompanyCreated ? 
    <>
    <div className="min-w-screen  flex items-center justify-center ">
    <div className="max-w-7xl w-full mx-auto py-6 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">

            <div className="w-full lg:w-1/4">
                <div className="widget w-full p-4 rounded-lg bg-white border-l-4 border-purple-400">
                    <div className="flex items-center">
                        <div className="icon w-14 p-3.5 bg-purple-400 text-white rounded-full mr-3">
                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path>
                            </svg>
                        </div>
                        <div className="flex flex-col justify-center">
                            <div className="text-lg">9</div>
                            <div className="text-sm text-gray-400">Redeems</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full lg:w-1/4">
                <div className="widget w-full p-4 rounded-lg bg-white border-l-4 border-blue-400">
                    <div className="flex items-center">
                        <div className="icon w-14 p-3.5 bg-blue-400 text-white rounded-full mr-3">
                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                        </div>
                        <div className="flex flex-col justify-center">
                            <div className="text-lg">8</div>
                            <div className="text-sm text-gray-400">Users</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full lg:w-1/4">
                <div className="widget w-full p-4 rounded-lg bg-white border-l-4 border-yellow-400">
                    <div className="flex items-center">
                        <div className="icon w-14 p-3.5 bg-yellow-400 text-white rounded-full mr-3">
                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                            </svg>
                        </div>
                        <div className="flex flex-col justify-center">
                            <div className="text-lg">0</div>
                            <div className="text-sm text-gray-400">Verifications</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full lg:w-1/4">
                <div className="widget w-full p-4 rounded-lg bg-white border-l-4 border-red-400">
                    <div className="flex items-center">
                        <div className="icon w-14 p-3.5 bg-red-400 text-white rounded-full mr-3">
                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                        </div>
                        <div className="flex flex-col justify-center">
                            <div className="text-lg">34</div>
                            <div className="text-sm text-gray-400">Submissions</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
    <div className="flex  flex-row space-x-4 p-4">
      
                        <div className="w-2/3 p-4 bg-white">
                          Tal
                        </div>
      <div className="bg-white text-center w-1/3 p-4">
        RECENT ACTIVITIES
        </div>
    </div> </>: <div className="alert flex flex-col sm:flex-row items-center bg-yellow-200 p-5 rounded border-b-2 border-yellow-300 justify-between">
    <div className="alert flex flex-row">
    <div className="alert-icon flex items-center bg-yellow-100 border-2 border-yellow-500 justify-center h-10 w-10 flex-shrink-0 rounded-full">
      <span className="text-yellow-500">
        <svg fill="currentColor"
           viewBox="0 0 20 20"
           className="h-6 w-6">
          <path fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"></path>
        </svg>
      </span>
    </div>
    <div className="alert-content ml-4">
      <div className="alert-title font-semibold text-lg text-yellow-800">
        Warning
      </div>
      <div className="alert-description text-sm text-yellow-600">
        You cannot add products, loan criteria etc without creating company first
      </div>
    </div>
    </div>
    <div className="pt-3 sm:pt-0">
    <button
            className="focus:outline-none inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-main hover:bg-theme-main-hover"
          >
            <Link to={{ pathname: "/partner/company", state: { add: true } }}>
            Add company Now
            </Link>
          </button>
    </div>
  </div>
    :
    ''
    }
	</div>
    </>
  );
}
