// import React,{useEffect} from "react";
import React  from "react";

function ProfileComponent(props) {

    return(
<section className="text-gray-600 body-font">
  <div className="container">
    <div className="flex items-center lg:w-4/5 border-b border-gray-200 sm:flex-row flex-col">
      {<>
        <div className="h-20 w-20 inline-flex items-center rounded-full flex-shrink-0">
          <svg fill="none" stroke="#52CA95" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
          </svg>
        </div>
        <div className="">
          <p className="leading-relaxed text-sm">{props.message}</p>
        </div>
        </>}
      </div>
  </div>
</section>
    )
}

export default  ProfileComponent;