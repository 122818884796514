/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { _logOut } from "../../services/apiService";
import Cookies from "universal-cookie";
import NotificationDropdown from "../../components/Dropdowns/NotificationDropdown.js";
import PartnerDropdown from "../../components/Dropdowns/PartnerDropdown.js";
import Logo from "../../assets/img/seedja-logo2.png";

export default function PartnerSidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const logOut = async () => {
    const cookies = new Cookies();
    cookies.remove("kidUser");
    let result = await _logOut();
    if (result) {
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  };

  const handleClick = (isOpen) => {
    setIsSidebarOpen(!isOpen);
  };
  return (
    <div className="md:left-0 md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex flex-col md:flex-row md:min-h-screen w-full">
      <div className="flex flex-col w-full md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 flex-shrink-0">
        <div className="flex-shrink-0 px-8 pt-10 flex flex-row items-center justify-between">
          <Link to="/partner/dashboard" className="p-2">
            {/* <svg
              width="25"
              height="20"
              viewBox="0 0 716 815"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M699.66 20.2744C721.151 41.7661 721.151 76.6109 699.66 98.1025L493.369 304.394C436.352 361.41 436.352 453.852 493.369 510.869L699.717 717.217C721.24 738.74 721.24 773.637 699.717 795.16V795.16C678.194 816.683 643.298 816.683 621.774 795.16L337.598 510.983C280.581 453.967 280.581 361.525 337.598 304.508L621.832 20.2745C643.323 -1.21717 678.168 -1.21718 699.66 20.2744V20.2744Z"
                fill="#52CA95"
              />
              <rect width="126" height="815" rx="11" fill="#52CA95" />
            </svg> */}
            <img className="h-6" src={Logo} />
          </Link>
          <button
            className="rounded-lg md:hidden focus:outline-none focus:shadow-outline"
            onClick={() => {
              handleClick(isSidebarOpen);
            }}
          >
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
              {!isSidebarOpen && (
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              )}
              {isSidebarOpen && (
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              )}
            </svg>
          </button>
        </div>
        <nav
          className={`flex-grow md:block px-4 mt-6 md:pb-0 md:overflow-y-auto ${
            isSidebarOpen ? "block" : "hidden"
          }`}
        >
          <div className="mt-1 px-4 text-xl font-thin text-primary">
            Partner's Panel
          </div>
          <Link
            className={`block ml-8 pl-2 mr-3 mt-3 py-1 text-base bg-transparent rounded-lg ${
              window.location.href.indexOf("/partner/dashboard") !== -1
                ? "outline-none bg-theme-main shadow-outline text-white font-normal hover:bg-theme-main hover:text-white"
                : "text-gray-900 hover:text-gray-900 hover:bg-green-50 font-light"
            }`}
            to="/partner/dashboard"
          >
            Dashboard
          </Link>
          <Link
            className={`block ml-8 pl-2 mr-3 py-1 text-base bg-transparent rounded-lg ${
              window.location.href.indexOf("/partner/products") !== -1
                ? "outline-none bg-theme-main shadow-outline text-white font-normal hover:bg-theme-main hover:text-white"
                : "text-gray-900 hover:text-gray-900 hover:bg-green-50 font-light "
            }`}
            to="/partner/products"
          >
            Products
          </Link>
          <Link
            className={`block ml-8 pl-2 mr-3 py-1 text-base bg-transparent rounded-lg ${
              window.location.href.indexOf("/partner/loans") !== -1
                ? "outline-none bg-theme-main shadow-outline text-white font-normal hover:bg-theme-main hover:text-white"
                : "text-gray-900 hover:text-gray-900 hover:bg-green-50 font-light "
            }`}
            to="/partner/loans"
          >
            Loans
          </Link>
          <Link
            className={`block ml-8 pl-2 mr-3 py-1 text-base font-light bg-transparent rounded-lg ${
              window.location.href.indexOf("/partner/reports") !== -1
                ? "outline-none bg-theme-main shadow-outline text-white font-normal hover:bg-theme-main hover:text-white"
                : "text-gray-900 hover:text-gray-900 hover:bg-green-50 font-light"
            }`}
            to="/partner/reports"
          >
            Reports
          </Link>
          <Link
            className={`block ml-8 pl-2 mr-3 py-1 text-base font-light bg-transparent rounded-lg ${
              window.location.href.indexOf("/partner/loan-applications") !== -1
                ? "outline-none bg-theme-main shadow-outline text-white font-normal hover:bg-theme-main hover:text-white"
                : "text-gray-900 hover:text-gray-900 hover:bg-green-50 font-light"
            }`}
            to="/partner/loan-applications"
          >
            Loan Applications
          </Link>
          <Link
            className={`block ml-8 pl-2 mr-3 py-1 text-base font-light bg-transparent rounded-lg ${
              window.location.href.indexOf("/partner/company") !== -1
                ? "outline-none bg-theme-main shadow-outline text-white font-normal hover:bg-theme-main hover:text-white"
                : "text-gray-900 hover:text-gray-900 hover:bg-green-50 font-light"
            }`}
            to="/partner/company"
          >
            Company
          </Link>
          <Link
            className="block ml-8 pl-2 mr-3 py-1 text-base font-light bg-transparent rounded-lg hover:bg-green-50"
            to="/partner/logout"
            onClick={() => logOut()}
          >
            logout
          </Link>
        </nav>
      </div>
    </div>
  );
}
