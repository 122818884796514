import React from "react";

export default function LogoutLoader(showLogout = true) {
  return (
    <div className="w-full h-full flex mt-4 items-center justify-center fixed block top-0 left-0 bg-transparent ">
      <span className="text-green-500 relative">
        <i className="fas fa-circle-notch fa-spin fa-5x mx-4"></i>
        <p className="">
          {showLogout ? (
            <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden  opacity-75 flex flex-col items-center justify-center">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-white h-12 w-12 mb-4"></div>
              <h2 className="text-center text-white text-xl font-normal">
                Logging you out....
              </h2>
            </div>
          ) : (
            ""
          )}
        </p>
      </span>
    </div>
  );
}
