import React, { useState, useEffect } from "react";
import {
  _getPublicLoans,
  _getUserLoanDecision,
  _applyLoanDecision,
} from "../../services/apiService";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import DetailedView from "./DetailedView";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Cookies from "universal-cookie";

function LoanListView(props) {
  const cookies = new Cookies();
  const cookieInfo = cookies.get('kidUser');
  let loanType = 'FullModel';
  if(cookieInfo.infoStatus && cookieInfo.infoStatus=="FirstModel") {
    loanType = 'FirstModel';
  }
  let { id } = useParams();
  const [loans, setLoans] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [requestedLoanValue, setRequestedLoanValue] = useState(0);
  const [sliderVal, setSliderVal] = useState(0);
  const [loanDecisionData, setLoanDecisionData] = useState();

  useEffect(() => {
    
    async function fetchData() {
      // You can await here
      let result = await _getPublicLoans();
      result.forEach((information, i) => {
        information.created = moment(information.startDate).format(
          "h:mm a, MMMM Do YYYY"
        );
      });
      setLoans(result);
      // setFilteredItems(result)
      // ...

      let loanDecisionData = await _getUserLoanDecision(loanType);
      setLoanDecisionData(loanDecisionData);
      setDataLoaded(true);
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  let myData = { loanDecision: true, maxLoanAmount: 33332, loanTermMonths: 6 };

  const handleLoanRequest = async () => {
    // Perform the desired action here when the button is clicked
    // For example, you can make an API call, show a modal, or navigate to a different page
    // You can customize this function to fit your specific needs
    console.log('Loan request initiated');
    // Additional logic...
    if (requestedLoanValue > 0) {

        const loanData = {
        term: loanDecisionData.loanTermMonths,
        interestRate: loanDecisionData.interestRate,
        loanAmount: requestedLoanValue

      }
      console.log('Loan request initiated', loanData);
      try {
        await _applyLoanDecision(loanData, loanType);
        
        
          toast.success('Applied successfully. Waiting for admin approval', {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(() => {
            props.history.push("/loan-application");
          }, 2500);
      
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong. Try agin', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
     
      // Additional logic...
      // props.history.push("/loan-request");
    } else {
      alert("Please select loan amount");
    }
  };
  
  const calculateLoanValue = (slideVal) => {
    if (slideVal > loanDecisionData.maxLoanAmount) {
      slideVal = loanDecisionData.maxLoanAmount;
    }
    if (!slideVal) {
      slideVal = 0;
    }
    setSliderVal(slideVal);
    let requestedLoanValue = slideVal;
    setRequestedLoanValue(requestedLoanValue);
  };

  const limitDecimalsWithoutRounding = (val, decimals) => {
    let parts = val.toString().split(".");
    if (parts.length > 1) {
      return parseFloat(parts[0] + "." + parts[1].substring(0, decimals));
    } else {
      return val;
    }
  };

  const numberWithCommas = (x) => {
    x = limitDecimalsWithoutRounding(x, 2);
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
      {dataLoaded ? (
        ""
      ) : (
        <div className="my-4">
          <div className=" bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
            {[...Array(4)].map((e, i) => (
              <div className="animate-pulse my-10 flex space-x-4" key={i}>
                <div className="flex-1 space-y-4 py-1">
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                  <div className="space-y-2">
                    <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                    <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {id ? (
        <div>
          <DetailedView />
        </div>
      ) : (
        loanDecisionData && (
          <div>
            {loanDecisionData?.loanDecision && (
              <div className="bg-white shadow rounded-md p-4 max-w-sm sm:max-w-full w-full">
                <div className="flex justify-between">
                  <div
                    className="text-2xl font-bold"
                    style={{ color: "#19ca73" }}
                  >
                    You have been pre-approved for UP to{" "}
                    {loanDecisionData.maxLoanAmount}{" "}
                  </div>
                  <div className="text-green-400 font-bold">
                    Term <span>{loanDecisionData.loanTermMonths}</span>
                  </div>
                </div>
                <div>
                  <label
                    color={{ background: "#19ca73" }}
                    className="block text-green-400 mb-0.5 font-semibold"
                    htmlFor="customRange2"
                  >
                    Choose your loan amount on slider or enter in the input box
                  </label>
                </div>
                <div className="flex flex-col sm:flex-row relative pt-1 mt-1">
                  <div className="w-full sm:w-1/2">
                    <input
                      type="range"
                      max={loanDecisionData.maxLoanAmount}
                      step="100"
                      value={sliderVal}
                      className="w-1/2 overflow-hidden appearance-none rounded-lg bg-green-500 w-full seedja-slider"
                      style={{ background: "#19ca73" }}
                      // min="0"
                      // max="5"
                      // className="bg-green-200"
                      // id="customRange2"
                      onChange={(e) => {
                        calculateLoanValue(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full sm:w-1/2 sm:ml-4">
                    <input
                      onChange={(e) => {
                        calculateLoanValue(e.target.value);
                      }}
                      type="number"
                      className=" w-full sm:w-1/2 px-4 loan"
                      placeholder="Enter loan amount"
                      max={loanDecisionData.maxLoanAmount}
                      style={{ color: "black" }}
                    ></input>
                    {/* <input type="text" id="company" className=" border border-gray-300 text-gray-900 text-sm rounded-lg 
                  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 
                  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Flowbite" required>
                    </input> */}
                  </div>
                </div>
              </div>
            )}
            {loanDecisionData?.loanDecision ? (
              <div className="bg-white shadow rounded-md max-w-sm sm:max-w-full w-full mt-10 py-10 px-4">
                <div className="flex flex-col items-center">
                  <div className="text-5xl font-bold text-green-500 mt-1">
                    {numberWithCommas(requestedLoanValue)}
                  </div>
                  <div className="text-lg text-green-500">Requested amount</div>
                  <div
                    className="bg-white rounded-lg"
                    id="stats"
                    role="tabpanel"
                    aria-labelledby="stats-tab"
                  >
                    <dl className="grid grid-cols-2 gap-8 p-4 mx-auto max-w-screen-xl text-gray-900 sm:grid-cols-3 xl:grid-cols-3 sm:p-8">
                      <div className="flex flex-col justify-center items-center">
                        <dt className="mb-2 text-3xl text-green-500 font-extrabold">
                          {loanDecisionData.interestRate}%
                        </dt>
                        <dd className="text-green-500">Rate</dd>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <dt className="mb-2 text-3xl text-green-500 font-extrabold">
                          {" "}
                          {numberWithCommas(
                            parseInt(requestedLoanValue) *
                              (loanDecisionData.interestRate / 100) *
                              loanDecisionData.loanTermMonths
                          )}
                        </dt>
                        <dd className="text-green-500">Total Interest</dd>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <dt className="mb-2 text-3xl text-green-500 font-extrabold">
                          {numberWithCommas(
                            parseInt(requestedLoanValue) * (5 / 100)
                          )}
                        </dt>
                        <dd className="text-green-500">Processing Fee</dd>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <dt className="mb-2 text-3xl text-green-500 font-extrabold">
                          {" "}
                          {numberWithCommas(
                            parseInt(requestedLoanValue) +
                              parseInt(requestedLoanValue) *
                                (loanDecisionData.interestRate / 100) *
                                loanDecisionData.loanTermMonths
                          )}
                        </dt>
                        <dd className="text-green-500">Total Repayment</dd>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <dt className="mb-2 text-3xl text-green-500 font-extrabold">
                          {" "}
                          {numberWithCommas(
                            (parseInt(requestedLoanValue) +
                              parseInt(requestedLoanValue) *
                                (loanDecisionData.interestRate / 100) *
                                loanDecisionData.loanTermMonths) /
                              loanDecisionData.loanTermMonths
                          )}
                        </dt>
                        <dd className="text-green-500">Monthly Repayment</dd>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <dt className="mb-2 text-3xl text-green-500 font-extrabold">
                          {loanDecisionData.loanTermMonths}
                        </dt>
                        <dd className="text-green-500">Months</dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center mt-5">
                  {loanType == 'FullModel' ?
                  <Popup
                    trigger={
                      <button
                        type="button"
                        className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm w-40 pt-1.5 pb-2 mr-2 mt-2"
                      >
                        Request/apply for this loan
                      </button>
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <button className="close" onClick={close}>
                          &times;
                        </button>
                        <div className="content">
                          {" "}
                          Whoo Hoooo, You made it!! Welcome to the Seed Family.
                          We will contact you with further details to process
                          your loan application as we await our Bank of Jamaica
                          Microcredit Licence. Stay Tuned for more updates and
                          let’s see what we can achieve together. Don’t worry,
                          we will contact you very soon
                        </div>
                      </div>
                    )}
                  </Popup>:
                  <button
                  onClick={() => {handleLoanRequest();}}
                  type="button"
                  className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm w-40 pt-1.5 pb-2 mr-2 mt-2"
                >
                  Request/apply for this loan
                </button>}

                  <button
                    onClick={() => {
                      props.history.push("/loan-services");
                    }}
                    type="button"
                    className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm w-40 pt-1.5 pb-2 mr-2 mb-2 mt-1"
                  >
                    Go to previous step
                  </button>
                  <button
                    onClick={() => {
                      props.history.push("/loan-services");
                    }}
                    type="button"
                    className="focus:outline-none text-green-500 underline font-medium text-sm"
                  >
                    Monthly payments too high for me
                  </button>
                </div>
              </div>
            ) : (
              <div className="bg-white shadow rounded-md max-w-sm sm:max-w-full w-full mt-10 p-4">
                <div className="flex flex-col items-center">
                  {/* <div className="font-bold text-green-500 text-sm mt-4">
                    You have requested a loan of amount
                  </div> */}
                  <div className="font-bold text-green-500 text-sm mt-4">
                    {loanDecisionData.reasonForDecline
                      ? loanDecisionData.reasonForDecline
                      : "No offers available. Check your email for more info"}
                  </div>
                  <button
                    onClick={() => {
                      props.history.push("/loan-services");
                    }}
                    type="button"
                    className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm w-40 pt-1.5 pb-2 mr-2 mb-2 mt-2"
                  >
                    Go to previous step
                  </button>
                </div>
              </div>
            )}
          </div>
        )
      )}
    </>
  );
}

export default LoanListView;
