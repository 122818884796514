import React, {useEffect,useState} from "react";
import { _getDashboardStatsInfo } from "../../services/apiService";
// ../../components

import CardLineChart from "../../components/Cards/CardLineChart.js";
import CardLineChart1 from "../../components/Cards/CardLineChart1";
import CardLineChart2 from "../../components/Cards/CardLineChart2";
import CardBarChart from "../../components/Cards/CardBarChart.js";

// import CardPageVisits from "../../components/Cards/CardPageVisits.js";
// import CardSocialTraffic from "../../components/Cards/CardSocialTraffic.js";

export default function Dashboard() {
  const [response, setResponse] = useState(null);
  const [barData, setBarData] = useState(null);
  const [lineData, setLineData] = useState(null);
  const [lineData1, setLineData1] = useState(null);
  const [lineData2, setLineData2] = useState(null);
  function createrBar(usersCount) {
    const obj = {label:[],data:[]};
    for (let i = 1; i <= 30; i++) {
      const count = Math.floor(Math.random() * (130 - 30 + 1)) + 30; // Random count between 30 and 130
      const date = new Date();
      date.setDate(date.getDate() - i); // Generate dates for the past 30 days
      const dateDMY = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
    
      usersCount.push({ count, dateDMY });
    }
    
    usersCount.forEach(count => {
      obj['data'].push(count['count']);
      obj['label'].push(count['dateDMY']);
    })
    console.log("bar:",obj);
    setBarData(obj);
  };

  function createLine(submittedVerificationCount,submittedInfoCount) {
    const obj1 = {label:[],data:[]};
    const obj2 = {label:[],data:[]};
   
    for (let i = 1; i <= 30; i++) {
      const count = Math.floor(Math.random() * (170 - 65 + 1)) + 65; // Random count between 65 and 170
      const date = new Date();
      date.setDate(date.getDate() - i); // Generate dates for the past 30 days
      const dateDMY = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
    
      submittedVerificationCount.push({ count, dateDMY });
    }
    
    // Create a fake submittedInfoCount array
    
    for (let i = 1; i <= 30; i++) {
      const count = Math.floor(Math.random() * (290 - 100 + 1)) + 100; // Random count between 100 and 290
      const date = new Date();
      date.setDate(date.getDate() - i); // Generate dates for the past 30 days
      const dateDMY = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
    
      submittedInfoCount.push({ count, dateDMY });
    }
    submittedVerificationCount.forEach(count => {
      obj1['data'].push(count['count']);
      obj1['label'].push(count['dateDMY']);
    });
    submittedInfoCount.forEach(count => {
      obj2['data'].push(count['count']);
      obj2['label'].push(count['dateDMY']);
    });
    console.log("createLine:",{submit:obj2,verify:obj1});
    setLineData({submit:obj2,verify:obj1});

    const appliedLoans = [];
const approvedLoans = [];

for (let i = 1; i <= 30; i++) {
  const appliedCount = Math.floor(Math.random() * 1000) + 100; // Random count between 100 and 1100
  const approvalRate = 0.8; // Approval rate of 80%
  const approvedCount = Math.floor(appliedCount * approvalRate);

  const date = new Date();
  date.setDate(date.getDate() - i); // Generate dates for the past 30 days
  const dateDMY = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });

  appliedLoans.push({ count: appliedCount, dateDMY });
  approvedLoans.push({ count: approvedCount, dateDMY });
}

const obj3={label:[],data:[]};
const obj4={label:[],data:[]};
appliedLoans.forEach(count => {
  obj3['data'].push(count['count']);
  obj3['label'].push(count['dateDMY']);
});
approvedLoans.forEach(count => {
  obj4['data'].push(count['count']);
  obj4['label'].push(count['dateDMY']);
});
console.log("createLine1:",{submit:obj4,verify:obj3});
setLineData1({submit:obj3,verify:obj4});

const defaultLoans = [];

for (let i = 1; i <= 30; i++) {
  const defaultCount = Math.floor(Math.random() * (8 - 3 + 1)) + 3; // Random count between 3 and 8
  const date = new Date();
  date.setDate(date.getDate() - i); // Generate dates for the past 30 days
  const dateDMY = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });

  defaultLoans.push({ count: defaultCount, dateDMY });
}

const obj5={label:[],data:[]};
defaultLoans.forEach(count => {
  obj5['data'].push(count['count']);
  obj5['label'].push(count['dateDMY']);
});
setLineData2({submit:obj5});

  };
  useEffect(() => {
    async function fetchData() {
      // You can await here
      let result = await _getDashboardStatsInfo();
      setResponse(result);
      createrBar(result.usersCount);
      createLine(result.submittedVerificationCount,result.submittedInfoCount)
    }
    fetchData();
  }, []);
  return (
    response ?
    <>
    {/* <div className="min-w-screen  flex items-center justify-center ">
    <div className="max-w-7xl w-full mx-auto py-6 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">

            <div className="w-full lg:w-1/4">
                <div className="widget w-full p-4 rounded-lg bg-white border-l-4 border-purple-400">
                    <div className="flex items-center">
                        <div className="icon w-14 p-3.5 bg-purple-400 text-white rounded-full mr-3">
                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path>
                            </svg>
                        </div>
                        <div className="flex flex-col justify-center">
                            <div className="text-lg">{response.redeems}</div>
                            <div className="text-sm text-gray-400">Redeems</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full lg:w-1/4">
                <div className="widget w-full p-4 rounded-lg bg-white border-l-4 border-blue-400">
                    <div className="flex items-center">
                        <div className="icon w-14 p-3.5 bg-blue-400 text-white rounded-full mr-3">
                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                        </div>
                        <div className="flex flex-col justify-center">
                            <div className="text-lg">{response.users}</div>
                            <div className="text-sm text-gray-400">Users</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full lg:w-1/4">
                <div className="widget w-full p-4 rounded-lg bg-white border-l-4 border-yellow-400">
                    <div className="flex items-center">
                        <div className="icon w-14 p-3.5 bg-yellow-400 text-white rounded-full mr-3">
                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                            </svg>
                        </div>
                        <div className="flex flex-col justify-center">
                            <div className="text-lg">{response.verifications}</div>
                            <div className="text-sm text-gray-400">Verifications</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full lg:w-1/4">
                <div className="widget w-full p-4 rounded-lg bg-white border-l-4 border-red-400">
                    <div className="flex items-center">
                        <div className="icon w-14 p-3.5 bg-red-400 text-white rounded-full mr-3">
                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                        </div>
                        <div className="flex flex-col justify-center">
                            <div className="text-lg">{response.information}</div>
                            <div className="text-sm text-gray-400">Submissions</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
   
</div> */}
<div class=" mx-auto px-4 py-8">


    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
    <div class="bg-orange-500 rounded-lg shadow p-6">
        <h2 class="text-lg font-semibold mb-4 text-white">Redeems</h2>
        <p class="text-3xl font-bold text-white">100</p>
      </div>

      <div class="bg-pink-500 rounded-lg shadow p-6">
        <h2 class="text-lg font-semibold mb-4 text-white">Users</h2>
        <p class="text-3xl font-bold text-white">8442</p>
      </div>

      <div class="bg-indigo-500 rounded-lg shadow p-6">
        <h2 class="text-lg font-semibold mb-4 text-white">Verifications</h2>
        <p class="text-3xl font-bold text-white">18321</p>
      </div>

      <div class="bg-green-500 rounded-lg shadow p-6">
        <h2 class="text-lg font-semibold mb-4 text-white">Submissions</h2>
        <p class="text-3xl font-bold text-white">21943</p>
      </div>

      <div class="bg-blue-500 rounded-lg shadow p-6">
        <h2 class="text-lg font-semibold mb-4 text-white">Approval Rate</h2>
        <p class="text-3xl font-bold text-white">82%</p>
      </div>

      <div class="bg-yellow-500 rounded-lg shadow p-6">
        <h2 class="text-lg font-semibold mb-4 text-white">Delinquency Rate</h2>
        <p class="text-3xl font-bold text-white">8%</p>
      </div>

      <div class="bg-red-500 rounded-lg shadow p-6">
        <h2 class="text-lg font-semibold mb-4 text-white">Default Rate</h2>
        <p class="text-3xl font-bold text-white">4%</p>
      </div>

     
      <div class="bg-purple-500 rounded-lg shadow p-6">
        <h2 class="text-lg font-semibold mb-4 text-white">Portfolio Size</h2>
        <p class="text-3xl font-bold text-white">300m</p>
      </div>

      <div class="bg-green-500 rounded-lg shadow p-6">
        <h2 class="text-lg font-semibold mb-4 text-white">Number of Active Loans</h2>
        <p class="text-3xl font-bold text-white">6k</p>
      </div>
    <div class="bg-gray-500 rounded-lg shadow p-6">
      <h2 class="text-lg font-semibold mb-4 text-white">Approval Time</h2>
      <p class="text-3xl font-bold text-white">10 minutes</p>
    </div>
    </div>


    
  </div>
      <div className="flex flex-wrap">
        {/* <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <CardLineChart />
        </div> */}
        <div className="w-full px-4">
         {barData ? <CardBarChart props={barData}/>:''}
        </div>
        <div className="w-full px-4">
        {lineData ? <CardLineChart props={lineData}/>:''}
        </div>
        <div className="w-full px-4">
        {lineData1 ? <CardLineChart1 props={lineData1}/>:''}
        </div>
        <div className="w-full px-4">
        {lineData2 ? <CardLineChart2 props={lineData2}/>:''}
        </div>
      </div>
      {/* <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <CardPageVisits />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <CardSocialTraffic />
        </div>
      </div> */}
    </>:''
  );
}
